import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import __isEmpty from 'lodash/isEmpty';


import { verifyBeginPasswordReset } from '../../services/teacher';

import PageHeader from '../../components/elements/PageHeader';
import FormContainer from '../../components/elements/FormContainer';
import Textbox from '../../components/elements/Textbox';
import Button from '../../components/elements/Button';

import './ResetPassword.scss';

function ResetPassword(props) {
  const s3Url = process.env.REACT_APP_S3_IMAGES_URL;
  const [ confirmed, setConfirmed ] = useState(false);
  const [ error, setError ] = useState('');
  const [ password, setPassword ] = useState('');

  const setConfirmation = () => {
   setConfirmed(true);
  }

  const savePassword= (elemName, value) => {
    console.log('hit!', password)
    setPassword(value.toLowerCase());
  }

  /**
   * Created custom parser to preserve servered characters
   * that URLSearchParams doesn't
   * @param {string} url
   * @returns {object}
   */
  const parseQueryString = (url) => {
    const queryString = url.split('?')[1];
    const paramsArray = queryString.split('&');
    const parsedValues = {};

    paramsArray.forEach(param => {
      const [key, value] = param.split('=');
      parsedValues[key] = decodeURIComponent(value);
    });

    return parsedValues;
  }

  const showFormProcessing = () => {
    //change the button style - this is an isolated event
    document.getElementById('login-submit').classList.remove('alt-orange');
    document.getElementById('login-submit').classList.add('processing', 'alt-gray');
    document.getElementById('login-submit').value = 'PROCESSING...';
  }

  const restFormProcessing = () => {
    document.getElementById('login-submit').classList.add('alt-orange');
    document.getElementById('login-submit').classList.remove('processing', 'alt-gray');
  }

  const invalidEmailError = () => {
    document.getElementById('login-submit').classList.add('alt-orange');
    document.getElementById('login-submit').classList.remove('processing', 'alt-gray');
  }

  const submitForm = async (e) => {
    e.preventDefault();
    restFormProcessing();

    if (__isEmpty(password)) {
      setError('Password is required');
      return invalidEmailError();
    }

    // min and max length of password from the register form
    if (password.length < 6 || password.length > 32) {
      setError('Password must be between 6 and 32 characters');
      return invalidEmailError();
    }

    showFormProcessing();
    setError('');

    try {
      const urlParams = parseQueryString(props.location.search);
      console.log('submitting form', urlParams);
      const params = {
        email: urlParams.email,
        code: urlParams.token,
        password,
      };
      await verifyBeginPasswordReset(params);
      setConfirmation();
    } catch (error) {
      setError(error.message);
      invalidEmailError();
    } 
  }

  return (
    <div className="password-recovery-container page">
      <div className="grid-x grid-padding-x header">
        {
          confirmed ? (
            <>
              <PageHeader
                pageTitle="Password Reset Success!"
                pageSubtitle="Sign in using your new password."
              />
              <Button to="/login" type="in-page" text="Sign In" extraClass="alt-orange" />
            </>
          ) : (
            <>
              <PageHeader
                pageTitle="Change your Password "
                pageSubtitle="Enter your new Password."
              />
              <FormContainer formTitle="" columnClass="small-12 medium-9 large-5">
                <div className="reset-pass-form-wrapper">
                  <div className="line-error" dangerouslySetInnerHTML={{ __html: error }} />
                  <form onSubmit={ e => submitForm(e) }>
                    <Textbox type="password" name="login-email" onChange={savePassword} noValidate={ false } />
                    <Button text="Continue" type="form" id="login-submit" extraClass="alt-orange" />
                  </form>
                  <div className="more-links">
                    <Link to="/login">Cancel &amp; Sign In</Link>
                  </div>
                </div>
              </FormContainer>
            </>
          )
        }
      </div>
      <div className="crop">
        <img className="bg-image" alt="" src={`${s3Url}/school.svg`} />
      </div>
    </div>
  );
}

export default ResetPassword;
