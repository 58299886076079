import React from 'react';

import './FormContainerWrapper.scss';

function FormContainer(props) {
  return (
    <div className={ `${ props.columnClass } cell form-container-wrapper` }>
      <div className="grid-x grid-padding-x">
        <div className="small-12 cell">
          {
            props.formTitle !== undefined &&
            <p className="form-title">{ props.formTitle }</p>
          }
          {
            props.formSubtitle !== undefined &&
            <p className="form-subtitle">{ props.formSubtitle }</p>
          }
        </div>
        <div className="small-12 medium-11 cell children-container">
          { props.children }
        </div>
      </div>
    </div>
  );
}

export default FormContainer;
