const Parse = require('parse/node');
Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, process.env.REACT_APP_PARSE_JS_KEY);
Parse.serverURL = process.env.REACT_APP_PARSE_URL;

export const getCompanyConfig = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetDashboardConfig', params)
    .then((results) => {
      resolve(results);
    }).catch((error) => {
      reject(error);
    });
  });
};

export const getUserGeoIp = () => {
  const geoAPI = process.env.REACT_APP_GEOIP_API;
  
  return new Promise((resolve, reject) => {
    fetch(geoAPI).then(response => response.json())
    .then(data => {
      resolve(data);
    })
    .catch((error) => {
      console.error('error', error);
      reject(error);
    });
  });
};