import React from 'react';
import { Helmet } from 'react-helmet';
import './Lessons.scss';
import Card from '../../../components/global/Card';

export default function Lessons(props) {
  const { vocabulary, siteContent } = props;
  const s3Url = process.env.REACT_APP_S3_IMAGES_URL;

  let list = [
    {
      text: 'dashboard.lessons.plans',
      to: 'lessons/plans/lessons',
      src: 'lesson-plans.png',
    },
    {
      text: 'dashboard.lessons.minilessons',
      to: 'lessons/AGmini-lessons',
      src: 'Amelia.svg',
    },
    {
      text: 'dashboard.lessons.ELAIntegratedLessons',
      to: 'lessons/elaIntegrated-lessons',
      src: 'TeacherKaPow.svg',
    },
    {
      text: 'dashboard.lessons.activities',
      to: 'lessons/unplugged-activities',
      src: 'unplugged-activities.png',
    },
    {
      text: 'dashboard.lessons.plans.glossary',
      to: 'lessons/plans/glossary',
      src: 'resources_vocabulary.png',
    },
  ];

  if (!siteContent.isPartner) {
    list.splice(3, 0, {
      text: 'dashboard.lessons.summerJump',
      to: 'lessons/summer-jump',
      src: 'summerglitch.png',
    });
    // list.splice(3, 0, { text: 'dashboard.lessons.hourOfCode', to: 'LessonsHourOfCode', src: 'hour-of-code.png' });
  }

  return (
    <div className="lessons-container page page-gray">
      <Helmet>
        <title>Lesson Planning | codeSpark Academy Teacher Dashboard</title>
      </Helmet>
      <ul>
        {list.map((item, i) => (
          <li key={item.to}>
            <Card
              text={item.text}
              to={item.to}
              src={`${s3Url}/${item.src}`}
              filename={item.filename}
              name={item.name}
              page={item.page}
              hasDownloads={item.hasDownloads}
              footnote={item.footnote}
              vocabulary={vocabulary}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
