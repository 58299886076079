import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import './ClassroomsCreateSuccess.scss';
import Button from '../../../components/elements/Button';

class ClassroomsCreateSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      classroomId: '',
      classroomName: '',
      studentCount: '',
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({
        classroomId: this.props.location.state.classroomId,
        classroomName: this.props.location.state.classroomName,
        studentCount: this.props.location.state.studentCount,
      });
    }
  }

  render() {
    const { vocabulary } = this.props;
    const s3Url = process.env.REACT_APP_S3_IMAGES_URL;

    return (
      <div className="classrooms-create-success-container page">
        <div className="grid-x grid-padding-x">
          <div className="small-12 cell header">
            <h1 className="title">{vocabulary['dashboard.classrooms.success.header']}</h1>
            <h2 className="classroom-name">{this.state.classroomName}</h2>
            <p className="instructions">
              {vocabulary['dashboard.classrooms.success.message1']}&nbsp;
              {this.state.studentCount}&nbsp;
              {vocabulary['dashboard.classrooms.success.message2']}.
            </p>
            <h3>{vocabulary['dashboard.classrooms.success.getClassroomCodeHeader']}</h3>
            <p
              className="instructions"
              dangerouslySetInnerHTML={{ __html: vocabulary['dashboard.classrooms.success.getClassroomCodeMessage'] }}
            ></p>
          </div>
          <div className="small-12 cell setup-instructions">
            <div className="setup-block">
              <div className="setupImg">
                <img alt="" src={`${s3Url}/Classroom_page_Generate_Code.png`} />
              </div>
              <p>{vocabulary['dashboard.classrooms.success.step1']}</p>
            </div>
            <div className="setup-block">
              <div className="setupImg">
                <img alt="" src={`${s3Url}/Class_Share_Code.png`} />
              </div>
              <p>{vocabulary['dashboard.classrooms.success.step2']}</p>
            </div>
            <p className="secondary-instructions">{vocabulary['dashboard.classrooms.success.codeTimeLimit']}</p>
          </div>

          <div div className="small-12 cell header">
            <h3>{vocabulary['dashboard.classrooms.success.nextStepsHeader']}</h3>
            <p
              className="instructions"
              dangerouslySetInnerHTML={{ __html: vocabulary['dashboard.classrooms.success.nextStepsMessage'] }}
            ></p>
          </div>
          <div className="small-12 cell setup-instructions">
            {process.env.REACT_APP_NODE_ENV === 'beta' ? (
              <div className="setup-block">
                <p dangerouslySetInnerHTML={{ __html: vocabulary['dashboard.classrooms.success.step3.beta'] }}></p>
              </div>
            ) : (
              <div className="setup-block">
                <p dangerouslySetInnerHTML={{ __html: vocabulary['dashboard.classrooms.success.step3'] }}></p>
              </div>
            )}
            <div className="setup-block">
              <div className="setupImg">
                <img alt="" src={`${s3Url}/school-mode-select-schools.jpg`} />
              </div>
              <p>{vocabulary['dashboard.classrooms.success.step4']}</p>
            </div>
            <div className="setup-block">
              <div className="setupImg">
                <img alt="" src={`${s3Url}/school-mode-select-students.jpg`} />
              </div>
              <p>{vocabulary['dashboard.classrooms.success.step5']}</p>
            </div>
            <div className="setup-block">
              <div className="setupImg">
                <img alt="" src={`${s3Url}/school-mode-select-class.jpg`} />
              </div>
              <p>{vocabulary['dashboard.classrooms.success.step6']}</p>
            </div>
            <div className="setup-block">
              <div className="setupImg">
                <img alt="" src={`${s3Url}/school-mode-play.jpg`} />
              </div>
              <p>{vocabulary['dashboard.classrooms.success.step7']}</p>
            </div>
            <div className="setup-block">
              <div className="setupImg">
                <img alt="" src={`${s3Url}/school-mode-classroom.jpg`} />
              </div>
              <p dangerouslySetInnerHTML={{ __html: vocabulary['dashboard.classrooms.success.step8'] }}></p>
            </div>
            {this.state.classroomId !== '' && (
              <Button
                to={`/dashboard/classroom/${this.state.classroomId}`}
                linkParams={{ id: this.state.classroomId }}
                text={vocabulary['dashboard.classrooms.success.go']}
                extraClass="go-to-class alt-orange"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ClassroomsCreateSuccess);
