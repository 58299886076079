import React from 'react';

import './Play.scss';

export default function Play(props) {
  const { currLocale } = props;
  const s3Url = process.env.REACT_APP_S3_IMAGES_URL;
  const playUrl = process.env.REACT_APP_CODESPARK_BASE_URL;

  return (
    <div className="page page-gradient">
      <div className="play-container">
        <div className="content">
          <h2>To play codeSpark Academy go to <a href={`${playUrl}/play`}>{`${playUrl}/play`}</a></h2>
          <p>The app is currently available for free on all tablets and computers.</p>
          <ul className="app-links">
            <li>
              <a className="app-badge" href="https://itunes.apple.com/us/app/codespark-academy/id923441570?mt=8">
                <img src={`${s3Url}/apple_${currLocale}.png`} alt=""/>
              </a>
            </li>
            <li>
              <a className="app-badge" href="https://play.google.com/store/apps/details?id=org.codespark.thefoos&hl=en_US">
                <img src={`${s3Url}/google_${currLocale}.png`} alt="" />
              </a>
            </li>
            <li>
              <a className="app-badge" href="https://www.amazon.com/codeSpark-Academy-The-Foos/dp/B07B5DKFPJ">
                <img src={`${s3Url}/amazon_${currLocale}.png`} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}