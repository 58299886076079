import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import __has from 'lodash/has';
import __isEmpty from 'lodash/isEmpty';
import mixpanel from 'mixpanel-browser';

import './LoginFormWrapper.scss';
import { login, logout, getCurrentUser, getCurrentUserID } from '../../../services/teacher';
import { setIsCompletedProfile, setIsValidEmail } from '../../../redux/actions';
import Textbox from '../../elements/Textbox';
import Button from '../../elements/Button';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: ''
    }

    this.saveEmail = this.saveEmail.bind(this);
    this.setPasswordToSubmit = this.setPasswordToSubmit.bind(this);
    this.identifyUser = this.identifyUser.bind(this);
    this.trackLoginEvent = this.trackLoginEvent.bind(this);
  }

  saveEmail(elemName, value) {
    this.setState({ email: value });
  }

  setPasswordToSubmit(elemName, value) {
    this.setState({ password: value });
  }

  submitForm(e) {
    e.preventDefault();
    this.loginUser();
  }

  identifyUser(user) {
    mixpanel.identify(user.id);
  }

  trackLoginEvent() {
    if (!__isEmpty(getCurrentUser())) {
      mixpanel.track('Dashboard (v1.3): Button Click', {
        parseAccountId: getCurrentUserID(),
        name: 'Sign In',
      });
    }
  }

  getCodeNinjasDashURL() {
    let url = '';
    switch (process.env.REACT_APP_NODE_ENV) {
      case 'production':
        url = 'https://codeninjas.codespark.com';
        break;
      case 'alpha':
        url = 'https://codeninjas-alpha.codespark.com';
        break;
      case 'development':
      default:
        url = 'https://codeninjas-dev.codespark.com';
        break;
    }
    return url;
  }

  getCodeSparkDashURL() {
    let url = '';
    switch (process.env.REACT_APP_NODE_ENV) {
      case 'production':
        url = 'https://dashboard.codespark.com';
        break;
      case 'alpha':
        url = 'https://dashboard-alpha.codespark.com';
        break;
      case 'development':
      default:
        url = 'https://dashboard-dev.codespark.com';
        break;
    }
    return url;
  }

  loginUser() {
    this.showFormProcessing();

    login({
      username: this.state.email,
      password: this.state.password
    }).then((teacher) => {
      if (teacher.has('user')) {
        this.identifyUser(teacher.get('user'));
      }
      let errorStr;
      if (teacher.has('partner') && this.props.isPartner === false) {
        // they are a partner teacher (code ninjas) and are at regular dashboard, they should go to codeninjas
        errorStr = `Looks like you're trying to log into a different dashboard. Log into the <a href="${this.getCodeNinjasDashURL()}/login">Code Ninjas Dashboard</a>`;
        throw new Error(errorStr);
      } else if (!teacher.has('partner') && this.props.isPartner === true) {
        // they are not a partner teacher and are at code ninjas dashboard, they should go to codespark dashboard
        // TODO: if we have more partners in the future, use teacher.get('partner') to send them to the correct url
        errorStr = `Looks like you're trying to log into a different dashboard. Log into the <a href="${this.getCodeSparkDashURL()}/login">Teacher Dashboard</a>`;
        throw new Error(errorStr);
      }
      this.trackLoginEvent();

      // check if user has a public type email and prompt to change
      let invalidEmails = ['gmail', 'yahoo', 'hotmail', 'aol.com'];
      if (invalidEmails.some(email => teacher.get('user').get('email').includes(email))) {
        this.props.setIsValidEmail(false);
        return this.props.history.push('/profile');
      }
      // check if user has profile info filled out and if not redirect
      if (this.isProfileCompleted(teacher)) {
        this.props.history.push('/dashboard');
      }
    }).catch((error) => {
      // make sure they're logged out
      logout();
      const errorMessage = error.message.errors ? this.props.vocabulary['error.' + error.message.errors[0].messageKey] : error.message;
      this.setState({ error: errorMessage });
      this.removeFormProcessing();
      // notify('error', '', 5000);
    });
  }

  isProfileCompleted(teacher) {
    // continue onto dashboard if they are not in the US
    if (teacher.get('user').get('countryCode') !== "US") {
      return true;
    }

    if (teacher.attributes.hasOwnProperty('professionType') &&
        teacher.attributes.hasOwnProperty('school') &&
        teacher.attributes.hasOwnProperty('schoolAddress') &&
        teacher.attributes.hasOwnProperty('schoolCity') &&
        teacher.attributes.hasOwnProperty('schoolPhoneNum') &&
        teacher.attributes.hasOwnProperty('schoolState') &&
        teacher.attributes.hasOwnProperty('schoolType')
    ) {
      return true;
    } else {
      this.props.setIsCompletedProfile(false);
      this.props.history.push('/profile');
    };
  }

  showFormProcessing() {
    //change the button style - this is an isolated event
    document.getElementById('login-submit').classList.remove('alt-orange');
    document.getElementById('login-submit').classList.add('processing', 'alt-gray');
    document.getElementById('login-submit').value = 'PROCESSING...';
  }

  removeFormProcessing() {
    //return submit button back to normal state
    document.getElementById('login-submit').classList.remove('processing', 'alt-gray');
    document.getElementById('login-submit').classList.add('alt-orange');
    document.getElementById('login-submit').value = this.props.vocabulary['nav.login'];
  }

  render() {
    return (
      <div className="login-form-wrapper">
        <div className="line-error" dangerouslySetInnerHTML={{ __html: this.state.error }} />
        <form onSubmit={e => this.submitForm(e)}>
          <Textbox type="text" placeholder={this.props.vocabulary['dashboard.login.username']} name="login-email" onChange={this.saveEmail} noValidate={true} defaultValue={this.props.emailToSave} />
          <Textbox type="password" placeholder={this.props.vocabulary['dashboard.login.password']} name="login-password" onChange={this.setPasswordToSubmit} noValidate={true} />
          <Button text={this.props.vocabulary['nav.login']} type="form" id="login-submit" extraClass="alt-orange" />
        </form>
        <div className="more-links">
          <Link to="/password-recovery">{this.props.vocabulary['dashboard.login.forgotPassword']}</Link>
          {
            !this.props.isPartner &&
            <Link to="/register">{this.props.vocabulary['dashboard.login.needAccount']}</Link>
          }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setIsValidEmail,
  setIsCompletedProfile
}

export default withRouter(connect(null, mapDispatchToProps)(LoginForm));
