import React from 'react';
import { useLocation } from 'react-router-dom';

import TableView from '../TableView';

export default function TableWrapper(props) {
  const location = useLocation();
  const data = {
    filename: props.filename,
    name: props.name,
    page: props.page,
    hasDownloads: props.hasDownloads,
    footnote: props.footnote,
    itemIndex: props.itemIndex
  }

  return (
    <TableView
      resourceData={data}
      currLocale={props.currLocale}
      siteContent={props.siteContent}
      vocabulary={props.vocabulary}
    />
  )
}