import React from 'react';

import './FeatureCard.scss';

class FeatureCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        isEnabled: props.isEnabled,
    };

    this.toggleFeature = this.toggleFeature.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isEnabled !== this.props.isEnabled) {
      this.setState({ isEnabled: this.props.isEnabled });
    }
  }

  toggleFeature() {
    const { handleClick } = this.props;

    this.setState((prevState) => {
      if (handleClick) { handleClick(); }

      return {
        isEnabled: !prevState.isEnabled,
      };
    });
  }

  render() {
    const { isEnabled } = this.state;
    const { chapterImg, title, description } = this.props;
    const s3Url = process.env.REACT_APP_S3_IMAGES_URL;

    return (
      <div
        className={`feature-card-container ${isEnabled ? 'active' : ''}`}
        onClick={ this.toggleFeature }
      >
        <div className="feature-card">
          <img className="foos-img" src={chapterImg} alt="" />
          <h4>{title}</h4>
          <p>{description}</p>
          { !isEnabled &&
            <img className="feature-disable" src={`${s3Url}/stop.png`} alt="disabled" />
          }
        </div>
        <div className="feature-card-checkbox-highlight">
          <div className="feature-card-checkbox">
            { isEnabled &&
              <img src={`${s3Url}/check.png`} alt="" />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default FeatureCard;
