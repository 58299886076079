import React, { Component } from 'react';

import './Login.scss';
import FormContainer from '../../components/elements/FormContainer';
import LoginForm from '../../components/forms/Login';

function Partner(props) {
  const { siteContent } = props;
  const logo = siteContent.dashboard.content.logo;
  const s3Url = process.env.REACT_APP_S3_IMAGES_URL;

  return (
    <div className="partner-login-container page">
      <div className="logo">
        <img alt="codeSpark Academy + Code Ninjas" src={logo} />
      </div>
      <div className="welcome-text">
        <h2>{ props.vocabulary['dashboard.login.welcomeBack'] }</h2>
        <p>{ props.vocabulary['dashboard.login.welcomeMessage'] }</p>
      </div>
      <FormContainer>
        <div className="bullet-points">
          <div className="cool-image">
            <img alt="" src={`${s3Url}/partners/codeNinjas/ninja_glitch_thumbs_up.png`}/>
          </div>
          <p>Word-free coding</p>
          <p>Promotes creativity and making</p>
          <p>Accessible by all types of learners</p>
        </div>
        <div className="login-container">
          <p className="instructions">Log In to Get Started</p>
          <LoginForm vocabulary={props.vocabulary} isPartner={siteContent.isPartner} partner={siteContent.company} />
        </div>
      </FormContainer>
      <div className="trouble">
        <p>
          <span>Having trouble logging in?</span><br />
          Please contact <a href="mailto:cnsupport@codespark.com">cnsupport@codespark.com</a> and we&rsquo;ll be happy to help!
        </p>
      </div>
    </div>
  );
}

export default Partner;
