import React, { useState } from 'react';

import './PasswordRecovery.scss';

import PageHeader from '../../components/elements/PageHeader';
import FormContainer from '../../components/elements/FormContainer';
import RecoverForm from '../../components/forms/PasswordRecovery';
import Button from '../../components/elements/Button';

function PasswordRecovery(props) {
  const s3Url = process.env.REACT_APP_S3_IMAGES_URL;
  const [ confirmed, setConfirmed ] = useState(false);

  const setConfirmation = () => {
   setConfirmed(true);
  }

  return (
    <div className="password-recovery-container page">
      <div className="grid-x grid-padding-x header">
        {
          confirmed ? (
            <>
              <PageHeader
                pageTitle="Your Password Request has been sent."
                pageSubtitle="Check your email and follow the instructions to reset your Password."
              />
              <Button to="Login" type="in-page" text="Sign In" extraClass="alt-orange" />
            </>
          ) : (
            <>
              <PageHeader
                pageTitle="Forgot Password"
                pageSubtitle="Enter your Email below and we will send you a Password Reset email."
              />
              <FormContainer formTitle="" columnClass="small-12 medium-9 large-5">
                <RecoverForm vocabulary={ props.vocabulary } setConfirmation={ setConfirmation } />
              </FormContainer>
            </>
          )
        }
      </div>
      <div className="crop">
        <img className="bg-image" alt="" src={`${s3Url}/school.svg`} />
      </div>
    </div>
  );
}

export default PasswordRecovery;
