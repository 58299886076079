import React from 'react';
import __first from 'lodash/first';

import './DropdownSelect.scss';

class DropdownSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.selectedValue || __first(props.options),
    };

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.selectValue = this.selectValue.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options) {
      this.setState({ selected: __first(this.props.options) });
    }
  }

  toggleDropdown(e) {
    e.preventDefault();
    this.setState(prevState => ({
      show: !prevState.show,
    }));
  }

  selectValue(value, e) {
    const selected = { value: value, text: e.target.innerText };
    this.setState({ selected: selected });

    if (this.props.onSelect) this.props.onSelect(selected);
    this.toggleDropdown(e);
  }

  render() {
    const { options } = this.props;
    const show = this.state.show ? 'show' : '';
    let dropdownIcon;

    if (show){ 
      dropdownIcon = <i className="fas fa-chevron-up"></i>
    } else { 
      dropdownIcon = <i className="fas fa-chevron-down"></i>
    }

    if (!options || options.length === 0) return <div className="dropdown-select-container" />;

    return (
      <div className={`dropdown-select-container ${this.state.show ? 'show' : ''}`}>
        <button className="dropdown-btn" onClick={this.toggleDropdown}>{this.state.selected && this.state.selected.text} <span className="smallChevron" >{dropdownIcon}</span></button>
        <div className="dropdown-content">
        <div className="dropdown-menu-line"></div>
          {
            options && options.map(option => (
              <div key={option.value} onClick={(e) => this.selectValue(option.value, e)}>{option.text}</div>
            ))
          }
        </div>
        <div className="dropdown-overlay" onClick={this.toggleDropdown} />
      </div>
    );
  }
}

export default DropdownSelect;