import React from 'react';
import { Link } from 'react-router-dom';
import __isEmpty from 'lodash/isEmpty';
import __upperFirst from 'lodash/upperFirst';

import './ButtonWrapper.scss';

function Button(props) { 
  return (
    <div className="button-component-wrapper" type={ props.type }>
      {
        props.type === 'form' ? (
          <input 
            id={ props.id }
            type="submit" 
            className={ `button ${ !__isEmpty(props.extraClass) ? props.extraClass : '' }` } 
            value={ props.text } 
            data-on="click"
            data-event-category="Button - Form"
            data-event-action="Click"
            data-event-label={ props.text }
          />
        ) : (!__isEmpty(props.to)) ? (
          <Link
            className={ `button ${ !__isEmpty(props.extraClass) ? props.extraClass : '' }` }
            to={ props.to } params={ props.linkParams }
          >
            { props.text }
          </Link>
        ) : (!__isEmpty(props.href)) ? (
          <a
            className={ `button ${ !__isEmpty(props.extraClass) ? props.extraClass : '' }` }
            href={ props.href }
            target="_blank"
          >
            { props.text }
          </a>
        ) : (
          <button 
            className={ `button ${ !__isEmpty(props.extraClass) ? props.extraClass : '' }` } 
            onClick={ props.onClick !== undefined ? (e) => props.onClick(e) : undefined }
            data-on="click"
            data-event-category={ `Button - ${ __upperFirst(props.type) }` }
            data-event-action="Click"
            data-event-label={ props.text }
          >
            { props.text }
          </button>
        )
      }
    </div>
  );
}

export default Button;
