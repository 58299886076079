import React from 'react';
import { Link } from 'react-router-dom';
import { resetPassword } from '../../../services/teacher';

import './RecoveryFormWrapper.scss';
import Textbox from '../../../components/elements/Textbox';
import Button from '../../../components/elements/Button';

export default class RecoveryForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: ''
    }
    this.saveEmail = this.saveEmail.bind(this);
  }

  saveEmail(elemName, value) {
    this.setState({ email: value.toLowerCase() });
  }

  submitForm(e) {
    e.preventDefault();
    this.resetPasswordForUser();
  }

  resetPasswordForUser() {
    this.showFormProcessing();

    resetPassword(this.state.email).then(() => {
      this.removeFormProcessing();
      this.props.setConfirmation();
      this.setState({ email: '' });
    }).catch((error) => {
      const errorMessage = error.message.errors ? this.props.vocabulary['error.' + error.message.errors[0].messageKey] : error.message;
      this.setState({ error: errorMessage });
      this.invalidEmailError();
    });
  }

  showFormProcessing() {
    //change the button style - this is an isolated event
    document.getElementById('login-submit').classList.remove('alt-orange');
    document.getElementById('login-submit').classList.add('processing', 'alt-gray');
    document.getElementById('login-submit').value = 'PROCESSING...';
  }

  removeFormProcessing() {
    //return submit button back to normal state
    document.getElementById('login-submit').classList.remove('processing', 'alt-gray');
    document.getElementById('login-submit').classList.add('alt-orange');
    document.getElementById('login-submit').value = this.props.vocabulary['nav.login'];
  }

  invalidEmailError() {
    document.getElementById('login-submit').classList.remove('processing', 'alt-gray');
    document.getElementById('login-submit').classList.add('alt-orange');
  }

  render() {
    return (
      <div className="reset-pass-form-wrapper">
        <div className="line-error" dangerouslySetInnerHTML={{ __html: this.state.error }} />
        <form onSubmit={ e => this.submitForm(e) }>
          <Textbox type="text" placeholder="Email" name="login-email" onChange={ this.saveEmail } noValidate={ false } defaultValue={ this.props.emailToSave } />
          <Button text="Continue" type="form" id="login-submit" extraClass="alt-orange" />
        </form>
        <div className="more-links">
          <Link to="Login">Cancel &amp; Sign In</Link>
        </div>
      </div>
    );
  }
}
