import React from 'react';
import { Link } from 'react-router-dom';

import './DashboardButton.scss';
import { isLoggedIn, getCurrentUserID } from '../../../services/teacher';
import { Mixpanel } from '../../../utils/mixpanel';

class DashboardButton extends React.Component {
  constructor(props) {
    super(props);

    this.trackClickEvent = this.trackClickEvent.bind(this);
  }

  componentDidMount() {
    const { eventParams, href, id } = this.props;

    if (id && href && isLoggedIn()) {
      Mixpanel.track_links(`#${id}`, 'Dashboard (v1.3): Link Click', {
        parseAccountId: getCurrentUserID(),
        href,
        ...eventParams,
      });
    }
  }

  trackClickEvent(e) {
    const { eventParams, onClick } = this.props;

    if (eventParams && isLoggedIn()) {
      Mixpanel.track('Dashboard (v1.3): Button Click', {
        parseAccountId: getCurrentUserID(),
        ...eventParams,
      });
    }

    onClick(e);
  }

  render() {
    const {
      children,
      to,
      href,
      onClick,
      className,
      eventParams,
      ...props
    } = this.props;
    let button;

    if (to) {
      button = (
        <Link
          className={`dashboard-button ${className ? className : ''}`}
          to={to}
          {...props}
        >
          {children}
        </Link>
      );
    } else if (href) {
      button = (
        <a
          className={`dashboard-button ${className ? className : ''}`}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          {...props}
        >
          {children}
        </a>
      );
    } else {
      button = (
        <button
          className={`dashboard-button ${className ? className : ''}`}
          onClick={this.trackClickEvent}
          {...props}
        >
          {children}
        </button>
      );
    }

    return button;
  }
}

export default DashboardButton;
