import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import __includes from 'lodash/includes';

import './SubNav.scss';

export default function SubNav(props) {
  const location = useLocation();
  const { vocabulary, page, siteContent } = props;

  let pages = {
    lessons: [
      {
        text: 'dashboard.lessons.plans',
        to: '/dashboard/lessons/plans/lessons',
        filename: 'lessons.plans.lessons',
        name: 'LessonsPlansLessons',
        page: 'lessons',
        hasDownloads: true,
        footnote: '*These can be completed at any point in the sequence of lessons.',
      },
      {
        text: 'dashboard.lessons.minilessons',
        to: '/dashboard/lessons/AGmini-lessons',
        filename: 'lessons.minilessons',
      },
      {
        text: 'dashboard.lessons.ELAIntegratedLessons',
        to: '/dashboard/lessons/elaIntegrated-lessons',
        filename: 'lessons.elaIntegratedLesson',
      },
      {
        text: 'dashboard.lessons.activities',
        to: '/dashboard/lessons/unplugged-activities',
        filename: 'lessons.unplugged',
        name: 'LessonsUnpluggedActivities',
        page: 'lessons',
        hasDownloads: true,
      },
    ],
    resources: [
      {
        to: '/dashboard/resources/getting-started-guide',
        name: 'ResourcesGettingStartedGuide',
        text: 'dashboard.resources.gettingStartedGuide',
        page: 'resources',
      },
      {
        to: '/dashboard/resources/solution-guides',
        name: 'ResourcesSolutionGuides',
        text: 'dashboard.resources.solutionGuides',
      },
      {
        to: '/dashboard/resources/downloadable-images',
        name: 'ResourcesDownloadImages',
        text: 'dashboard.resources.downloadableImages',
        filename: 'resources.downloadable-images',
        page: 'resources',
        hasDownloads: true,
      },
      { to: '/dashboard/resources/faq', name: 'ResourcesFaq', text: 'dashboard.resources.faq', page: 'resources' },
      { to: '/dashboard/resources/contact-us', name: 'ResourcesContactUs', text: 'dashboard.resources.contactUs' },
    ],
  };

  if (siteContent && !siteContent.isPartner) {
    // pages.lessons.push({ text: 'dashboard.lessons.hourOfCode', to: 'LessonsHourOfCode' });
    pages.lessons.push({
      text: 'dashboard.lessons.summerJump',
      to: '/dashboard/lessons/summer-jump',
      filename: 'lessons.summerjump',
      name: 'LessonsSummerJump',
      page: 'lessons',
      hasDownloads: true,
    });
    pages.resources.splice(2, 0, {
      to: '/dashboard/resources/videos',
      name: 'ResourcesWebinars',
      text: 'dashboard.resources.webinars',
      filename: 'resources.videos',
      page: 'resources',
    });
    pages.resources.splice(3, 0, {
      to: '/dashboard/resources/standards',
      name: 'ResourcesStandards',
      text: 'dashboard.resources.standards',
    });
    pages.resources.splice(4, 0, {
      to: '/dashboard/resources/for-parents',
      name: 'ResourcesForParents',
      text: 'dashboard.resources.forParents',
    });
  }

  return (
    <ul className="sub-nav-container">
      {page !== 'classrooms' &&
        pages[page].map((item) => (
          <li key={item.to} className={__includes(location.pathname, item.to) ? 'active' : ''}>
            <Link
              to={{
                pathname: `${item.to}`,
                state: {
                  filename: item.filename,
                  name: item.name,
                  page: item.page,
                  hasDownloads: item.hasDownloads,
                  footnote: item.footnote,
                },
              }}
            >
              {vocabulary[item.text]}
            </Link>
          </li>
        ))}
      {page === 'classrooms' && <li className="active">recent {vocabulary['dashboard.classroom.textPlural']}</li>}
      {page === 'classrooms' &&
        props.links.map((item) => (
          <li key={item.id} className={__includes(location.pathname, item.id) ? 'active' : ''}>
            <Link to={item.to} params={{ id: item.id }}>
              {item.text}
            </Link>
          </li>
        ))}
    </ul>
  );
}
