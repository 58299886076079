import React, { useState } from 'react';
import  __isEmpty from 'lodash/isEmpty';
import Popup from 'reactjs-popup';

import ImageMap from '../password_img_map.json';

export default function StudentPasswordRow(props) {
  const s3Url = process.env.REACT_APP_S3_IMAGES_URL;

  const [openPasswordPopup, setOpenPasswordPopup] = useState(false);

  const confirmResetPassword = (studentId) => {
    props.resetPassword(studentId);
    setOpenPasswordPopup(false);
  }

  return (
    <>
      <span className="password-pictures" onClick={() => setOpenPasswordPopup(true)}>
        {
          !__isEmpty(props.student.password) &&
            props.student.password.map((passImg, i) => (
              <img key={`img${i}`} src={ImageMap[passImg]} alt="password key map" />
            )
          )
        }
      </span>
      <Popup
        open={openPasswordPopup}
        className="password-popup"
        closeOnDocumentClick={false}
        onclose={() => setOpenPasswordPopup(false)}
      >
        <div className="popup-content">
          <div className="warning-content">
            <h4>Password Reset</h4>
            <h2>{props.student.name}</h2>
            <p>Current password will be removed, and student will be required to create a new password upon login.</p>
          </div>
          <div className="modal-options">
            <div className="options">
              <button onClick={() => setOpenPasswordPopup(false)}>
                <h5>Cancel</h5>
                <img src={`${s3Url}/stop.png`} alt="cancel" />
              </button>
            </div>
            <div className="options">
              <button onClick={() => confirmResetPassword(props.student.id)}>
                <h5>Confirm</h5>
                <img src={`${s3Url}/check.png`} alt="checkmark" />
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </>
  )
}
