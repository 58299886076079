import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { isLoggedIn, getTeacher, logout } from '../../../services/teacher';
import __includes from 'lodash/includes';
import __camelCase from 'lodash/camelCase';
import __toLower from 'lodash/toLower';
import __isEmpty from 'lodash/isEmpty';
import { Mixpanel } from '../../../utils/mixpanel';
import { getCurrentUserID } from '../../../services/teacher';
import './HeaderWrapper.scss';

import Button from '../../../components/elements/Button';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locales: [
        { value: 'en_US', text: 'English' },
        { value: 'es_MX', text: 'Español' },
        { value: 'de_DE', text: 'Deutsche' },
        { value: 'fr_FR', text: 'français' },
        { value: 'it_IT', text: 'italiano' },
        { value: 'jp_JP', text: '日本語' },
        { value: 'ko_KR', text: '한국어' },
        { value: 'nl_NL', text: 'Nederlands' },
        { value: 'pt_BR', text: 'português' },
        { value: 'ru_RU', text: 'русский' },
        { value: 'se_SE', text: 'svenska' },
        { value: 'th_TH', text: 'ไทย' },
        { value: 'zh_CN', text: '简体中文' },
        { value: 'zh_TW', text: '繁體中文' },
      ],
    };
  }

  componentDidMount() {
    if (isLoggedIn() && !this.state.teacherInitials) {
      this.fetchTeacherData();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.pathname !== this.props.location.pathname &&
      isLoggedIn() &&
      !this.state.teacherInitials
    ) {
      this.fetchTeacherData();
    }
  }

  sendMixpanelEvent(eventName, eventProperties) {
    console.info(
      `Sending Mixpanel event: ${eventName} for user: ${getCurrentUserID()}`
    );
    Mixpanel.track(eventName, {
      parseAccountId: getCurrentUserID(),
      ...eventProperties,
    });
  }

  fetchTeacherData() {
    getTeacher().then((teacher) => {
      const doesNameExist = !__isEmpty(teacher.get('firstName'));
      if (doesNameExist) {
        const firstInitial = teacher.get('firstName')[0];
        const lastInitial = teacher.get('lastName')[0];

        this.setState((prevState, props) => {
          return { teacherInitials: `${firstInitial}${lastInitial}` };
        });
      } else {
        this.setState({ teacherInitials: `N/A` });
      }
    });
  }

  handleLogout = (e) => {
    e.preventDefault();
    logout().then(() => {
      localStorage.clear();
      this.setState({ teacherInitials: undefined });
      this.props.history.push('/login');
    });
  };

  showLogin = () => {
    if (
      this.props.location.pathname === '/' ||
      this.props.location.pathname === '/register'
    ) {
      return true;
    } else {
      return false;
    }
  };

  isDashboardRoute = (route) => {
    const routes = ['/dashboard/*'];
    let regex = new RegExp(route);
    return routes.filter((route) => regex.test(route));
  };

  render() {
    const loggedIn = isLoggedIn();
    const { vocabulary, siteContent, location } = this.props;
    const logo = siteContent.dashboard.content.logo;

    if (!loggedIn && siteContent.isPartner) {
      return null;
    }

    return (
      <div className={`global-header-wrapper ${loggedIn ? 'dashboard' : ''}`}>
        <ul className="nav-links">
          {location.pathname !== '/more-info' && (
            <li className="logo-list-item">
              <Link to={loggedIn ? '/dashboard' : '/'}>
                <img
                  className={`header-logo ${__toLower(
                    __camelCase(siteContent.company)
                  )}`}
                  alt="codeSpark Teacher Dashboard"
                  src={logo}
                />
              </Link>
            </li>
          )}
          {this.isDashboardRoute(location.pathname) && loggedIn && (
            <>
              <li
                className={
                  location.pathname === '/dashboard/classrooms'
                    ? 'active active--blue'
                    : ''
                }
              >
                <Link
                  to="/dashboard/classrooms"
                  onClick={() =>
                    this.sendMixpanelEvent('Dashboard (v1.4): Link Clicked', {
                      destinationUrl: '/dashboard/classrooms',
                    })
                  }
                >
                  {vocabulary['nav.classrooms']}
                </Link>
              </li>
              <li
                className={`two-lines ${
                  location.pathname === '/dashboard/lessons' ? 'active' : ''
                }`}
              >
                <Link
                  to="/dashboard/lessons"
                  onClick={() =>
                    this.sendMixpanelEvent('Dashboard (v1.4): Link Clicked', {
                      destinationUrl: '/dashboard/lessons',
                    })
                  }
                >
                  {vocabulary['nav.lessons']}
                </Link>
              </li>
              <li
                className={
                  location.pathname === '/dashboard/resources' ? 'active' : ''
                }
              >
                <Link
                  to="/dashboard/resources"
                  onClick={() =>
                    this.sendMixpanelEvent('Dashboard (v1.4): Link Clicked', {
                      destinationUrl: '/dashboard/resources',
                    })
                  }
                >
                  {vocabulary['nav.resources']}
                </Link>
              </li>
              <li
                className={
                  location.pathname === '/dashboard/professional-development'
                    ? 'active'
                    : ''
                }
              >
                {__toLower(__camelCase(siteContent.company)) ===
                'codeninjas' ? (
                  <a
                    className="link-with-icon"
                    href="https://teach.codespark.com/courses/senseitraining"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      this.sendMixpanelEvent('Dashboard (v1.4): Link Clicked', {
                        destinationUrl:
                          'https://teach.codespark.com/courses/senseitraining',
                      })
                    }
                  >
                    {vocabulary['nav.development']}
                    <span className="external-url-icon"></span>
                  </a>
                ) : (
                  <a
                    className="link-with-icon"
                    href="https://teach.codespark.com/courses/codespark-ignite"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      this.sendMixpanelEvent('Dashboard (v1.4): Link Clicked', {
                        destinationUrl:
                          'https://teach.codespark.com/courses/codespark-ignite',
                      })
                    }
                  >
                    {vocabulary['nav.development']}
                    <span className="external-url-icon"></span>
                  </a>
                )}
              </li>
            </>
          )}
        </ul>
        <ul className="nav-links nav-links--right">
          {this.isDashboardRoute(location.pathname) && loggedIn && (
            <li
              className={
                location.pathname === '/dashboard/play' ? 'active no-arrow' : ''
              }
            >
              <Link to="/dashboard/play">{vocabulary['nav.play']}</Link>
            </li>
          )}
          {loggedIn && (
            <li>
              <a onClick={this.handleLogout}>{vocabulary['nav.logout']}</a>
            </li>
          )}
          {this.showLogin() && !loggedIn && (
            <li>
              <Link to="/login">{vocabulary['nav.login']}</Link>
            </li>
          )}
          {location.pathname === '/' && !loggedIn && (
            <li>
              <Button
                to="/register"
                text={vocabulary['dashboard.home.getStarted']}
                type="header"
                extraClass="alt-orange"
              />
            </li>
          )}
          {loggedIn && !siteContent.isPartner && (
            <li>
              <Link className="profile-link" to="/profile">
                <span>{this.state.teacherInitials}</span>
              </Link>
            </li>
          )}
        </ul>
      </div>
    );
  }
}

export default withRouter(Header);
