import React from 'react';

import './SolutionGuides.scss';
import Card from '../../../../components/global/Card';
import SubNav from '../../../../components/global/SubNav';

export default function SolutionGuides(props) {
  const { vocabulary, siteContent } = props;
  const list = [
    {
      to: 'solution-guides/overview',
      text: 'dashboard.resources.solutionGuides.overview',
    },
    {
      type: 'chapter',
      to: 'solution-guides/chapter1',
      level: '1',
    },
    {
      type: 'chapter',
      to: 'solution-guides/chapter2',
      level: '2',
    },
    {
      type: 'chapter',
      to: 'solution-guides/chapter3',
      level: '3',
    },
    {
      type: 'chapter',
      to: 'solution-guides/chapter4',
      level: '4',
    },
    {
      type: 'chapter',
      to: 'solution-guides/chapter5',
      level: '5',
    },
  ];

  return (
    <div className="resources-solution-guides-container page page-gray">
      <SubNav page="resources" vocabulary={vocabulary} siteContent={siteContent} />
      <ul className="cards">
        {
          list.map(item => (
            <li key={item.to}>
              <Card
                type={item.type}
                level={item.level}
                text={item.text}
                to={item.to}
                src={item.src}
                filename={item.filename}
                name={item.name}
                page={item.page}
                itemIndex={item.itemIndex}
                vocabulary={vocabulary}
              />
            </li>
          ))
        }
      </ul>
    </div>
  );
}
