import React from 'react';
import PropTypes from 'prop-types';

import './Checkbox.scss';

class Checkbox extends React.Component {
    render() {
        const { children, name, checked, onChange, extraClass } = this.props;
        return (
            <div className={ `checkbox-wrapper ${ extraClass }` }>
                <input id={ name } name={ name } type="checkbox" checked={ checked } onChange={ onChange } />
                <label htmlFor={ name }>{ children }</label>
            </div>
        );
    }
}

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    extraClass: PropTypes.string
}

export default Checkbox;
