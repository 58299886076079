import React from 'react';

import './FooterWrapper.scss';

function Footer(props) {
  const { siteContent, pathName } = props;
  const isPartner = siteContent.isPartner;
  const footerLinks = siteContent.dashboard.content.footer.links;

  return (
    <div className="footer-component">
      {
        (pathName === '/' || pathName === '/not-found' || (siteContent.isPartner && pathName === '/login')) && (
          <div className="social-links">
            <ul>
              <a href="https://www.facebook.com/pages/codeSpark/151949611657987?ref=br_tf" target="_blank" rel="noopener noreferrer">
                <li><i className="fab fa-facebook-f" aria-hidden="true"></i></li>
              </a>
              <a href="http://twitter.com/codesparkapp" target="_blank" rel="noopener noreferrer">
                <li><i className="fab fa-twitter" aria-hidden="true"></i></li>
              </a>
              <a href="https://www.instagram.com/codesparkapp/" target="_blank" rel="noopener noreferrer">
                <li><i className="fab fa-instagram" aria-hidden="true"></i></li>
              </a>
            </ul>
          </div>
        )
      }
      <div className="footer-links">
        <ul>
          <li>{ isPartner ? (
            <span className="powered-by">Powered by&nbsp;&nbsp;&nbsp;
              <img alt="codeSpark" src={`${process.env.REACT_APP_S3_IMAGES_URL}/logo_codeSpark_Glitch_Biting.png`}/>
              &nbsp;&nbsp;&copy; { new Date().getFullYear() }
            </span>
            ) : (
              <span>&copy;codeSpark { new Date().getFullYear() }</span>
            )
          }</li>
          {
            footerLinks.includes('About') &&
            <li><a target="_blank" href={ `${ process.env.REACT_APP_CODESPARK_BASE_URL }/about` }>About</a></li>
          }
          {
            footerLinks.includes('FAQ') &&
            <li><a target="_blank" href={ `${ process.env.REACT_APP_CODESPARK_BASE_URL }/faq` }>FAQ</a></li>
          }
          {
            footerLinks.includes('Stories') &&
            <li><a target="_blank" href={ `${ process.env.REACT_APP_BLOG_BASE_URL }` }>Stories</a></li>
          }
          {
            footerLinks.includes('Press') &&
            <li><a target="_blank" href={ `${ process.env.REACT_APP_CODESPARK_BASE_URL }/press` }>Press</a></li>
          }
          {
            footerLinks.includes('Privacy') &&
            <li><a target="_blank" href={ `${ process.env.REACT_APP_CODESPARK_BASE_URL }/privacy` }>Privacy</a></li>
          }
          {
            footerLinks.includes('Terms') &&
            <li><a target="_blank" href={ `${ process.env.REACT_APP_CODESPARK_BASE_URL }/terms` }>Terms</a></li>
          }
        </ul>
      </div>
    </div>
  );
}

export default Footer;