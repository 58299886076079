import React from 'react';

import './Community.scss';
import Button from '../../../components/elements/Button'

const Community = () => {
  const s3Url = process.env.REACT_APP_S3_IMAGES_URL;

  return (
    <div className="community-container">
      <div className="heading-section">
        <h1>Educator Programs</h1>
        <div className="inner-community-nav">
          <a href="#educators">Educators</a><span>|</span>
          <a href="#ambassador">Ambassadors</a><span>|</span>
          <a href="#leaders">Community Leaders</a><span>|</span>
          <a href="#advisors">Advisors</a>
        </div>
        <img className="landing-header-image" alt="" src={`${s3Url}/community/ambassador/ambassador_header.png`} />
      </div>
      <div className="info-section bronze" id="educators">
        <div className="grid-x grid-padding-x align-center">
          <div className="small-12 large-9 cell">
            <img className="medallion" alt="" src={`${s3Url}/community/ambassador/seal_bronze.png`} />
            <h2>codeSpark Educators</h2>
            <p>
              codeSpark Educators are public school teachers, administrators, and librarians who:
              <br /><br />
              1) Are advocates for accessibility in coding; 2) Are passionate about teaching coding to kids and acknowledge the challenges and barriers for themselves and their students; 3) Value silliness, creativity and exploration as well as share our beliefs in:
            </p>
            <br /><br />
            <div className="concepts">
              <div className="concept">
                <img alt="" src={`${s3Url}/community/ambassador/concept_ingenuity.png`} />
                <p>The Magic of Ingenuity</p>
              </div>
              <div className="concept">
                <img alt="" src={`${s3Url}/community/ambassador/concept_confidence.png`} />
                <p>The Power of Confidence</p>
              </div>
              <div className="concept stop-right-margin">
                <img alt="" src={`${s3Url}/community/ambassador/concept_experiment.png`} />
                <p>The Freedom of Experiment</p>
              </div>
            </div>
            <div className="concepts">
              <div className="concept">
                <img alt="" src={`${s3Url}/community/ambassador/concept_childhood.png`} />
                <p>The Wonder of Childhood</p>
              </div>
              <div className="concept stop-right-margin">
                <img alt="" src={`${s3Url}/community/ambassador/concept_bar.png`} />
                <p>The Power of Raising the Bar</p>
              </div>
            </div>
            <p>
              <br />
              <span className="bold-header">Benefits of becoming a codeSpark Educator:</span>
              <br /><br />
              <img className="check" alt="" src={`${s3Url}/community/ambassador/checkmark.png`} />100% free access to the full codeSparkAcademy app and teacher tools including: Curriculum, Unplugged Activities, Content Library and Lesson Plans.<br />
              <img className="check" alt="" src={`${s3Url}/community/ambassador/checkmark.png`} />Access to professional development resources from Launch CS<br />
              <img className="check" alt="" src={`${s3Url}/community/ambassador/checkmark.png`} />Exclusive discounts from codeSpark partners<br />
              <img className="check" alt="" src={`${s3Url}/community/ambassador/checkmark.png`} />Open channel to feedback on product releases and new feature development<br />
              <img className="check" alt="" src={`${s3Url}/community/ambassador/checkmark.png`} />Access to codeSparkAcademy support to troubleshoot any set-up and or curriculum questions.
              <br /><br />
              To become a codeSpark Educator all you have to do is create a free account at dashboard.codespark.com.
            </p>
            {/* <Button type="in-page" text="Become an Educator" to="Register" extraClass="alt-blue" /> */}
          </div>
        </div>
      </div>
      <div className="info-section silver" id="ambassador">
        <div className="grid-x grid-padding-x align-center">
          <div className="small-12 large-9 cell">
            <img className="medallion" alt="" src={`${s3Url}/community/ambassador/seal_silver.png`} />
            <h2>codeSpark Educator Ambassador</h2>
            <p>
              codespark educators who want to take their coding instruction to the next level can apply to be a codeSpark Ambassador.
              <br /><br />
              Ambassadors are members of the codeSpark Educator community who are actively using codeSpark in the classroom, and wish to share tips and gain insights from fellow codeSpark Ambassadors.
            </p>
            <p className="center"><span className="bold-header">In addition to the benefits received as a codeSpark Educator, Ambassadors may receive:</span></p>
            <p>
              <img className="check" alt="" src={`${s3Url}/community/ambassador/checkmark.png`} />Discounted Professional development resources from Launch CS.<br />
              <img className="check" alt="" src={`${s3Url}/community/ambassador/checkmark.png`} />Access to Slack Group for Ambassadors<br />
              <img className="check" alt="" src={`${s3Url}/community/ambassador/checkmark.png`} />Hour of Code Swag Pack - T-shirt, stickers, posters, getting started guides during Hour of Code.<br />
              <img className="check" alt="" src={`${s3Url}/community/ambassador/checkmark.png`} />Ambassador Badge for use on your blog, twitter, and other social outlets.<br />
              <img className="check" alt="" src={`${s3Url}/community/ambassador/checkmark.png`} />codeSpark social amplification of Ambassador’s coding-related programs and initiatives. <br />
              <br /><br />
              codeSpark Educator Ambassadors must be actively using codeSpark in their classrooms and submit an application that will require two reviews of codeSpark - one on either Common Sense Education or comparable educational blog (not your own) and one in the App Store.
            </p>
            <Button type="in-page" href="https://docs.google.com/forms/d/e/1FAIpQLSdeFBQr_8NQ_weConqWIGas9x4De2mLy0ss3nY_SS3pEJ6POw/viewform" text="Apply to the Educator Ambassador Program" extraClass="alt-blue" />
          </div>
        </div>
      </div>
      <div className="info-section gold" id="leaders">
        <div className="grid-x grid-padding-x align-center">
          <div className="small-12 large-9 cell">
            <img className="medallion" alt="" src={`${s3Url}/community/ambassador/seal_gold.png`} />
            <h2>codeSpark Community Leaders</h2>
            <p>
              codeSpark Community Leaders are educators who have shown a dedication to making coding accessible within their local community.
              <br /><br />
              Community leaders must be active codeSpark Educators or Ambassadors and be nominated by a fellow teacher or member of an organization such as code.org, CSTA, CSforAll or codeSpark Educator Advisors.
              <br /><br />
              <span className="bold-header">In addition to the benefits received as a codeSpark Educator and Ambassadors, Community Leaders may receive:</span>
              <br /><br />
              <img className="check" alt="" src={`${s3Url}/community/ambassador/checkmark.png`} />Discounted Professional development resources from Launch CS.<br />
              <img className="check" alt="" src={`${s3Url}/community/ambassador/checkmark.png`} />Access to Slack Group for Community Leaders. <br />
              <img className="check" alt="" src={`${s3Url}/community/ambassador/checkmark.png`} />Sponsorship of local coding events hosted by the Community Leaders<br />
              <img className="check" alt="" src={`${s3Url}/community/ambassador/checkmark.png`} />Hour of Code Swag Pack - T-shirts, stickers, posters, getting started guides during Hour of Code.<br />
              <img className="check" alt="" src={`${s3Url}/community/ambassador/checkmark.png`} />Exclusive codeSpark Gear - To sport in class and at conferences. <br />
              <img className="check" alt="" src={`${s3Url}/community/ambassador/checkmark.png`} />Can apply for sponsorship of conferences such as CSTA, ISTE, etc.<br />
              <img className="check" alt="" src={`${s3Url}/community/ambassador/checkmark.png`} />Community Leader Badge for use on your blog, twitter, and other social outlets.<br />
              <img className="check" alt="" src={`${s3Url}/community/ambassador/checkmark.png`} />codeSpark social amplification of Community Leader’s coding-related programs and initiatives.
              <br /><br />
              There is a limit of 125 spaces for this Community Leader program, and Community Leaders will have to reapply after a period of time to retain their Community Leader positions.
            </p>
            <Button type="in-page" href="https://docs.google.com/forms/d/e/1FAIpQLScsAEu8-hTwjs8Sdq4vHRcoG7AB4pcNXRvr0OWyrZc69MWG6Q/viewform" text="Apply to Community Leader Program" extraClass="alt-blue" />
          </div>
        </div>
      </div>
      <div className="info-section platinum" id="advisors">
        <div className="grid-x grid-padding-x align-center">
          <div className="small-12 large-9 cell">
            <img className="medallion" alt="" src={`${s3Url}/community/ambassador/seal_platinum.png`} />
            <h2>The codeSpark Educator Advisors</h2>
            <p>
              Our six Educator Advisors are the pillars of the codeSpark Educator community. They act as guides for how to address accessibility barriers in teaching computer science through these subjects to codeSpark educators and codeSpark.
              <br /><br />
              codeSpark Educator Advisors also represent codeSpark to the broader education and parenting community as codeSpark continues its mission to make computer science education accessible to kids around the world.
            </p>
            <br /><br />
            <div className="advisors">
              <div className="advisor-block">
                <div className="ad-image image-1">
                  <img alt="" src={`${s3Url}/community/ambassador/advisor_j_nishimoto.png`} />
                </div>
                <p>
                  <span className="bold-header">Jennifer Nishimoto</span><br />
                  <span className="bold-header">Coding for Early Learners</span><br />
                  Los Angeles, CA<br />
                  <span className="twitter-handle">@JNshimoto</span><br />
                  <span className="email-address">J.Nishimoto@codespark.com</span>
                </p>
              </div>
              <div className="advisor-block">
                <div className="ad-image image-2">
                  <img alt="" src={`${s3Url}/community/ambassador/advisor_mike.jpg`} />
                </div>
                <p>
                  <span className="bold-header">Michael Harvey</span><br />
                  <span className="bold-header">Coding and Making</span><br />
                  Fairmouth, ME<br />
                  <span className="twitter-handle">@mharveytech</span><br />
                  <span className="email-address">M.Harvey@codespark.com</span>
                </p>
              </div>
              <div className="advisor-block stop-right-margin">
                <div className="ad-image image-3">
                  <img alt="" src={`${s3Url}/community/ambassador/advisor_j_duda.png`} />
                </div>
                <p>
                  <span className="bold-header">Jennifer Kressier-Duda</span><br />
                  <span className="bold-header">Coding for Special Education</span><br />
                  Hamilton Township, NJ<br />
                  <span className="twitter-handle">@DynamicDuda338</span><br />
                  <span className="email-address">J.K.Duda@codespark.com</span>
                </p>
              </div>
            </div>
            <div className="advisors">
              <div className="advisor-block">
                <div className="ad-image image-4">
                  <img alt="" src={`${s3Url}/community/ambassador/advisor_kristen.jpeg`} />
                </div>
                <p>
                  <span className="bold-header">Kristen Brooks</span><br />
                  <span className="bold-header">Coding and Other Subjects</span><br />
                  Atlanta, GA<br />
                  <span className="twitter-handle">@KristenBrooks77</span><br />
                  <span className="email-address">K.Brooks@codespark.com</span>
                </p>
              </div>
              <div className="advisor-block stop-right-margin">
                <div className="ad-image image-5">
                  <img alt="" src={`${s3Url}/community/ambassador/advisor_ashley.jpg`} />
                </div>
                <p>
                  <span className="bold-header">Ashley Cooksey</span><br />
                  <span className="bold-header">Coding and Early Literacy</span><br />
                  Batesville, AR<br />
                  <span className="twitter-handle">@AshleyCooksey2</span><br />
                  <span className="email-address">A.Cooksey@codespark.com</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Community;
