import React from 'react';
import { withRouter } from 'react-router-dom';
import 'whatwg-fetch';

import { Mixpanel } from '../../../utils/mixpanel';
import './MoreInfoFormWrapper.scss';
import { update, syncClassrooms } from '../../../services/teacher';
import { notify } from '../../../utils/functions';
import Textbox from '../../../components/elements/Textbox';
import Button from '../../../components/elements/Button';
import Dropdown from '../../../components/elements/Dropdown';
import Checkbox from '../../../components/elements/Checkbox';

/**
 * MoreInfoForm - This is a smaller version of the RegisterForm for oauth teachers.
 *                firstName, lastName and email should be taken directly from the oauth info if possible.
 *                Note: we if we don't get firstName and lastName from google classroom, we need to add
 *                it here. See RegisterForm for an example.
 */
class MoreInfoForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // form objects
      profession: {value: '', error: ''},
      country: {value: '', error: ''},
      usState: {value: '', error: ''},
      district: {value: '', error: ''},
      address: {value: '', error: ''},
      city: {value: '', error: ''},
      schoolType: {value: '', error: ''},
      schoolName: {value: '', error: ''},
      schoolPhone: {value: '', error: ''},
      selectedReferral: {value: '', error: ''},
      confirmTerms: {value: false, error: ''},
      confirmPromoEmails: {value: false, error: ''},

      // lists
      countriesList: [],
      usStatesList: [],
      districtsList: [],
      referralTypes: [
        { value: '', text: props.vocabulary['register.referrerType'] },
        { value: 'code.org/Hour of Code', text: props.vocabulary['select.refer.hoc'] },
        { value: 'Search', text: props.vocabulary['select.refer.search'] },
        { value: 'Apple or Google App Store', text: props.vocabulary['select.refer.appstore'] },
        { value: 'Social Media', text: props.vocabulary['select.refer.social'] },
        { value: 'Teacher/Parent', text: props.vocabulary['select.refer.teacherParent'] },
        { value: 'Student Request', text: props.vocabulary['select.refer.student'] },
        { value: 'Other', text: props.vocabulary['select.refer.other'] },
      ],
      schoolTypes: [
        { value: '', text: props.vocabulary['register.schoolType'] },
        { value: 'Public School', text: props.vocabulary['select.school.public'] },
        { value: 'Private School', text: props.vocabulary['select.school.private'] },
        { value: 'Charter School', text: props.vocabulary['select.school.charter'] },
        { value: 'Public Library', text: props.vocabulary['select.school.library'] },
        { value: 'Coding Club', text: props.vocabulary['select.school.club'] },
        { value: 'Home School', text: props.vocabulary['select.school.home'] }
      ],
      professionTypes: [
        { value: '', text: props.vocabulary['register.professionType'] },
        { value: 'Teacher', text: props.vocabulary['select.profession.teacher'] },
        { value: 'Instructional Tech Coach', text: props.vocabulary['select.profession.it'] },
        { value: 'Librarian/Media Specialist', text: props.vocabulary['select.profession.librarian'] },
        { value: 'School Administrator', text: props.vocabulary['select.profession.schooladmin'] },
        { value: 'Coding Club Coach', text: props.vocabulary['select.profession.codingclub'] },
        { value: 'Homeschooler', text: props.vocabulary['select.profession.homeschool'] },
        { value: 'Non-profit Organizer', text: props.vocabulary['select.profession.nonprofit'] },
        { value: 'Professional Development Instructor', text: props.vocabulary['select.profession.profdevelopment'] },
      ],
      error: "",
    };

    // handlers
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.trackUpdateEvent = this.trackUpdateEvent.bind(this);
  }

  componentDidMount() {
    //get the lists for countries and states
    fetch('https://thefoos-com2.s3.amazonaws.com/dashboard/locale/countries.json')
    .then(response => response.json())
    .then(countries => {
      countries.unshift({ name: this.props.vocabulary['register.country'], code: '' });
      this.setState({ countriesList: countries });
    });

    fetch('https://thefoos-com2.s3.amazonaws.com/dashboard/locale/states.json')
    .then(response => response.json())
    .then(states => {
      states.unshift({ name: this.props.vocabulary['register.state'], code: '' });
      this.setState({ usStatesList: states });
    });
  }

  handleInputChange(name, value) {
    const obj = this.state[name];
    obj.value = value;
    this.setState({ [name]: obj });
    this.validateForm(name);
  }

  handleDropdownChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    const obj = this.state[name];
    obj.value = value;
    this.setState({ [name]: obj }, () => {
      if (name === 'usState') {
        if (value === '') {
          this.setState({ districtsList: [] });
        } else {
          fetch(`https://thefoos-com2.s3.amazonaws.com/dashboard/locale/schoolDistricts/${ value }.json`)
          .then(response => response.json())
          .then(districts => {
            districts.unshift({ name: this.props.vocabulary['register.schoolDistrict'], code: '' });
            this.setState({ districtsList: districts });
          });
        }
      }
    });
    this.validateForm(name);
  }

  handleCheckboxChange(e) {
    const obj = this.state[e.target.name];
    obj.value = e.target.checked;
    this.setState({ [e.target.name]: obj });
    this.validateForm(e.target.name);
  }

  submitForm(e) {
    e.preventDefault();
    this.updateTeacher();
  }

  trackUpdateEvent(params) {
    const teacher = JSON.parse(sessionStorage.getItem('teacherData'));
    const opts = {
      parseAccountId: teacher.user.objectId,
      schoolType: params.schoolType,
      referrer: params.referrerType,
      profession: params.professionType,
      schoolDistrict: params.schoolDistrict,
      schoolAddress: params.schoolAddress,
      schoolCity: params.schoolCity,
      schoolState: params.schoolState,
    };
    Mixpanel.track('Dashboard (v1.3): Teacher Update', opts);
  }

  setError(field, error) {
    const obj = this.state[field];
    obj.error = error;
    this.setState({[field]: obj});
  }

  /**
   * validateForm - Validates the form for the given value or all fields if null
   * @param {*} fieldName - the field to validate - null for all
   */
  validateForm(fieldName) {
    var error = false;

    if ((!fieldName || fieldName === "profession") && !this.state.profession.value) {
      this.setError('profession', this.props.vocabulary['error.TD_ProfessionEmpty']);
      error = true;
    } else if (fieldName === "profession") {
      this.setError('profession', '');
    }

    if ((!fieldName || fieldName === "country") && !this.state.country.value) {
      this.setError('country', this.props.vocabulary['error.TD_CountryEmpty']);
      error = true;
    } else if (fieldName === "country") {
      this.setError('country', '');
    }

    // IF REGISTERING IN THE US
    if (this.state.country.value === 'US') {

      if ((!fieldName || fieldName === "usState") && !this.state.usState.value) {
        this.setError('usState', this.props.vocabulary['error.TD_StateEmpty']);
        error = true;
      } else if (fieldName === "usState") {
        this.setError('usState', '');
      }

      if ((!fieldName || fieldName === "district") && (!this.state.district.value || this.state.district.value === this.props.vocabulary['register.schoolDistrict'])) {
        this.setError('district', this.props.vocabulary['error.TD_SchoolDistrictEmpty']);
        error = true;
      } else if (fieldName === "district") {
        this.setError('district', '');
      }

      if ((!fieldName || fieldName === "address") && !this.state.address.value) {
        this.setError('address', this.props.vocabulary['error.TD_AddressEmpty']);
        error = true;
      } else if (fieldName === "address") {
        this.setError('address', '');
      }

      if ((!fieldName || fieldName === "city") && !this.state.city.value) {
        this.setError('city', this.props.vocabulary['error.TD_CityEmpty']);
        error = true;
      } else if (fieldName === "city") {
        this.setError('city', '');
      }
    }

    if ((!fieldName || fieldName === "schoolType") && !this.state.schoolType.value) {
      this.setError('schoolType', this.props.vocabulary['error.TD_SchoolTypeEmpty']);
      error = true;
    } else if (fieldName === "schoolType") {
      this.setError('schoolType', '');
    }

    if ((!fieldName || fieldName === "schoolName") && !this.state.schoolName.value) {
      this.setError('schoolName', this.props.vocabulary['error.TD_SchoolNameEmpty']);
      error = true;
    } else if (fieldName === "schoolName") {
      this.setError('schoolName', '');
    }

    if ((!fieldName || fieldName === "schoolPhone") && !this.state.schoolPhone.value) {
      this.setError('schoolPhone', this.props.vocabulary['error.TD_PhoneEmpty']);
      error = true;
    } else if (fieldName === "schoolPhone") {
      this.setError('schoolPhone', '');
    }

    if ((!fieldName || fieldName === "selectedReferral") && !this.state.selectedReferral.value) {
      this.setError('selectedReferral', this.props.vocabulary['error.TD_HowHearEmpty']);
      error = true;
    } else if (fieldName === "selectedReferral") {
      this.setError('selectedReferral', '');
    }

    if ((!fieldName || fieldName === "confirmTerms") && !this.state.confirmTerms.value) {
      this.setError('confirmTerms', this.props.vocabulary['error.TD_MustAgreeToTerms']);
      error = true;
    } else if (fieldName === "confirmTerms") {
      this.setError('confirmTerms', '');
    }

    if (error) {
      this.setState({error: this.props.vocabulary['error.TD_ErrorsInForm']});
    }

    return error;
  }

  updateTeacher() {
    this.setState({error: ""});
    if (this.validateForm()) {
      this.setState({error: this.props.vocabulary['error.TD_ErrorsInForm']});
      window.scrollTo(0, 0);
      return;
    }
    // processing
    this.showFormProcessing();

    const params = {
      school: this.state.schoolName.value,
      schoolType: this.state.schoolType.value,
      referrerType: this.state.selectedReferral.value,
      professionType: this.state.profession.value,
      schoolPhoneNum: this.state.schoolPhone.value,
      country: this.state.country.value,
      confirmPromoEmails: this.state.confirmPromoEmails.value,
    };

    if (this.state.usState.value !== '') {
      params.state = this.state.usState.value;
    }

    if (this.state.district.value !== '') {
      params.schoolDistrict = this.state.district.value;
    }

    if (this.state.address.value !== '') {
      params.schoolAddress = this.state.address.value;
    }

    if (this.state.city.value !== '') {
      params.schoolCity = this.state.city.value;
    }

    update(params).then((user) => {
      this.trackUpdateEvent(params);
      syncClassrooms().then((user) => {
        this.removeFormProcessing();
        this.props.history.push('/dashboard');
      }).catch((error) => {
        console.log("Error syncing classrooms", error);
        notify('error', `Error syncing classrooms: ${error}`, 5000);
        this.removeFormProcessing();
      });
    }).catch((error) => {
      console.log("Error updating", error);
      this.setState({error: this.props.vocabulary['error.TD_CantUpdate'] + error.code});
      this.removeFormProcessing();
    });
  }

  showFormProcessing() {
    //change the button style - this is an isolated event
    document.getElementById('register-submit').classList.remove('alt-orange');
    document.getElementById('register-submit').classList.add('processing', 'alt-gray');
    document.getElementById('register-submit').value = 'PROCESSING...';
  }

  removeFormProcessing() {
    //return submit button back to normal state
    document.getElementById('register-submit').classList.remove('processing', 'alt-gray');
    document.getElementById('register-submit').classList.add('alt-orange');
    document.getElementById('register-submit').value = this.props.vocabulary['moreinfo.submit'];
  }

  render() {
    let usStateElement, usStateError;
    let schoolAddressElement, schoolAddressError;
    let schoolCityElement, schoolCityError;

    if (this.state.country.value === 'US') {
      usStateError = this.state.usState.error ? <div className="line-error">{this.state.usState.error}</div> : "";
      usStateElement =
        <Dropdown name="usState"
          options={ this.state.usStatesList }
          onChange={ this.handleDropdownChange }
          selectedValue={ this.state.usState.value }
          specialKeys={ ['name', 'code'] } />

      schoolAddressError = this.state.address.error ? <div className="line-error">{this.state.address.error}</div> : "";
      schoolAddressElement =
        <Textbox type="text"
          placeholder={ this.props.vocabulary['register.schoolAddress'] }
          name="address"
          onChange={ this.handleInputChange }
          defaultValue={ this.state.address.value }
          extraClass="school" />

      schoolCityError = this.state.city.error ? <div className="line-error">{this.state.city.error}</div> : "";
      schoolCityElement =
        <Textbox type="text"
          placeholder={ this.props.vocabulary['register.schoolCity'] }
          name="city"
          onChange={ this.handleInputChange }
          defaultValue={ this.state.city.value }
          extraClass="school" />
    }

    // district
    let districtElement, districtError;
    if (this.state.usState.value) {
      districtError = this.state.district.error ? <div className="line-error">{this.state.district.error}</div> : "";
      districtElement =
        <Dropdown name="district"
          options={ this.state.districtsList }
          onChange={ this.handleDropdownChange }
          selectedValue={ this.state.district.value }
          specialKeys={ ['name', 'name'] } />
    }

    return (
      <div className="register-form-wrapper">
        { this.state.error ? <div id="general-error">{this.state.error}</div> : ""}
        <form onSubmit={ e => this.submitForm(e) }>
          { this.state.profession.error ? <div className="line-error">{this.state.profession.error}</div> : "" }
          <Dropdown name="profession"
            options={ this.state.professionTypes }
            onChange={ this.handleDropdownChange }
            selectedValue={ this.state.profession.value } />

          { this.state.country.error ? <div className="line-error">{this.state.country.error}</div> : "" }
          <Dropdown name="country"
            options={ this.state.countriesList }
            onChange={ this.handleDropdownChange }
            selectedValue={ this.state.country.value }
            specialKeys={ ['name', 'code'] } />

          {usStateError}
          {usStateElement}
          {districtError}
          {districtElement}
          {schoolAddressError}
          {schoolAddressElement}
          {schoolCityError}
          {schoolCityElement}

          { this.state.schoolType.error ? <div className="line-error">{this.state.schoolType.error}</div> : "" }
          <Dropdown name="schoolType"
            options={ this.state.schoolTypes }
            onChange={ this.handleDropdownChange }
            selectedValue={ this.state.schoolType.value } />

          { this.state.schoolName.error ? <div className="line-error">{this.state.schoolName.error}</div> : "" }
          <Textbox type="text" placeholder={ this.props.vocabulary['register.schoolName'] } name="schoolName" onChange={ this.handleInputChange } defaultValue={ this.state.schoolName.value } extraClass="school" />

          { this.state.schoolPhone.error ? <div className="line-error">{this.state.schoolPhone.error}</div> : "" }
          <Textbox type="tel" placeholder={ this.props.vocabulary['register.schoolPhoneNumber'] } name="schoolPhone" onChange={ this.handleInputChange } defaultValue={ this.state.schoolPhone.value } extraClass="school" />

          { this.state.selectedReferral.error ? <div className="line-error">{this.state.selectedReferral.error}</div> : "" }
          <Dropdown name="selectedReferral"
            options={ this.state.referralTypes }
            onChange={ this.handleDropdownChange }
            selectedValue={ this.state.selectedReferral.value } />

          { this.state.confirmTerms.error ? <div className="line-error">{this.state.confirmTerms.error}</div> : "" }
          <Checkbox name="confirmTerms"
            type="checkbox"
            checked={ this.state.confirmTerms.value }
            onChange={ this.handleCheckboxChange }>
              I have read and agree to the <a target="_blank" href={ `${ process.env.REACT_APP_CODESPARK_BASE_URL }/terms` }>Terms of Service</a> and <a target="_blank" href={ `${ process.env.REACT_APP_CODESPARK_BASE_URL }/privacy` }>Privacy Policy</a>.
        </Checkbox>

          <Checkbox
            name="confirmPromoEmails"
            type="checkbox"
            checked={ this.state.confirmPromoEmails.value }
            onChange={ this.handleCheckboxChange }>
              I would like occasional news and promotional emails.
          </Checkbox>
          <br />
          <Button text={ this.props.vocabulary['moreinfo.submit'] } type="form" id="register-submit" extraClass="alt-orange" />
        </form>
      </div>
    );
  }
}

export default withRouter(MoreInfoForm);
