import React from 'react';

import './ForParents.scss';
import Button from '../../../../components/elements/DashboardButton';
import SubNav from '../../../../components/global/SubNav';

export default function ForParents(props) {
  const { vocabulary, siteContent } = props;
  const s3ImageUrl = process.env.REACT_APP_S3_IMAGES_URL;
  const s3PdfUrl = process.env.REACT_APP_S3_PDF_URL;

  return (
    <div className="resources-for-parents-container page page-gray">
      <SubNav page="resources" vocabulary={vocabulary} siteContent={siteContent} />
      <div className="card">
        <div className="content">
          <div>
            <h2>Support for coding education</h2>
            <p>Stats and print-outs from code.org about the importance of coding education.</p>
          </div>
          <div>
            <Button
              id="view-reference"
              href="https://code.org/promote"
              eventParams={{ name: 'View References' }}
            >view references</Button>
          </div>
        </div>
        <div className="preview">
          <div>
            <img src={`${s3ImageUrl}/resources_code_org.png`} alt="" />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="content">
          <div>
            <h2>Intro letter to parents</h2>
            <p>Download and print this letter about codeSpark Academy for your students to take home to their parents. Available in English or Spanish.</p>
          </div>
        </div>
        <div className="preview">
          <div>
            <p>English</p>
            <Button
              id="intro-english"
              className="button--default"
              href={`${s3PdfUrl}/english/Letter_to_Parents-English.pdf`}
              eventParams={{ name: 'Download Intro Letter', language: 'English' }}
            ><img src={`${s3ImageUrl}/resources_letter.png`} alt="Intro Letter English" /></Button>
          </div>
          <div>
            <p>Spanish</p>
            <Button
              id="intro-spanish"
              className="button--default"
              href={`${s3PdfUrl}/spanish/Letter_to_Parents-Spanish.pdf`}
              eventParams={{ name: 'Download Intro Letter', language: 'Spanish' }}
            ><img src={`${s3ImageUrl}/resources_letter.png`} alt="Intro Letter Spanish" /></Button>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="content">
          <div>
            <h2>Login instructions - printable</h2>
            <p>This printable provides simple step-by-step login instructions for students and parents.</p>
          </div>
        </div>
        <div className="preview">
          <div>
            <Button
              id="login-instructions"
              className="button--default"
              href='https://cdn.buttercms.com/0ts7U0pCSiflNbVn3pqA'
              eventParams={{ name: 'Download Login Instructions' }}
            ><img src={`${s3ImageUrl}/login_instructions.png`} alt="Login Instructions" /></Button>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="content">
          <div>
            <h2>Student "Getting Started" Guide</h2>
            <p>Download this guide to send to students and parents who want more in-depth instructions for navigating the app.</p>
          </div>
        </div>
        <div className="preview">
          <div>
            <Button
              id="student-guide"
              className="button--default"
              href='https://cdn.buttercms.com/lqXyZwOpRUKFnIqYk18U'
              eventParams={{ name: 'Student Guide' }}
            ><img src={`${s3ImageUrl}/student_guide.png`} alt="Student Guide" /></Button>
          </div>
        </div>
      </div>
    </div>
  );
}
