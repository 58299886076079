import React from 'react';
import { Helmet } from 'react-helmet';

import './ContactUs.scss';

import SubNav from '../../../../components/global/SubNav';

export default function ContactUs(props) {
  const { vocabulary, siteContent } = props;

  // TODO: Add language translations

  let supportEmail = <a href="mailto:support@codespark.com">support@codespark.com</a>;
  if (siteContent.isPartner) {
    supportEmail = <a href="mailto:cnsupport@codespark.com">cnsupport@codespark.com</a>;
  }

  return (
    <div className="resources-contact-us-container page page-gray">
      <Helmet>
        <title>Contact Us | codeSpark Academy Teacher Dashboard</title>
      </Helmet>
      <SubNav page="resources" vocabulary={vocabulary} siteContent={siteContent} />
      <div className="card">
          <h2>Contact Us</h2>
          <p>
            If you are experiencing any trouble with the app or dashboard, email us
            at {supportEmail}. We will be happy to help!
          </p>
      </div>
    </div>
  );
}
