import React from 'react';
import __filter from 'lodash/filter';

import './ClassroomsDetailFeatures.scss';
import { GetClassroomDetailsWithPermissions, updateClassroomOptions, unlockFeaturesForStudents } from '../../../../services/classroom';
import Button from '../../../../components/elements/DashboardButton';
import FeatureCard from '../../../../components/global/FeatureCard';

const s3Url = process.env.REACT_APP_S3_IMAGES_URL;

const ChapterList = ({ classroom, list, vocabulary, handleClick }) => (
  <ul>
    {classroom.options && list.map(level => (
      <li key={level.stage}>
        <FeatureCard
          title={vocabulary[level.name]}
          description={vocabulary[level.topic]}
          isEnabled={!classroom.options[level.stage].isRestricted}
          chapterImg={`${s3Url}/${level.img}`}
          handleClick={() => handleClick(level.stage)}
        />
      </li>
    ))}
  </ul>
);

class ClassroomsDetailFeatures extends React.Component {
  constructor(props) {
    super(props);

    let gameOptions = [
      { img: 'foos_chapter1.png', section: 'puzzleLevels', stage: 'Chapter1', name: 'puzzle.chapter1.name', topic: 'topic.sequencing.title' },
      { img: 'foos_chapter2.png', section: 'puzzleLevels', stage: 'Chapter2', name: 'puzzle.chapter2.name', topic: 'topic.loops.title' },
      { img: 'foos_chapter3.png', section: 'puzzleLevels', stage: 'Chapter3', name: 'puzzle.chapter3.name', topic: 'topic.advSequencing.title' },
      { img: 'foos_chapter4.png', section: 'puzzleLevels', stage: 'Chapter4', name: 'puzzle.chapter4.name', topic: 'topic.events.title' },
      { img: 'foos_chapter5.png', section: 'puzzleLevels', stage: 'Chapter5', name: 'puzzle.chapter5.name', topic: 'topic.conditionals.title' },
      // explore
      { img: 'splash_clash_minigame.png', section: 'explore', stage: 'SplashClash', name: 'explore.splashclash.name', topic: 'topic.splashclash.description' },
      { img: 'foos_petcare.png', section: 'explore', stage: 'PetCare', name: 'studio.petcare.name', topic: 'studio.petcare.description' },
      { img: 'crocodile_catch.png', section: 'explore', stage: 'Temple', name: 'explore.temple.name', topic: 'topic.variablesInequalities.title' },
      { img: 'pet_stacker.png', section: 'explore', stage: 'PetStacker', name: 'explore.petstacker.name', topic: 'topic.stacksAndQueues.title' },
      { img: 'foos_minigame.png', section: 'explore', stage: 'SweetSorter', name: 'explore.sorter.name', topic: 'topic.booleans.title' },

      // create
      // create games
      { img: 'story_create.jpg', section: 'create', stage: 'FSDraft', name: 'studio.create.name', topic: 'studio.create.description' },
      // create adventure games
      { img: 'adventure_create.jpg', section: 'create', stage: 'AdventureCreate', name: 'adventure.create.name', topic: 'adventure.create.description' },
      // create stories
      { img: 'story_sharing.jpg', section: 'create', stage: 'StoryCreate', name: 'story.create.name', topic: 'story.create.description' },
      // store
      { img: 'foos_store_v2.jpg', section: 'create', stage: 'Shop', name: 'studio.store.name', topic: 'studio.store.description' },
      // community
      { img: 'foos_world_v2.jpg', section: 'create', stage: 'FSPublish', name: 'studio.world.name', topic: 'studio.world.description' },
      // story sharing
      { img: 'story_publish.jpg', section: 'create', stage: 'StorySharing', name: 'story.sharing.name', topic: 'story.sharing.description' },

      // other
      { img: 'foos_password.png', section: 'other', stage: 'StudentPasswords', name: 'permissions.passwords.name', topic: 'permissions.passwords.description' },
      { img: 'foos_news.png', section: 'other', stage: 'News', name: 'studio.news.name', topic: 'studio.news.description' },

      // camera and microphone permissions
      { img: 'microphone_permissions.png', section: 'storyTools', stage: 'MicrophonePermissions', name: 'permissions.mic.name', topic: 'permissions.mic.description' },
      { img: 'camera_permissions.png', section: 'storyTools', stage: 'CameraPermissions', name: 'permissions.cam.name', topic: 'permissions.cam.description' },

    ];

    this.state = {
      gameOptions: gameOptions,
      classroom: {},
      isAllUnlocked: true,
    };

    this.getClassroomDetails = this.getClassroomDetails.bind(this);
    this.toggleFeature = this.toggleFeature.bind(this);
    this.updateClassroomOptions = this.updateClassroomOptions.bind(this);
    this.unlockAll = this.unlockAll.bind(this);
  }

  componentDidMount() {
    this.getClassroomDetails();
  }

  componentDidUpdate(prevProps) {
    const { classroomId } = this.props;

    if (prevProps.classroomId !== classroomId) {
      this.getClassroomDetails();
    }
  }

  getClassroomDetails() {
    const { classroomId } = this.props;
    GetClassroomDetailsWithPermissions({ classroomId: classroomId }).then((results) => {
      let classroom = results.classroom;
      let permissions = results.permissions;
      const isStoryRestricted = permissions.get('storySharingLevel') !== 2;
      let classroomOptions = {};

      if (classroom.has('options')) {
        // patch : these came after launch
        if (classroom.get('options').hasOwnProperty('PetStacker') === false) {
          classroom.get('options').PetStacker = { isRestricted: false };
        }
        if (classroom.get('options').hasOwnProperty('PetCare') === false) {
          classroom.get('options').PetCare = { isRestricted: false };
        }
        if (classroom.get('options').hasOwnProperty('Temple') === false) {
          classroom.get('options').Temple = { isRestricted: false };
        }
        if (classroom.get('options').hasOwnProperty('StoryCreate') === false) {
          classroom.get('options').StoryCreate = { isRestricted: false };
        }
        if (classroom.get('options').hasOwnProperty('SplashClash') === false) {
          classroom.get('options').SplashClash = { isRestricted: false };
        }
        if (classroom.get('options').hasOwnProperty('CameraPermissions') === false) {
          classroom.get('options').CameraPermissions = { isRestricted: false };
        }
        if (classroom.get('options').hasOwnProperty('MicrophonePermissions') === false) {
          classroom.get('options').MicrophonePermissions = { isRestricted: false };
        }
        if (classroom.get('options').hasOwnProperty('AdventureCreate') === false) {
          classroom.get('options').AdventureCreate = { isRestricted: false };
        }
        if (classroom.get('options').hasOwnProperty('StudentPasswords') === false) {
          classroom.get('options').StudentPasswords = { isRestricted: true };
        }
        classroomOptions = { options: classroom.get('options') };
        // story mode works on the Permissions object in the database, so it's different than the rest
        classroomOptions.options.StorySharing = { isRestricted: isStoryRestricted }
      } else {
        classroomOptions = {
          options: {
            Chapter1: { isRestricted: false },
            Chapter2: { isRestricted: false },
            Chapter3: { isRestricted: false },
            Chapter4: { isRestricted: false },
            Chapter5: { isRestricted: false },
            Shop: { isRestricted: false },
            FSDraft: { isRestricted: false },
            FSPublish: { isRestricted: false },
            SweetSorter: { isRestricted: false },
            PetStacker: { isRestricted: false },
            News: { isRestricted: false },
            Temple: { isRestricted: false },
            StoryCreate: { isRestricted: false },
            StorySharing: { isRestricted: isStoryRestricted },
            SplashClash: { isRestricted: false },
            CameraPermissions: { isRestricted: false },
            MicrophonePermissions: { isRestricted: false },
            AdventureCreate: { isRestricted: false },
            StudentPasswords: { isRestricted: true },
          },
        };
      }

      this.setState({
        classroom: classroomOptions,
        isAllUnlocked: classroom.get('shouldUnlockAll'),
      });
    });
  }

  toggleFeature(stage) {
    this.setState((prevState) => {
      const stageOptions = prevState.classroom.options[stage];
      stageOptions.isRestricted = !stageOptions.isRestricted;
      this.updateClassroomOptions(prevState.classroom.options);
      return {
        classroom: prevState.classroom,
      }
    });
  }

  updateClassroomOptions(options) {
    updateClassroomOptions(this.props.classroomId, options);
  }

  unlockAll() {
    unlockFeaturesForStudents(this.props.classroomId).then(() => (
      this.setState({ isAllUnlocked: true })
    ));
  }

  render() {
    const { gameOptions, classroom, isAllUnlocked } = this.state;
    const { vocabulary } = this.props;
    return (
      <div className="classrooms-detail-features-container">
        <div>
          <h4>{vocabulary['dashboard.classrooms.features.puzzle']}</h4>
          <p>{vocabulary['dashboard.classrooms.features.puzzleDescription']}</p>
          <ChapterList
            classroom={classroom}
            list={__filter(gameOptions, { section: 'puzzleLevels' })}
            handleClick={this.toggleFeature}
            vocabulary={vocabulary}
          />
        </div>
        <div>
          <h4>{vocabulary['dashboard.classrooms.features.explore']}</h4>
          <p>{vocabulary['dashboard.classrooms.features.exploreDescription']}</p>
          <ChapterList
            classroom={classroom}
            list={__filter(gameOptions, { section: 'explore' })}
            handleClick={this.toggleFeature}
            vocabulary={vocabulary}
          />
        </div>
        <div>
          <h4>{vocabulary['dashboard.classrooms.features.create']}</h4>
          <p>{vocabulary['dashboard.classrooms.features.createDescription']}</p>
          <ChapterList
            classroom={classroom}
            list={__filter(gameOptions, { section: 'create' })}
            handleClick={this.toggleFeature}
            vocabulary={vocabulary}
          />
        </div>
        <div>
          <h4>{vocabulary['dashboard.classrooms.features.storyTools']}</h4>
          <p>{vocabulary['dashboard.classrooms.features.storyToolsDescription']}</p>
          <ChapterList
            classroom={classroom}
            list={__filter(gameOptions, { section: 'storyTools' })}
            handleClick={this.toggleFeature}
            vocabulary={vocabulary}
          />
        </div>
        <div>
          <h4>{vocabulary['dashboard.classrooms.features.other']}</h4>
          <p>{vocabulary['dashboard.classrooms.features.otherDescription']}</p>
          <ChapterList
            classroom={classroom}
            list={__filter(gameOptions, { section: 'other' })}
            handleClick={this.toggleFeature}
            vocabulary={vocabulary}
          />
        </div>
        <div>
          <h4>{vocabulary['dashboard.classrooms.features.unlock']}</h4>
          <p>{vocabulary['dashboard.classrooms.features.unlockDescription']}</p>
          {
            isAllUnlocked ? (
              <p>{vocabulary['dashboard.classrooms.features.everythingUnlocked']}</p>
            ) : (
                <Button
                  onClick={this.unlockAll}
                  eventParams={{ name: 'Unlock All Levels' }}
                >{vocabulary['dashboard.classrooms.features.buttonUnlock']}</Button>
              )
          }
        </div>
      </div>
    );
  }
}

export default ClassroomsDetailFeatures;
