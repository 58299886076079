import React from 'react';
import { withRouter } from 'react-router-dom';
import __fill from 'lodash/fill';

import '../../../../components/global/Table/Table.scss';
import './ResourceVideos.scss';
import SubNav from '../../../../components/global/SubNav';
import ToggleItem from '../../../../components/global/ToggleItem';
import videoFiles from './resource_videos.json';

class ResourceVideos extends React.Component {
  render() {
    const { vocabulary, siteContent, resourceData } = this.props;

    return (
      <div className="table-view-container page page-gray">
        <SubNav
          page="resources"
          vocabulary={vocabulary}
          siteContent={siteContent}
        />
        <div className="table">
          <div className="title">
            <h2>videos</h2>
          </div>
          <div className="videos-content">
            <h4>How to:</h4>
            {
              videoFiles.items_howto.map((item, index) => (
                <div key={item.title} className="row-container-videos">
                  <ToggleItem
                    title={item.title}
                    description={item.description}
                    link={item.link}
                  />
                </div>
              ))
            }

            <h4>
              Learn about different coding concepts and commands from these
              videos:
            </h4>
            {videoFiles.items_concepts.map((item, index) => (
              <div key={item.title} className="row-container-videos">
                <ToggleItem
                  title={item.title}
                  description={item.description}
                  link={item.link}
                />
              </div>
            ))}

            <h4>Here are example projects to inspire you!</h4>
            {videoFiles.items_examples.map((item, index) => (
              <div key={item.title} className="row-container-videos">
                <ToggleItem
                  title={item.title}
                  description={item.description}
                  link={item.link}
                />
              </div>
            ))}

            <h4>Other</h4>
            {videoFiles.items_others.map((item, index) => (
              <div key={item.title} className="row-container-videos">
                <ToggleItem
                  title={item.title}
                  description={item.description}
                  link={item.link}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResourceVideos);
