import React from 'react';
import { useLocation } from 'react-router-dom';

import './MoreInfo.scss';
import PageHeader from '../../components/elements/PageHeader';
import FormContainer from '../../components/elements/FormContainer';
import MoreInfoForm from '../../components/forms/MoreInfo';
const queryString = require('query-string');

export default function MoreInfo(props) {
  const { search } = useLocation();
  const parsedQuery = queryString.parse(search);
  const s3Url = process.env.REACT_APP_S3_IMAGES_URL;

  return (
    <div className="more-info-container page">
      <div className="grid-x grid-padding-x header">
        <PageHeader
          pageTitle={ props.vocabulary['moreinfo.header'] }
          pageSubtitle={ props.vocabulary['moreinfo.message'] }
        />
        <FormContainer formTitle="" columnClass="small-12 medium-9 large-6">
          <MoreInfoForm vocabulary={ props.vocabulary } code={ parsedQuery.code }/>
        </FormContainer>
      </div>
      <div className="crop">
        <img className="bg-image" alt="" src={`${s3Url}/school.svg`} />
      </div>
    </div>
  );
}
