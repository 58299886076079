import React, { Component } from 'react';
import { connect } from 'react-redux';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import __camelCase from 'lodash/camelCase';
import __toLower from 'lodash/toLower';
import __isEmpty from 'lodash/isEmpty';

import { setTeacherProfile } from '../../redux/actions';
import './Dashboard.scss';
import Button from '../../components/elements/DashboardButton';
import DropdownNav from '../../components/global/DropdownNav';
import Video from '../../components/global/Video';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      teacherInfo: JSON.parse(sessionStorage.getItem('teacherData')),
    }
  }

  componentDidMount() {
    this.props.setTeacherProfile(this.state.teacherInfo);
  }

  render() {
    const s3Url = process.env.REACT_APP_S3_IMAGES_URL;
    const s3BaseUrl = process.env.REACT_APP_BASE_S3_URL;
    const { vocabulary, siteContent } = this.props;

    //lessons conditional
    const lessonsOptions = [
      { to: '/dashboard/lessons/plans/lessons', 
        text: 'intro to cs',
        filename: 'lessons.plans.lessons',
        name: 'LessonsPlansLessons',
        page: 'lessons',
        hasDownloads: true,
        footnote: '*These can be completed at any point in the sequence of lessons.'
      },
      { to: '/dashboard/lessons/unplugged-activities', 
        text: 'unplugged activities',
        filename: 'lessons.unplugged',
        name: 'LessonsUnpluggedActivities',
        page: 'lessons',
        hasDownloads: true
      },
    ];
    if (this.props.siteContent.dashboard.modules.lessons.hourOfCode.show) {
      lessonsOptions.push({ to: 'LessonsHourOfCode', text: 'Hour of Code' });
    }
    if (this.props.siteContent.dashboard.modules.lessons.summerJump.show) {
      lessonsOptions.push({ 
        to: '/dashboard/lessons/summer-jump', 
        text: 'quick lessons',
        filename: 'lessons.summerjump',
        name: 'LessonsSummerJump',
        page: 'lessons',
        hasDownloads: true
      });
    }

    //resources conditional
    const resourcesOptions = [
      { to: '/dashboard/resources/getting-started-guide', text: 'getting started guides', name: 'ResourcesGettingStartedGuide', page: 'resources' },
      { to: '/dashboard/resources/solution-guides', text: 'solution guides', name: 'ResourcesSolutionGuides' },
    ];
    if (this.props.siteContent.dashboard.modules.resources.standards.show) {
      resourcesOptions.push({ to: '/dashboard/resources/standards', text: 'standards', name: 'ResourcesStandards' });
    }
    if (this.props.siteContent.dashboard.modules.resources.parents.show) {
      resourcesOptions.push({ to: '/dashboard/resources/for-parents', text: 'for parents', name: 'ResourcesForParents' });
    }
    resourcesOptions.push({ to: '/dashboard/resources/faq', text: 'FAQ', name: 'ResourcesFaq', page: 'resources' });
    resourcesOptions.push({ to: '/dashboard/resources/contact-us', text: 'contact us', name: 'ResourcesContactUs' });

    let trainingURL = 'https://teach.codespark.com/courses/codespark-ignite';
    if (__toLower(__camelCase(siteContent.company)) === 'codeninjas') {
      trainingURL = 'https://teach.codespark.com/courses/senseitraining';
    }

    return (
      <div className="dashboard-container page">
        <div className="home-into-container">
          <div className="home-slide-1">
              <div className="home-intro-video">
                <LazyLoad height={500} once offset={100}>
                  <div className="small-12 medium-7 cell trailer">
                    <Video
                      videoUrl={`${s3BaseUrl}/dashboard/videos/teacher_setup1.mp4`}
                      title="Teacher Setup Video"
                      poster={`${s3BaseUrl}/flagship/teacher-landing/teacher_video_poster.jpg`}>
                      Your browser does not support the video tag.
                    </Video>
                  </div>
              </LazyLoad>
              </div>
              <div className="home-slide-1-content">
                <div className="foo-shop-button">
                  <a href="https://shop.codespark.com/" target="_blank" rel="noopener noreferrer">
                    <img src={`${s3Url}/Icon_FooShop.png`} alt="Foo shop icon" />foo shop
                  </a>
                </div>
                {
                  siteContent.isPartner ? (
                    <h2>Welcome to your Dashboard!</h2>
                  ) : (
                    <h2>Welcome to your codeSpark<br />Teacher Dashboard!</h2>
                  )
                }
                <p>We’ll show you how to <Link to="/dashboard/classrooms">create a {vocabulary["dashboard.classroom.text"]}</Link>, 
                  manage { vocabulary["dashboard.student.text"] } progress, and control features in the codeSpark Academy app. 
                  <Link to="/dashboard/resources"> Read the full guide here.</Link></p>
                <br />
                <div className="center">
                  <Button to="/dashboard/classrooms" className="button--small button--wide bold-btn">Lets get started</Button>
                </div>
              </div>
              <img className="home-slide-1-bg-img" src={`${s3Url}/home_slide_1_ipad.png`} alt="" />
          </div>
        </div>
        <div className="dashboard-actions">
          <div className="dashboard-card">
            <Link to="/dashboard/classrooms"><div className="card-overlay-link"></div></Link>
            {
              this.state.teacherInfo?.isOauthTeacher ? (
                <Link to="/dashboard/classrooms"><button className="fake-dropdown-btn"><span className="float-left">View {vocabulary["dashboard.classroom.textUpperPlural"]}</span><span className="small-chevron float-right"><i className="fas fa-chevron-right"></i></span></button></Link>
              ) : (
                <DropdownNav
                  buttonText={vocabulary["dashboard.classroom.textUpperPlural"]}
                  options={[
                    { to: '/dashboard/classrooms', text: 'view' },
                    { to: '/dashboard/classrooms/create', text: 'add' },
                  ]}
                />
              )
            }
            <div className="dashboard-title">
              <img src={`${s3Url}/home-classrooms.png`} alt=""/>
            </div>
          </div>

          <div className="dashboard-card">
              <Link to="/dashboard/lessons"><div className="card-overlay-link"></div></Link>
              <DropdownNav
                  buttonText={vocabulary["dashboard.nav.lessons"]}
                  options={lessonsOptions}
              />
              <div className="dashboard-title">
                  <img src={`${s3Url}/home-lessons.png`} alt=""/>
              </div>
          </div>

          <div className="dashboard-card">
            <Link to="/dashboard/resources"><div className="card-overlay-link"></div></Link>
            <DropdownNav
              buttonText="Resources"
              options={resourcesOptions}
            />
            <div className="dashboard-title">
              <img src={`${s3Url}/home-resources.png`} alt=""/>
            </div>
          </div>

          <div className="dashboard-card">
            <a href={trainingURL} target="_blank" rel="noopener noreferrer"><div className="card-overlay-link"></div></a>
            <a href={trainingURL} target="_blank" rel="noopener noreferrer">
              <button className="fake-dropdown-btn">
                <span>{vocabulary["nav.development"]}</span>
                <span className="small-chevron">
                  <i className="fas fa-chevron-right"></i>
                </span>
              </button>
            </a>
            <div className="dashboard-title">
              <img src={`${s3Url}/home-development.png`} alt=""/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = { setTeacherProfile };

export default connect(null, mapDispatchToProps)(Dashboard);
