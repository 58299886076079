import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import './Register.scss';
import { getLoginLink } from '../../services/clever';
import { getUserGeoIp } from '../../services/parse';
import PageHeader from '../../components/elements/PageHeader';
import FormContainer from '../../components/elements/FormContainer';
import RegisterForm from '../../components/forms/Register';

function Register(props) {
  const s3Url = process.env.REACT_APP_S3_IMAGES_URL;

  useEffect(() => {
    getUserGeoIp().then((data) => {
      if (data.country === 'HK' || data.country === 'MC') {
        window.location.replace('https://caprikon.education/codespark');
      }
    });
  }, []);

  const loginWithClever = () => {
    window.open(getLoginLink(), '_self');
  }

  return (
    <div className="register-container page">
      <Helmet>
        <title>Register | codeSpark Academy for Educators</title>
      </Helmet>
      <div className="grid-x grid-padding-x header">
        <PageHeader
          pageTitle={ props.vocabulary['register.header'] }
          pageSubtitle={ props.vocabulary['register.message'] }
        />
      </div>
      <div className="grid-x grid-padding-x header">
        <div className="clever-container"><span className="register-clever">OR</span>
          <button className="btn-clever" onClick={loginWithClever} />
        </div>
      </div>
      <div className="grid-x grid-padding-x header">
        <FormContainer formTitle="" columnClass="small-12 medium-9 large-6">
          <RegisterForm vocabulary={props.vocabulary} />
        </FormContainer>
      </div>
      <div className="crop">
        <img className="bg-image" alt="" src={`${s3Url}/school.svg`} />
      </div>
    </div>
  );
}

export default Register;
