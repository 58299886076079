import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';

import { Mixpanel } from '../../utils/mixpanel';
import './Profile.scss';
import { getCurrentUserID } from '../../services/teacher';
import PageHeader from '../../components/elements/PageHeader';
import FormContainer from '../../components/elements/FormContainer';
import RegisterForm from '../../components/forms/Register';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPopup: false,
      showEmailPopup: false
    }

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    if (this.props.isComplete === false) {
      this.setState({ showPopup: true });
      this.trackProfileReroute();
    }

    if (this.props.isValidEmail === false) {
      this.setState({ showEmailPopup: true });
    }
  }

  trackProfileReroute() {
    Mixpanel.track(`Dashboard (v1.3): Profile Page Request Update`, {
      parseAccountId: getCurrentUserID()
    });
  }

  closeModal() {
    this.setState({
      showPopup: false ,
      showEmailPopup: false
    });
  }

  render() {
    const s3Url = process.env.REACT_APP_S3_IMAGES_URL;

    return (
      <div className="profile-container page">
        <Helmet>
          <title>Profile Info | codeSpark Academy for Educators</title>
        </Helmet>
        <div className="grid-x grid-padding-x header">
          <PageHeader
              pageTitle="Edit your profile information"
              pageSubtitle=""
          />
        </div>
        <div className="grid-x grid-padding-x header">
          <FormContainer formTitle="" columnClass="small-12 medium-9 large-6">
            <RegisterForm vocabulary={ this.props.vocabulary } isEdit={true} />
          </FormContainer>
        </div>
        <div className="crop">
          <img className="bg-image" alt="" src={`${s3Url}/school.svg`} />
        </div>

        <Popup
          className="update-profile-popup"
          open={this.state.showPopup}
          closeOnDocumentClick
          onclose={this.closeModal}
        >
          <div className="modal">
            <h3>Please Update Your Profile</h3>
            <p className="popup-title">Your Teacher Profile is missing required information.</p>
            <p className="popup-title">Please update your profile to access the dashboard.</p>
            <div className="button-wrapper">
              <button className="dashboard-button" onClick={this.closeModal}>OK</button>
            </div>
          </div>
        </Popup>
        <Popup
          className="update-profile-popup"
          open={this.state.showEmailPopup}
          closeOnDocumentClick
          onclose={this.closeModal}
        >
          <div className="modal">
            <p className="popup-text">It looks like you're not using a school email address. Please update to a school email address.</p>
            <p className="popup-text">If you do not have a school email address, we will be contacting you in the next few months. Until then, you'll still have access to the dashboard.</p>
            <div className="button-wrapper">
              <button className="dashboard-button" onClick={this.closeModal}>OK</button>
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isComplete: state.appState.isComplete,
  isValidEmail: state.appState.isValidEmail
});

export default connect(mapStateToProps)(Profile);
