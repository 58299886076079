import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import __find from 'lodash/find';
import __findIndex from 'lodash/findIndex';
import __camelCase from 'lodash/camelCase';
import __toLower from 'lodash/toLower';
import __isEmpty from 'lodash/isEmpty';

import './ClassroomsDetail.scss';
import {
  getClassroomDetails,
  getClassroomSummary,
  getClassroomArchiveStatus,
  setClassroomArchiveStatus,
  editClassroom,
  addTeacherToClassroom,
  getTeachersForClassroom,
  removeTeacherFromClassroom,
  getClassroomProjectCount,
} from '../../../services/classroom';
import { setClassrooms } from '../../../redux/actions';
import Button from '../../../components/elements/DashboardButton';
import DropdownSelect from '../../../components/global/DropdownSelect';
import SubNav from '../../../components/global/SubNav';
import FeaturesView from './Features';
import StudentsView from './Students'
import ManageStudentsView from './ManageStudents';
import ConnectCodeView from './ConnectCode';
import ProjectsView from './Projects';


class ClassroomsDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDescription: {
        options: false,
      },
      classroomDetails: {},
      recentClassrooms: [],
      isClassroomArchived: false,
      currentPathname: '',
      showClassModal: false,
      showStudentsModal: false,
      showConnectDevicesModal: false,
      showProjectsModal: false,
      totalProjects: null,
      editClassroom: false,
      editTeacher: false,
      teacherInfo: JSON.parse(sessionStorage.getItem('teacherData'))
    };

    this.toggleArchiveStatus = this.toggleArchiveStatus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.updateClassroom = this.updateClassroom.bind(this);
    this.addTeacher = this.addTeacher.bind(this);
    this.updateProjectCount = this.updateProjectCount.bind(this);
    this.handleStudentRefresh = this.handleStudentRefresh.bind(this);
  }

  componentDidMount(prevProps) {
    if (prevProps !== this.props) {
      this.getClassroomInfo();
    }
    if (__isEmpty(this.props.classrooms)) {
      this.props.setClassrooms(JSON.parse(sessionStorage.getItem('classroomsList')));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.getClassroomInfo();
    }
  }

  toggleArchiveStatus() {
    const archiveStatus = this.state.isClassroomArchived;

    setClassroomArchiveStatus({
      classroomId: this.props.match.params.id,
      isArchived: !archiveStatus,
    }).then( this.setState({ isClassroomArchived: !archiveStatus}) )
  }

  toggleTeacherStatus(teacher) {
    removeTeacherFromClassroom({
      teacherId: teacher.id,
      classroomId: this.state.classroomDetails.id
    }).then((result) => {
      this.getClassroomInfo();
    });
  }

  getClassroomInfo() {
    const classroomId = this.props.match.params.id;
    if (!classroomId) { return };
    const { siteContent } = this.props;
    let classroom;

    if (classroomId) {
      getClassroomProjectCount({classroomId}).then((total) => {
        this.setState({ totalProjects: total.total });
      });
    }
    getClassroomDetails({ classroomId }).then((classroomObject) => {
      // check if an error was returned so we can reroute
      if (classroomObject.code === 141) {
        this.props.router.navigate({ name: 'NotFound' });
        return;
      }
      classroom = classroomObject;
      this.setState({ passwordEnabled: classroom.get('options').StudentPasswords });
      this.getAdditionalClassroomInfo(classroomId, classroom);
    });
  }


  getAdditionalClassroomInfo(classroomId, classroom) {
    getTeachersForClassroom({ classroomId }).then((teacherDetails) => {
      // only set details if there are details returned
      if (!teacherDetails) { return; }

      let details = {
        id: classroom.id,
        name: classroom.get('name'),
        grades: classroom.get('grades'),
        teachers: teacherDetails.map(teacherDetail => ({
          id: teacherDetail.teacher.id,
          email: teacherDetail.teacher.get('user').get('email')
        })),
        students: classroom.get('students'),
        options: classroom.get('options')
      };

      // check if the teacher is associated with classroom, otherwise disable editing
      let teachers = details.teachers.map((teacher) => {
        return teacher.id;
      });

      if (!teachers.includes(this.state.teacherInfo.objectId)) {
        this.setState({ disableEditing: true });
      }

      const company = __toLower(__camelCase(this.props.siteContent.company));
      const summary = getClassroomSummary(company, classroom);
      details = Object.assign(details, summary);

      const recentClassrooms = JSON.parse(localStorage.getItem('recentClassrooms')) || [];
      const foundIndex = __findIndex(recentClassrooms, { id: details.id });

      if (foundIndex < 0) {
        recentClassrooms.unshift({
          id: details.id,
          text: details.name,
          to: `/dashboard/classroom/${details.id}`,
        });
      }

      if (foundIndex > 0) {
        const splice = recentClassrooms.splice(foundIndex, 1);
        recentClassrooms.unshift(splice[0]);
      }

      if (recentClassrooms.length > 5) {
        recentClassrooms.pop();
      }

      // fetches and filters the Classrooms array so that we can update recentClassrooms
      const fetchedClassrooms = this.props.classrooms;
      fetchedClassrooms.filter((room) => {
        return recentClassrooms.some((recent) => {
          if (room.id === recent.id) {
            recent.text = room.name;
          }
        });
      });

      getClassroomArchiveStatus({ classroomId }).then((isArchived) => {
        this.setState({ isClassroomArchived: isArchived });
        localStorage.setItem('isCurrClassroomArchived', isArchived);
      });

      this.setState({
        classroomDetails: details,
        recentClassrooms: recentClassrooms,
      });

      localStorage.setItem('currentClassroom', JSON.stringify(details));
      localStorage.setItem('recentClassrooms', JSON.stringify(recentClassrooms));
    });
  }

  handleChange(e) {
    e.persist();
    this.setState(prevState => ({
      classroomDetails: {
        ...prevState.classroomDetails,
        name: e.target.value,
      },
      oldClassroomDetails: prevState.oldClassroomDetails || prevState.classroomDetails,
    }));
  }

  cancelEdit() {
    this.setState(prevState => ({
      classroomDetails: prevState.oldClassroomDetails || prevState.classroomDetails,
      oldClassroomDetails: null,
      editClassroom: false,
    }))
  }

  updateClassroom() {
    this.setState({ isUpdating: true });

    editClassroom({
      classroomId: this.state.classroomDetails.id,
      name: this.state.classroomDetails.name.trim(),
    }).then(() => {
      const recentClassrooms = JSON.parse(localStorage.getItem('recentClassrooms'));
      const foundIndex = __findIndex(recentClassrooms, { id: this.state.classroomDetails.id });

      recentClassrooms[foundIndex].text = this.state.classroomDetails.name;

      localStorage.setItem('currentClassroom', JSON.stringify(this.state.classroomDetails));
      localStorage.setItem('recentClassrooms', JSON.stringify(recentClassrooms));

      this.setState({
          isUpdating: false,
          editClassroom: false,
          oldClassroomDetails: null,
          recentClassrooms,
      });
    });
  }

  validEmail(email) {
    const re = /.+@.+/;
    return re.test(email);
  }

  addTeacher() {
    if (!this.state.newTeacherEmail) return;
    if (!this.validEmail(this.state.newTeacherEmail)) return;

    this.setState({ isUpdating: true });

    addTeacherToClassroom({
      classroomId: this.state.classroomDetails.id,
      teacherEmail: this.state.newTeacherEmail,
    }).then(value => (
      this.setState(prevState => ({
        newTeacherEmail: null,
        editTeacher: false,
        isUpdating: false,
        classroomDetails: {
          ...prevState.classroomDetails,
          teachers: [
            ...prevState.classroomDetails.teachers,
            { email: this.state.newTeacherEmail },
          ],
        },
      }))
    ));
  }

  updateProjectCount() {
    const classroomId = this.state.classroomDetails.objectId;
    getClassroomProjectCount({classroomId}).then((total) => {
      this.setState({ totalProjects: total.total });
    });
  }

  handleStudentRefresh() {
    this.getClassroomInfo();
  }

  render() {
    const { vocabulary, classrooms, siteContent } = this.props;
    const { classroomDetails, isClassroomArchived, recentClassrooms, teacherInfo } = this.state;
    const s3Url = process.env.REACT_APP_S3_IMAGES_URL;
    return (
      <div className="classrooms-detail-container page">
        <Helmet>
          <title>Classroom Detail | codeSpark Academy Teacher Dashboard</title>
        </Helmet>
        <SubNav page="classrooms" links={recentClassrooms} vocabulary={vocabulary} siteContent={siteContent} />
        {
          !this.state.showConnectDevicesModal ? (
            !this.state.editClassroom ? (
              <div className="detail-row">
                <div className="detail-card detail-card--classroom">
                  <h2 className="title">{ classroomDetails.name }</h2>
                  {
                    teacherInfo.isOauthTeacher ? (
                      null
                    ) : (
                      <Button
                        className="button--icon"
                        onClick={() => this.setState({ editClassroom: true })}
                        eventParams={{ name: 'Edit Classroom Name' }}
                        disabled={this.state.disableEditing}
                      ><i className="fa fa-pencil-alt" /></Button>
                    )
                  }
                </div>
                {
                  (teacherInfo.isOauthTeacher || isClassroomArchived) ? (
                    null
                  ) : (
                    <Button
                      className="button--large"
                      onClick={(e) => this.setState({ showConnectDevicesModal: true })}
                      eventParams={{ name: 'Connect Devices' }}
                      disabled={this.state.disableEditing}
                    >{vocabulary['dashboard.classrooms.show.connectDevices']}</Button>
                  )
                }
              </div>

            ) : (
              <div className="detail-row">
                <div className="detail-card detail-card--classroom">
                  <input value={classroomDetails.name} onChange={this.handleChange} />
                  <div className="button-list">
                    <Button className="button--icon" onClick={this.cancelEdit} disabled={this.state.isUpdating}><i className="fa fa-times" /></Button>
                    <Button className="button--icon" onClick={this.updateClassroom} disabled={this.state.isUpdating}><i className="fa fa-check" /></Button>
                  </div>
                </div>
                <Button
                  className="button--large"
                  onClick={(e) => this.setState({ showConnectDevicesModal: true })}
                  eventParams={{ name: 'Connect Devices' }}
                >{vocabulary['dashboard.classrooms.show.connectDevices']}</Button>
              </div>
            )
          ) : (
            <div className="detail-row">
              <div className="detail-col">
                <div className="detail-card detail-card--modal">
                  <div className="close" onClick={ (e) => this.setState({ showConnectDevicesModal: false }) } />
                  <ConnectCodeView
                    classroomId={classroomDetails.id}
                    classroomName={classroomDetails.name}
                    vocabulary={vocabulary}
                  />
                </div>
              </div>
            </div>
          )
        }

        {/* main body -- lists teachers, and different options for managing classroom and students */}
        <h3 className="title title--section">{vocabulary['dashboard.classrooms.show.class']} | {this.state.classroomDetails.numStudents} {vocabulary['dashboard.classrooms.show.students']}</h3>
        {
          (!this.state.showClassModal && (!this.state.showStudentsModal || this.state.studentsModal !== 'manage')) ? (
            <div className="detail-row">
              <div className="detail-col">
                {
                  this.state.editTeacher ? (
                    <div className="detail-card detail-card--summary detail-card--edit">
                      <div className="close" onClick={() => this.setState({ editTeacher: false })}/>
                      <h2>{vocabulary['dashboard.classrooms.show.teacher']}</h2>
                      <div className="form-group">
                        <label>{vocabulary['dashboard.classrooms.show.new']}</label>
                        <input
                          className="form-input"
                          type="email"
                          placeholder="teacher@email.com"
                          onChange={(e) => this.setState({ newTeacherEmail: e.target.value })}
                        />
                        <Button
                          className="button--white button--small button--wide"
                          onClick={this.addTeacher}
                          eventParams={{ name: 'Add Teacher' }}
                          disabled={this.state.isUpdating}
                        >{vocabulary['dashboard.classrooms.show.add']}</Button>
                      </div>
                      <div>
                        {
                          classroomDetails.teachers.slice(1).map((teacher) => (
                          <div key={teacher.id}>
                          {teacher.email}
                          <button className="btn-remove" onClick={() =>
                            { if (window.confirm("Are you sure you wish to remove this teacher from the classroom?"))
                              this.toggleTeacherStatus(teacher)}
                            }>
                            Remove</button>
                          </div>
                          )
                        )}
                      </div>
                    </div>

                  ) : (
                    <div className="detail-card detail-card--summary buffer">
                      <div className="summary-teacher">
                        <div>
                          {vocabulary['dashboard.classrooms.show.teachers']}
                          { classroomDetails.teachers  &&
                            <DropdownSelect options={classroomDetails.teachers.filter(teacher => !teacher.archivedStatus).map(teacher => ({ value: teacher.id, text: teacher.email || 'no email' }))} />
                          }
                        </div>
                        <div className="teacher-edit">
                          {
                            teacherInfo.isOauthTeacher ? (
                              null
                            ) : (
                              <Button
                                className="button--icon"
                                onClick={() => this.setState({ editTeacher: true })}
                                eventParams={{ name: 'Edit Teacher' }}
                                disabled={this.state.disableEditing}
                              ><i className="fa fa-pencil-alt" /></Button>
                            )
                          }
                        </div>
                      </div>
                      <div className="summary-stats">
                        <div>
                          <h2 className="stat-title"><img src={`${s3Url}/classroom_create_text.png`} alt="Projects Published"/></h2>
                          <div className="stat-container">
                            <h3>{vocabulary['dashboard.classrooms.show.levelsPublished']}</h3>
                            <div className="stat">{this.state.totalProjects || 0}</div>
                            <Button onClick={() => this.setState({ showProjectsModal: true })}>View</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
              <div className="detail-col">
                <div className="detail-card detail-card--action detail-card--hover"
                  onMouseEnter={(e) => this.setState({ showDescription: { options: true } })}
                  onMouseLeave={(e) => this.setState({ showDescription: { options: false } })}>
                  {
                    this.state.showDescription.options ? (
                      <p>{vocabulary['dashboard.classrooms.show.appOptions.help']}</p>
                    ) : (
                      <h4>{vocabulary['dashboard.classrooms.show.appOptions']}</h4>
                    )
                  }
                  <Button
                    onClick={(e) => this.setState({ showClassModal: true })}
                    eventParams={{ name: 'Edit App Options' }}
                    disabled={this.state.disableEditing}
                  >{vocabulary['dashboard.classrooms.show.edit']}</Button>
                </div>
                {
                  teacherInfo.isOauthTeacher ? (
                    null
                  ) : (
                    <>
                      <div className="detail-card detail-card--action detail-card--hover"
                        onMouseEnter={(e) => this.setState({ showDescription: { status: true } })}
                        onMouseLeave={(e) => this.setState({ showDescription: { status: false } })}>
                        {
                          this.state.showDescription.status ? (
                            <p>{isClassroomArchived ? vocabulary['dashboard.classrooms.show.restore.help'] : vocabulary['dashboard.classrooms.show.archive.help']}</p>
                          ) : (
                            <h4>{vocabulary['dashboard.classrooms.show.classStatus']}</h4>
                          )
                        }
                        <Button
                          className={isClassroomArchived ? 'button--highlight' : ''}
                          onClick={this.toggleArchiveStatus}
                          eventParams={{ name: 'Toggle Archive Status', isArchived: isClassroomArchived }}
                          disabled={this.state.disableEditing}
                        >{isClassroomArchived ? vocabulary['dashboard.classrooms.show.restore'] : vocabulary['dashboard.classrooms.show.archive']}</Button>
                      </div>
                      <div className="detail-card detail-card--action detail-card--hover"
                        onMouseEnter={(e) => this.setState({ showDescription: { manage: true } })}
                        onMouseLeave={(e) => this.setState({ showDescription: { manage: false } })}>
                        {
                            this.state.showDescription.manage ? (
                                <p>{vocabulary['dashboard.classrooms.show.studentManagement.help']}</p>
                            ) : (
                                <h4>{vocabulary['dashboard.classrooms.show.studentManagement']}</h4>
                            )
                        }
                        <Button
                          onClick={(e) => this.setState({ showStudentsModal: true, studentsModal: 'manage' })}
                          eventParams={{ name: 'Edit Students' }}
                          disabled={this.state.disableEditing}
                        >{vocabulary['dashboard.classrooms.show.edit']}</Button>
                      </div>
                    </>
                  )
                }
              </div>
            </div>
            ) : (
              <div className="detail-row">
                <div className="detail-col">
                  <div className="detail-card detail-card--modal">
                    {
                      this.state.showClassModal &&
                      <>
                        <div className="close" onClick={ (e) => this.setState({ showClassModal: false }) } />
                        <h3 className="modal-title">{vocabulary['dashboard.classrooms.show.changeAppOptions']}</h3>
                        <div className="modal-content">
                            <FeaturesView classroomId={classroomDetails.id} vocabulary={vocabulary} />
                        </div>
                      </>
                    }
                    {
                      (this.state.showStudentsModal && this.state.studentsModal === 'manage') &&
                      <>
                        <div className="close" onClick={ (e) => this.setState({ showStudentsModal: false }) } />
                        <ManageStudentsView
                            classrooms={classrooms}
                            refreshStudents={() => this.handleStudentRefresh()}
                            classroomId={classroomDetails.id}
                            vocabulary={vocabulary}
                            siteContent={siteContent}
                            classroomDetails={classroomDetails}
                        />
                      </>
                    }
                  </div>
                </div>
              </div>
            )
        }

        {
          this.state.showProjectsModal &&
          <>
            <div className="detail-row">
              <div className="detail-col">
                <div className="detail-card detail-card--modal">
                  <div className="close" onClick={ (e) => this.setState({ showProjectsModal: false }) } />
                    <ProjectsView
                      classroomId={classroomDetails.id}
                      totalProjects={this.state.totalProjects}
                      updateProjectCount={this.updateProjectCount}
                    />
                </div>
              </div>
            </div>
          </>
        }

        <h3 className="title title--section">{vocabulary['dashboard.classrooms.show.students']} ({this.state.classroomDetails.numStudents}) </h3>
        <div className="detail-row">
          <div className="detail-col">
            <div className="detail-card detail-card--modal">
              <StudentsView
                classroomId={classroomDetails.id}
                vocabulary={vocabulary}
                siteContent={siteContent}
                classroomDetails={classroomDetails}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  classrooms: state.appState.classrooms
})

const mapDispathToProps = { setClassrooms }

export default withRouter(connect(mapStateToProps, mapDispathToProps)(ClassroomsDetail));
