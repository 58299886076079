/* eslint-disable no-control-regex */

import React from 'react';
import __isEmpty from 'lodash/isEmpty';
import __lowerCase from 'lodash/lowerCase';

import './TextboxWrapper.scss';

class Textbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value !== undefined ? this.props.value : '',
      name: this.props.name,
      error_class: '',
      error_message: ''
    };

    this.checkErrors = this.checkErrors.bind(this);
  }
  
  onChange(e) {
    e.preventDefault();

    this.setState({
      value: e.target.value
    }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.name, this.state.value);
      }
    })
  }

  onBlur(e) {
    e.preventDefault();

    this.setState({
      value: e.target.value
    }, () => {
      if (this.props.onBlur) {
        this.props.onBlur(this.state.name, this.state.value);
      }
    })
  }

  maskPromoCode(value) {
    //this is a coupon code
    if (value.toUpperCase().substr(0, 2) === 'CS') {
      value = value.split('-').join('');
      return value.match(new RegExp('.{1,4}', 'g')).join('-');
    }

    return value;
  }

  checkErrors(e) {
    e.preventDefault();

    if (this.props.noValidate) {
      return;
    }

    let errorMsg = null;

    switch(this.props.type) {
      case 'email':
        errorMsg = this.checkErrorEmail(e.target);
        break;
      case 'text':
        errorMsg = this.checkErrorText(e.target);
        if (this.props.setTextBoxErrorClass) {
          if (errorMsg === null) {
            this.props.setTextBoxErrorClass('');
          } else {
            this.props.setTextBoxErrorClass('error');
          }
        }
        break;
      default:
        break;
    }

    const targetName = e.target.name;
    const targetValue = e.target.value;

    this.setState({
      error_class: errorMsg !== null ? 'error' : '',
      error_message: errorMsg !== null ? errorMsg : ''
    }, () => {
      if (this.state.error_class === 'error') {
        // NotificationManager.error(this.state.error_message, '', 5000);
      } else if (this.props.onBlur && !__isEmpty(targetValue)) {
        this.props.onBlur(targetName, targetValue);
      }
    });
  }

  checkErrorEmail(input) {
    //make sure it's a valid email address with regex
    let emailExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    if (!__isEmpty(input.value) && !emailExp.test(input.value)) {
      return 'Please enter a valid email address';
    }

    //test for any captial letters
    let captialLetter = /^[A-Z]*$/;

    if (!__isEmpty(input.value) && input.value.match(captialLetter) !== null) {
      return 'Please do not use capital letters';
    }

    if (this.props.isRequired && __isEmpty(input.value)) {
      return 'Please fill out your ' + __lowerCase(input.placeholder);
    }

    return null;
  }

  checkErrorText(input) {
    if (this.props.isRequired && __isEmpty(input.value)) {
      return 'Please fill out your ' + __lowerCase(input.placeholder);
    }

    return null;
  }

  render() {
    return (
      <div data-type={ this.props.type } className={ `textbox-wrapper ${ this.props.extraClass } ${ this.state.error_class } ` }>
        <input
          type={ this.props.type }
          placeholder={ this.props.placeholder }
          onChange={ e => this.onChange(e) }
          onBlur={ e => this.onBlur(e) }
          value={this.props.value }
          name={ this.props.name }
        />
      </div>
    );
  }
}

export default Textbox;
