import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './DropdownNav.scss';

function DropdownNav(props) {
  const [ show, setShow ] = useState();
  const { buttonText, options } = props;

  const toggleDropdown = () => {
    setShow(!show);
  }

  return (
    <div className={`dropdown-nav-container ${show ? 'show' : ''}`}>
      <button className="dropdown-btn" onClick={toggleDropdown}>{buttonText}
        <span className="small-chevron" >
          { show ? (
            <i className="fas fa-chevron-up"></i>
          ) : (
              <i className="fas fa-chevron-down"></i>
          )}
        </span>
      </button>
      <div className="dropdown-content">
        <div className="dropdown-line"></div>
        {
          options.map(option => {
            if (option.href) {
              return <a key={option.text} href={option.href} target="_blank">{option.text}</a>
            }

            return <Link key={option.text} to={option.to} hash={option.hash}>{option.text}</Link>
          })
        }
      </div>
      <div className="dropdown-overlay" onClick={toggleDropdown} />
    </div>
  );
}

export default DropdownNav;