// TODO DELETE maybe not used
import React from 'react';
import __map from 'lodash/map';

import './DropdownWrapper.scss';

function Dropdown(props) {
  const { name, selectedValue, onChange, options, specialKeys, extraClass } = props;
  return (
    <div className={ `dropdown-wrapper ${ extraClass }` }>
      <select name={ name } value={ selectedValue } onChange={ onChange }>
      {
        options !== undefined &&
        __map(options, function(option, i) {
          if (specialKeys) {
            return <option key={ i } value={ option[specialKeys[1]] }>{ option[specialKeys[0]] }</option>
          }
          return <option key={ i } value={ option.value }>{ option.text }</option>
        })
      }
      </select>
    </div>
  );
}

export default Dropdown;
