const __isEmpty = require('lodash/isEmpty');
const Parse = require('parse');

/**
 * This is a temp feature flag to enable CS to User services integration.
 * This should be set to false by default and only enable it when the
 * backend services are ready and deployed to production. When the backend
 * services are ready, this flag should be removed - update the login function
 * and remove the flag condition. do a full test to make sure everything
 * works as expected. Do a rebuild and deploy to production.
 */
const isCSToUserServicesEnabled = false;

Parse.initialize(
  process.env.REACT_APP_PARSE_APP_ID,
  process.env.REACT_APP_PARSE_JS_KEY
);
Parse.serverURL = process.env.REACT_APP_PARSE_URL;

export const login = async (params) => {
  if (isCSToUserServicesEnabled) {
    /**
     * The UserLogin cloud function returns a session token
     * that we can use to become the user.
     */
    const sessionToken = await Parse.Cloud.run('UserLogin', {
      username: params.username.toLowerCase(),
      password: params.password,
    });

    await Parse.User.become(sessionToken);
  } else {
    await Parse.User.logIn(params.username.toLowerCase(), params.password);
  }

  const teacher = await getTeacher();

  return teacher;
};

export const register = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('RegisterTeacher', params).then((user) => {
      resolve(user);
    }, (error) => {
      reject(error);
    });
  });
};

export const logout = () => {
  return new Promise((resolve, reject) => {
    Parse.User.logOut().then(() => {
      sessionStorage.clear();
      resolve();
    }, (error) => {
      reject(error);
    });
  });
};

export const update = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('SetTeacherProperties', params).then((user) => {
      resolve(user);
    }, (error) => {
      reject(error);
    });
  });
};

export const syncClassrooms = () => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('SyncClassrooms', {}).then((user) => {
      resolve(user);
    }, (error) => {
      reject(error);
    });
  });
};

export const getTeacher = () => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetTeacher', {}).then((teacher) => {
      sessionStorage.setItem('teacherData', JSON.stringify(teacher));
      resolve(teacher);
    }, (error) => {
      reject(error);
    });
  });
};



export const oldResetPassword = (email) => {
  return new Promise((resolve, reject) => {
    Parse.User.requestPasswordReset(email).then(() => {
      resolve();
    }, (error) => {
      reject(error);
    });
  });
};

export const resetPassword = async (email) => {
  if (!isCSToUserServicesEnabled) {
    return await oldResetPassword(email);
  }

  return await Parse.Cloud.run('RequestBeginPasswordReset', { email });
};

export const verifyBeginPasswordReset = async (params) => {
  try {
    await Parse.Cloud.run('VerifyBeginPasswordReset', params);
  } catch (error) {
    throw new Error('Failed to verify password reset. Try requesting a new password reset.');
  }
}

export const isLoggedIn = () => {
  return !__isEmpty(Parse.User.current());
};

export const getCurrentUser = () => {
  // user is already saved by Parse in local storage so we will use that one
  return localStorage.getItem(
    'Parse/' + process.env.REACT_APP_PARSE_APP_ID + '/currentUser'
  );
};

export const getCurrentUserID = () => {
  const user = JSON.parse(
    localStorage.getItem(
      'Parse/' + process.env.REACT_APP_PARSE_APP_ID + '/currentUser'
    )
  );
  return user.objectId;
};