import 'whatwg-fetch';
const Parse = require('parse');

Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, process.env.REACT_APP_PARSE_JS_KEY);
Parse.serverURL = process.env.REACT_APP_PARSE_URL;

export const getLoginLink = () => {
  const redirectURL = process.env.REACT_APP_CLEVER_REDIRECT_URL;
  const clientId = process.env.REACT_APP_CLEVER_CLIENT_ID;
  let loginUrl = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${ encodeURIComponent(redirectURL) }&client_id=${ clientId }`;

  // IMPORTANT: We use this in the demo to always send the user to log in via the Clever SSO demo district. Remove for production
  if (process.env.REACT_APP_NODE_ENV !== 'production') {
    loginUrl += "&district_id=5ae74620aefbc800017b86b4";
  }
  return loginUrl;
};

// get user data. Teachers only
export const getUserData = (token) => {
  return new Promise((resolve, reject) => {
    fetch('https://api.clever.com/v2.1/me', {
      headers: {
          'Authorization': 'Bearer ' + token,
      },
      data: {}
    }).then((results) => {
      resolve(results);
    }).catch((error) => {
      reject(error);
    });
  });
};

// Login or sign up
// code is the code returned in the Clever redirect
// codeSparkId is an optional Parse User id of an
// existing user to use rather than creating a new one
export const loginOrSignUp = (code, codeSparkId = null) => {
  let results = {};

  return new Promise((resolve, reject) => {
    runOauthFlow(code).then((authData) => {
      var params = {
        username: authData.id,
        authData: authData,
      };

      if (codeSparkId !== null) {
        params['codeSparkId'] = codeSparkId;
      }

      if (authData.type !== 'teacher') {
        // throw new Error('register.oauth.studentNotAllowed');
        params.errorMessage = 'register.oauth.studentNotAllowed';
        reject(params);
    // } else if (authData.authorized_by === 'district') {
    //     // throw error because they can't use district account
    //     reject('register.oauth.districtNotAllowed');
      } else {
        return Parse.Cloud.run('SignupOrLoginTeacherWithClever', params);
      }
    }).then((signupResults) => {
      results = signupResults;
      // sessionData is for new users who don't have an older codeSpark teacher account
      const sessionData = results.sessionData;

      if (sessionData.sessionToken) {
        return Parse.User.become(sessionData.sessionToken);
      } else {
        // user is already logged in
        return Parse.Promise.as(null);
      }
    }).then(() => {
      resolve(results);
    }).catch((error) => {
      reject(error);
    });
  });
};

//================== Utilities ===================
// Clever oauth flow - once we receive the code, run this
// and authenticate the user and get back user info
const runOauthFlow = (code) => {
  const redirectURL = process.env.REACT_APP_CLEVER_REDIRECT_URL;
  return new Promise((resolve, reject) => {
    getCleverTokenAndMeData(code, redirectURL).then((response) => {
      const results = {
        access_token: response.access_token,
        id: response.id,
        type: response.type,
        authorized_by: response.authorized_by
      };

      resolve(results);
      // then pick this up in teacher.js to actually sign up with Parse
    }).catch((error) => {
      reject(error);
    });
  });
};

const getCleverTokenAndMeData = (code, redirectURL) => {
  const params = {
    code: code,
    redirectURL: redirectURL,
  };

  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetCleverTokenAndMeData', params).then(function (token) {
      resolve(token);
    }, function (error) {
      reject(error);
    });
  });
};

const getToken = (code, redirectURL) => {
  const params = {
    code: code,
    redirectURL: redirectURL,
  };

  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetCleverToken', params).then(function (token) {
      resolve(token);
    }, function (error) {
      reject(error);
    });
  });
};
