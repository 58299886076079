import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import __has from 'lodash/has';
import __isEmpty from 'lodash/isEmpty';
import 'whatwg-fetch';
import Popup from 'reactjs-popup';

import { Mixpanel } from '../../../utils/mixpanel';
import './RegisterFormWrapper.scss';
import { register, login, update, getCurrentUserID } from '../../../services/teacher';
import Textbox from '../../elements/Textbox';
import Button from '../../elements/Button';
import Dropdown from '../../elements/Dropdown';
import Checkbox from '../../elements/Checkbox';

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      submitText: props.isEdit ? 'Save Profile' : props.vocabulary['register.signup'],
      editSuccess: false,
      showPassLogoutWarning: false,

      // form objects
      firstName: {value: '', error: ''},
      lastName: {value: '', error: ''},
      email: {value: '', error: ''},
      password: {value: '', error: ''},
      confirmPassword: {value: '', error: ''},
      profession: {value: '', error: ''},
      country: {value: '', error: ''},
      usState: {value: '', error: ''},
      district: {value: '', error: ''},
      address: {value: '', error: ''},
      city: {value: '', error: ''},
      schoolType: {value: '', error: ''},
      schoolName: {value: '', error: ''},
      schoolPhone: {value: '', error: ''},
      selectedReferral: {value: '', error: ''},
      confirmTerms: {value: false, error: ''},
      confirmPromoEmails: {value: false, error: ''},

      // lists
      countriesList: [],
      usStatesList: [],
      districtsList: [],
      referralTypes: [
        { value: '', text: props.vocabulary['register.referrerType'] },
        { value: 'code.org/Hour of Code', text: props.vocabulary['select.refer.hoc'] },
        { value: 'Search', text: props.vocabulary['select.refer.search'] },
        { value: 'Apple or Google App Store', text: props.vocabulary['select.refer.appstore'] },
        { value: 'Social Media', text: props.vocabulary['select.refer.social'] },

        { value: 'Teacher Recommendation', text: props.vocabulary['select.refer.teacher'] },
        { value: 'Parent Request', text: props.vocabulary['select.refer.parent'] },
        { value: 'Professional Development Course', text: props.vocabulary['select.refer.pd'] },
        { value: 'Conference', text: props.vocabulary['select.refer.conference'] },

        { value: 'Student Request', text: props.vocabulary['select.refer.student'] },
        { value: 'Other', text: props.vocabulary['select.refer.other'] },
      ],
      schoolTypes: [
        { value: '', text: props.vocabulary['register.schoolType'] },
        { value: 'Public School', text: props.vocabulary['select.school.public'] },
        { value: 'Private School', text: props.vocabulary['select.school.private'] },
        { value: 'Charter School', text: props.vocabulary['select.school.charter'] },
        { value: 'Public Library', text: props.vocabulary['select.school.library'] },
        { value: 'Coding Club', text: props.vocabulary['select.school.club'] },
        { value: 'Home School', text: props.vocabulary['select.school.home'] }
      ],
      professionTypes: [
        { value: '', text: props.vocabulary['register.professionType'] },
        { value: 'Technology Teacher', text: props.vocabulary['select.profession.technologyTeacher'] },
        { value: 'General Education Teacher', text: props.vocabulary['select.profession.generalTeacher'] },
        { value: 'Instructional Tech Coach', text: props.vocabulary['select.profession.it'] },
        { value: 'Librarian/Media Specialist', text: props.vocabulary['select.profession.librarian'] },
        { value: 'School Administrator', text: props.vocabulary['select.profession.schooladmin'] },
        { value: 'Coding Club Coach', text: props.vocabulary['select.profession.codingclub'] },
        { value: 'Homeschooler', text: props.vocabulary['select.profession.homeschool'] },
        { value: 'Non-profit Organizer', text: props.vocabulary['select.profession.nonprofit'] },
        { value: 'Professional Development Instructor', text: props.vocabulary['select.profession.profdevelopment'] },
      ],
      error: "",
    };

    // handlers
    this.closeModal = this.closeModal.bind(this);
  }

  async componentDidMount() {
    //get the lists for countries and states
    fetch('https://thefoos-com2.s3.amazonaws.com/dashboard/locale/countries.json')
    .then(response => response.json())
    .then(countries => {
      countries.unshift({ name: this.props.vocabulary['register.country'], code: '' });
      this.setState({ countriesList: countries });
    });

    fetch('https://thefoos-com2.s3.amazonaws.com/dashboard/locale/states.json')
    .then(response => response.json())
    .then(states => {
      states.unshift({ name: this.props.vocabulary['register.state'], code: '' });
      this.setState({ usStatesList: states });
    });

    if (this.props.isEdit) {
      // grab teacher profile from sessionStorage
      const teacher = JSON.parse(sessionStorage.teacherData);
      this.setState({
        firstName: { value: teacher.firstName, error: ''},
        lastName: { value: teacher.lastName, error: ''},
        email: { value: teacher.user.email, error: ''},
        profession: { value: teacher.professionType, error: ''},
        country: { value: teacher.user.countryCode, error: ''},
        usState: { value: teacher.schoolState, error: ''},
        district: { value: teacher.schoolDistrict, error: ''},
        address: { value: teacher.schoolAddress, error: ''},
        city: { value: teacher.schoolCity, error: ''},
        schoolType: { value: teacher.schoolType, error: ''},
        schoolName: { value: teacher.school, error: ''},
        schoolPhone: { value: teacher.schoolPhoneNum, error: ''},
        selectedReferral: { value: teacher.referrerType, error: ''},
        confirmPromoEmails: { value: teacher.confirmPromoEmails, error: ''}
      });
    }
  }

  componentDidUpdate() {
    const { isEdit } = this.props;
    const { districtsList, usState, editSuccess } = this.state;

    if (isEdit && editSuccess) {
      window.scrollTo(0, 0);
    }

    if (isEdit && sessionStorage.getItem('teacherData') !== null) {
      if (districtsList.length === 0 && usState.value !== '') {
        fetch(`https://thefoos-com2.s3.amazonaws.com/dashboard/locale/schoolDistricts/${ usState.value }.json`)
        .then(response => response.json())
        .then(districts => {
          districts.unshift({ name: 'Not seeing your district?', code: '' });
          districts.unshift({ name: this.props.vocabulary['register.schoolDistrict'], code: '' });
          this.setState({ districtsList: districts });
        });
      }
    }
  }

  handleInputChange = (name, value) => {
    const obj = this.state[name];
    obj.value = value;
    this.setState({ [name]: obj });

    this.validateForm(name);
  }

  setPasswordInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const obj = this.state[name];
    obj.value = value;
    this.setState({ [name]: obj });
  }

  handlePasswordChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (__isEmpty(value)) {
      this.setState({ showPassLogoutWarning: false });
    } else {
      this.setState({ showPassLogoutWarning: true });
    }
    //check length
    let min = 6;
    let max = 32;
    
    if (!__isEmpty(value) && (value.length < min || value.length > max)) {
      return this.setError('password', this.props.vocabulary['error.TD_InvalidPassword']);
    } 

    this.validateForm(name);
  }

  handleDropdownChange =(e) => {
    const name = e.target.name;
    const value = e.target.value;
    const obj = this.state[name];
    obj.value = value;
    this.setState({ [name]: obj }, () => {
      if (name === 'usState') {
        if (value === '') {
          this.setState({ districtsList: [] });
        } else {
          fetch(`https://thefoos-com2.s3.amazonaws.com/dashboard/locale/schoolDistricts/${ value }.json`)
          .then(response => response.json())
          .then(districts => {
            districts.unshift({ name: 'Not seeing your district?', code: '' });
            districts.unshift({ name: this.props.vocabulary['register.schoolDistrict'], code: '' });
            this.setState({ districtsList: districts });
          });
        }
      }
      if (name === 'district' && value === 'Not seeing your district?') {
        this.setState({ isOpen: true });
      }
    });
    this.validateForm(name);
  }

  closeModal() {
    this.setState({ isOpen: false });
  }

  handleCheckboxChange = (e) => {
    const obj = this.state[e.target.name];
    obj.value = e.target.checked;
    this.setState({ [e.target.name]: obj });
    this.validateForm(e.target.name);
  }

  submitForm(e) {
    e.preventDefault();
    this.registerUser();
  }

  identifyUser(user) {
    Mixpanel.alias(user.id);
    Mixpanel.people.set({
      'Account Status': 'Teacher',
      'subscriber': false,
      'parseAccountId': user.id
    });
  }

  trackRegisterEvent(params) {
    const { schoolDistrict, schoolAddress, schoolCity, state: schoolState } = params;
    const opts = {
      parseAccountId: getCurrentUserID(),
      schoolType: params.schoolType,
      referrer: params.referrerType,
      profession: params.professionType,
      schoolDistrict,
      schoolAddress,
      schoolCity,
      schoolState,
    };

    window.dataLayer.push({
      'event': 'teacherRegistration',
      'emailAddress': params.email,
      'userType': 'teacher',
      'teacherProfession': params.professionType,
      'teacherCountry': params.country,
      'teacherState': schoolState,
      'teacherSchoolType': params.schoolType,
      'userDefinedSource': params.referrerType,
    });
    Mixpanel.track('Dashboard (v1.3): Teacher Register', opts);
  }

  setError(field, error) {
    const obj = this.state[field];
    obj.error = error;
    this.setState({[field]: obj});
  }

  doNotRenderDistrict() {
    let role = this.state.profession.value;
    if (role !== 'Coding Club Coach' && role !== 'Homeschooler' && role !== 'Non-profit Organizer' && role !== 'Professional Development Instructor') {
      return true;
    }
  }

  /**
   * validateForm - Validates the form for the given value or all fields if null
   * @param {*} fieldName - the field to validate - null for all
   */
  validateForm(fieldName) {
    var error = false;
    const hasDistrict = this.doNotRenderDistrict();

    if ((!fieldName || fieldName === "firstName") && __isEmpty(this.state.firstName.value)) {
      this.setError('firstName', this.props.vocabulary['error.TD_FirstNameEmpty']);
      error = true;
    } else if (fieldName === "firstName") {
      this.setError('firstName', '');
    }

    if ((!fieldName || fieldName === "lastName") && __isEmpty(this.state.lastName.value)) {
      this.setError('lastName', this.props.vocabulary['error.TD_LastNameEmpty']);
      error = true;
    } else if (fieldName === "lastName") {
      this.setError('lastName', '');
    }

    if ((!fieldName || fieldName === "email") && __isEmpty(this.state.email.value)) {
      this.setError('email', this.props.vocabulary['error.TD_EmailEmpty']);
      error = true;
    } else if (fieldName === "email") {
      this.setError('email', '');
    }

    // - password
    if (!this.props.isEdit || (this.props.isEdit && !__isEmpty(this.state.password.value))) {
      if ((!fieldName || fieldName === "password") && __isEmpty(this.state.password.value)) {
        this.setError('password', this.props.vocabulary['error.TD_PasswordEmpty']);
        error = true;
      }

      if (this.state.confirmPassword.value !== this.state.password.value) {
        this.setError('password', this.props.vocabulary['error.PasswordNotMatching']);
        error = true;
      } else {
        this.setError('password', '');
        error = false;
      }
    }

    if (__isEmpty(this.state.password.value)) {
      this.setError('password', '');
    }

    if ((!fieldName || fieldName === "profession") && __isEmpty(this.state.profession.value)) {
      this.setError('profession', this.props.vocabulary['error.TD_ProfessionEmpty']);
      error = true;
    } else if (fieldName === "profession") {
      this.setError('profession', '');
    }

    if ((!fieldName || fieldName === "country") && __isEmpty(this.state.country.value)) {
      this.setError('country', this.props.vocabulary['error.TD_CountryEmpty']);
      error = true;
    } else if (fieldName === "country") {
      this.setError('country', '');
    }

    // IF REGISTERING IN THE US
    if (this.state.country.value === 'US') {
      if ((!fieldName || fieldName === "usState") && __isEmpty(this.state.usState.value)) {
        this.setError('usState', this.props.vocabulary['error.TD_StateEmpty']);
        error = true;
      } else if (fieldName === "usState") {
        this.setError('usState', '');
      }

      if ((!fieldName || fieldName === "district") && hasDistrict && (__isEmpty(this.state.district.value) || this.state.district.value === this.props.vocabulary['register.schoolDistrict'])) {
        this.setError('district', this.props.vocabulary['error.TD_SchoolDistrictEmpty']);
        error = true;
      } else if (fieldName === "district") {
        this.setError('district', '');
      }

      if ((!fieldName || fieldName === "address") && __isEmpty(this.state.address.value)) {
        this.setError('address', this.props.vocabulary['error.TD_AddressEmpty']);
        error = true;
      } else if (fieldName === "address") {
        this.setError('address', '');
      }

      if ((!fieldName || fieldName === "city") && __isEmpty(this.state.city.value)) {
        this.setError('city', this.props.vocabulary['error.TD_CityEmpty']);
        error = true;
      } else if (fieldName === "city") {
        this.setError('city', '');
      }
    } else {
      //reset school info
      this.setState({
        usState: {value: '', error: ''},
        district: {value: '', error: ''},
        address: {value: '', error: ''},
        city: {value: '', error: ''},
      });
    }

    if ((!fieldName || fieldName === "schoolType") && __isEmpty(this.state.schoolType.value)) {
      this.setError('schoolType', this.props.vocabulary['error.TD_SchoolTypeEmpty']);
      error = true;
    } else if (fieldName === "schoolType") {
      this.setError('schoolType', '');
    }

    if ((!fieldName || fieldName === "schoolName") && __isEmpty(this.state.schoolName.value)) {
      this.setError('schoolName', this.props.vocabulary['error.TD_SchoolNameEmpty']);
      error = true;
    } else if (fieldName === "schoolName") {
      this.setError('schoolName', '');
    }

    if ((!fieldName || fieldName === "schoolPhone") && __isEmpty(this.state.schoolPhone.value)) {
      this.setError('schoolPhone', this.props.vocabulary['error.TD_PhoneEmpty']);
      error = true;
    } else if (fieldName === "schoolPhone") {
      this.setError('schoolPhone', '');
    }

    if (!this.props.isEdit) {
      if ((!fieldName || fieldName === "selectedReferral") && __isEmpty(this.state.selectedReferral.value)) {
        this.setError('selectedReferral', this.props.vocabulary['error.TD_HowHearEmpty']);
        error = true;
      } else if (fieldName === "selectedReferral") {
        this.setError('selectedReferral', '');
      }
    }

    if (!this.props.isEdit) {
      if ((!fieldName || fieldName === "confirmTerms") && !this.state.confirmTerms.value) {
        this.setError('confirmTerms', this.props.vocabulary['error.TD_MustAgreeToTerms']);
        error = true;
      } else if (fieldName === "confirmTerms") {
        this.setError('confirmTerms', '');
      }
    }

    if (error) {
      this.setState({error: this.props.vocabulary['error.TD_ErrorsInForm']});
    } else {
      this.setState({ error: ''})
    }

    return error;
  }

  registerUser() {
    this.setState({error: ""});

    if (this.validateForm()) {
      this.setState({error: this.props.vocabulary['error.TD_ErrorsInForm']});
      window.scrollTo(0, 0);
      return false;
    }

    // processing
    this.showFormProcessing();

    const params = {
      firstName: this.state.firstName.value,
      lastName: this.state.lastName.value,
      username: this.state.email.value.toLowerCase(), // send only lowercase values
      email: this.state.email.value.toLowerCase(),
      password: this.state.password.value,
      school: this.state.schoolName.value,
      schoolType: this.state.schoolType.value,
      referrerType: this.state.selectedReferral.value,
      professionType: this.state.profession.value,
      schoolPhoneNum: this.state.schoolPhone.value,
      country: this.state.country.value,
      confirmPromoEmails: this.state.confirmPromoEmails.value,
    };

    if (this.state.usState.value !== '') {
      params.state = this.state.usState.value;
    }

    if (this.state.district.value !== '') {
      params.schoolDistrict = this.state.district.value;
    }

    if (this.state.address.value !== '') {
      params.schoolAddress = this.state.address.value;
    }

    if (this.state.city.value !== '') {
      params.schoolCity = this.state.city.value;
    }

    if (this.props.isEdit) {
      update(params).then((user) => {
        this.removeFormProcessing();
        this.setState({ editSuccess: true }, () => {
          if (!__isEmpty(params.password)) {
            localStorage.clear();
            window.location.reload();
          } else {
            setTimeout(() => {
              this.setState({ editSuccess: false });
            }, 5000);
          }
        });
      }).catch((error) => {
        console.log("Error updating teacher", error);
        this.showServerError(error);
      });;
    } else {
      register(params).then((user) => {
        this.removeFormProcessing();
        this.identifyUser(user);
        login({
          username: this.state.email.value,
          password: this.state.password.value
        }).then((user) => {
          this.trackRegisterEvent(params);
          this.props.history.push('/dashboard');
        }).catch((error) => {
          console.log("error logging in", error);
        });
      }).catch((error) => {
        console.log("Error signing in", error);
        this.showServerError(error);
      });
    }
  }

  showFormProcessing() {
    //change the button style - this is an isolated event
    document.getElementById('register-submit').classList.remove('alt-orange');
    document.getElementById('register-submit').classList.add('processing', 'alt-gray');
    this.setState({ submitText: 'PROCESSING...' })
  }

  removeFormProcessing() {
    //return submit button back to normal state
    document.getElementById('register-submit').classList.remove('processing', 'alt-gray');
    document.getElementById('register-submit').classList.add('alt-orange');

    if (this.props.isEdit) {
      this.setState({ submitText: 'Save Profile' })
    } else {
      this.setState({ submitText: this.props.vocabulary['nav.login'] })
    }
  }

  showServerError(error) {
    let errorMessage;

    if (error.message) {
      errorMessage = error.message;
    } else if (error.message.errors && error.message.errors[0].messageKey) {
      errorMessage = this.props.vocabulary['error.' + error.message.errors[0].messageKey];
    } else {
      errorMessage = '';
    }

    this.setState({ error: errorMessage });
    this.removeFormProcessing();
    window.scrollTo(0, 0);
  }

  render() {
    let usStateElement, usStateError;
    let schoolAddressElement, schoolAddressError;
    let schoolCityElement, schoolCityError;
    let districtElement, districtError;
    let hasDistrict = this.doNotRenderDistrict();

    if (this.state.country.value === 'US') {
      usStateError = this.state.usState.error ? <div className="line-error">{this.state.usState.error}</div> : "";
      usStateElement =
        <Dropdown name="usState"
          options={ this.state.usStatesList }
          onChange={ this.handleDropdownChange }
          selectedValue={ this.state.usState.value }
          specialKeys={ ['name', 'code'] } />

      schoolAddressError = this.state.address.error ? <div className="line-error">{this.state.address.error}</div> : "";
      schoolAddressElement =
        <Textbox type="text"
          placeholder={ this.props.vocabulary['register.schoolAddress'] }
          name="address"
          onChange={ this.handleInputChange }
          value={ this.state.address.value }
          extraClass="school" />

      schoolCityError = this.state.city.error ? <div className="line-error">{this.state.city.error}</div> : "";
      schoolCityElement =
        <Textbox type="text"
          placeholder={ this.props.vocabulary['register.schoolCity'] }
          name="city"
          onChange={ this.handleInputChange }
          value={ this.state.city.value }
          extraClass="school" />

      if (this.state.usState.value && hasDistrict) {
        districtError = this.state.district.error ? <div className="line-error">{this.state.district.error}</div> : "";
        districtElement =
          <Dropdown name="district"
            options={ this.state.districtsList }
            onChange={ this.handleDropdownChange }
            selectedValue={ this.state.district.value }
            specialKeys={ ['name', 'name'] } />
      }
    }

    return (
      <div className="register-form-wrapper">
        { this.state.error ? <div id="general-error">{this.state.error}</div> : ""}
        {
          (this.props.isEdit && this.state.editSuccess) &&
          <div className="edit-success">
            Your information has been saved successfully.
          </div>
        }

        <form onSubmit={ e => this.submitForm(e) }>
          { this.state.firstName.error ? <div className="line-error">{this.state.firstName.error}</div> : "" }
          <Textbox type="text"
            placeholder={ this.props.vocabulary['register.firstName'] }
            name="firstName"
            onChange={ this.handleInputChange }
            value={ this.state.firstName.value } />

          { this.state.lastName.error ? <div className="line-error">{this.state.lastName.error}</div> : "" }
          <Textbox type="text"
            placeholder={ this.props.vocabulary['register.lastName'] }
            name="lastName"
            onChange={ this.handleInputChange }
            value={ this.state.lastName.value } />

          { this.state.email.error ? <div className="line-error">{this.state.email.error}</div> : "" }
          <Textbox type="email"
            placeholder={ this.props.vocabulary['register.workEmail'] }
            name="email"
            onChange={ this.handleInputChange }
            value={ this.state.email.value } />

          { this.state.password.error ? <div className="line-error">{this.state.password.error}</div> : "" }
          { (this.props.isEdit && this.state.showPassLogoutWarning) && <div className="line-error warning">Updating your password will automatically log you out</div> }
          <div className={`textbox-wrapper ${this.state.password.error ? 'error' : ''}`}>
            <input type="password"
              placeholder={ this.props.vocabulary['register.password'] }
              name="password"
              onChange={ this.setPasswordInput }
              onBlur={ this.handlePasswordChange }
              value={ this.state.password.value } />
          </div>
          
          { this.state.confirmPassword.error ? <div className="line-error">{this.state.confirmPassword.error}</div> : "" }
          <div className={`textbox-wrapper ${this.state.password.error ? 'error' : ''}`}>
            <input type="password"
              placeholder={ this.props.vocabulary['register.confirmPassword'] }
              name="confirmPassword"
              onChange={this.setPasswordInput}
              onBlur={ this.handlePasswordChange }
              value={ this.state.confirmPassword.value } />
          </div>
          
          { this.state.profession.error ? <div className="line-error">{this.state.profession.error}</div> : "" }
          <Dropdown name="profession"
            options={ this.state.professionTypes }
            onChange={ this.handleDropdownChange }
            selectedValue={ this.state.profession.value } />

          { this.state.country.error ? <div className="line-error">{this.state.country.error}</div> : "" }
          <Dropdown name="country"
            options={ this.state.countriesList }
            onChange={ this.handleDropdownChange }
            selectedValue={ this.state.country.value }
            specialKeys={ ['name', 'code'] } />

          {usStateError}
          {usStateElement}
          {districtError}
          {districtElement}
          {schoolAddressError}
          {schoolAddressElement}
          {schoolCityError}
          {schoolCityElement}

          { this.state.schoolType.error ? <div className="line-error">{this.state.schoolType.error}</div> : "" }
          <Dropdown name="schoolType"
            options={ this.state.schoolTypes }
            onChange={ this.handleDropdownChange }
            selectedValue={ this.state.schoolType.value } />

          { this.state.schoolName.error ? <div className="line-error">{this.state.schoolName.error}</div> : "" }
          <Textbox type="text" placeholder={ this.props.vocabulary['register.schoolName'] } name="schoolName" onChange={ this.handleInputChange } value={ this.state.schoolName.value } extraClass="school" />

          { this.state.schoolPhone.error ? <div className="line-error">{this.state.schoolPhone.error}</div> : "" }
          <Textbox type="tel" placeholder={ this.props.vocabulary['register.schoolPhoneNumber'] } name="schoolPhone" onChange={ this.handleInputChange } value={ this.state.schoolPhone.value } extraClass="school" />

          { this.state.selectedReferral.error ? <div className="line-error">{this.state.selectedReferral.error}</div> : "" }
          {
            !this.props.isEdit &&
            <Dropdown name="selectedReferral"
              options={ this.state.referralTypes }
              onChange={ this.handleDropdownChange }
              selectedValue={ this.state.selectedReferral.value } />
          }

          { this.state.confirmTerms.error ? <div className="line-error">{this.state.confirmTerms.error}</div> : "" }
          {
            !this.props.isEdit &&
            <Checkbox name="confirmTerms"
              type="checkbox"
              checked={ this.state.confirmTerms.value }
              onChange={ this.handleCheckboxChange }>
              I have read and agree to the <a target="_blank" href={ `${ process.env.REACT_APP_CODESPARK_BASE_URL }/terms` }>Terms of Service</a> and <a target="_blank" href={ `${ process.env.REACT_APP_CODESPARK_BASE_URL }/privacy` }>Privacy Policy</a>.
            </Checkbox>
          }

          <Checkbox
            name="confirmPromoEmails"
            type="checkbox"
            checked={ this.state.confirmPromoEmails.value }
            onChange={ this.handleCheckboxChange }>
            I would like occasional news and promotional emails.
          </Checkbox>
          <br />
          <Button text={ this.state.submitText } type="form" id="register-submit" extraClass="alt-orange" />
        </form>
        <Popup
          className="contact-popup"
          open={this.state.isOpen}
          closeOnDocumentClick
          onclose={this.closeModal}
        >
          <div className="modal signup-popup-form">
            <div className="close" onClick={this.closeModal}>X</div>
            <p className="popup-title">
              Don’t see your school district? Please email <a href="mailto:support@codespark.com?subject=District not listed">support@codespark.com</a> to add your district to this list.
            </p>
          </div>
        </Popup>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  teacherProfile: state.appState.teacherProfile
});

export default withRouter(connect(mapStateToProps)(RegisterForm));
