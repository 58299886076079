import React from 'react';
import { Helmet } from 'react-helmet';

import './Resources.scss';
import Card from '../../../components/global/Card';

export default function Resources(props) {
  const { vocabulary, siteContent } = props;
  const s3Url = process.env.REACT_APP_S3_IMAGES_URL;

  let list = [
    {
      to: 'resources/getting-started-guide',
      text: 'dashboard.resources.gettingStartedGuide',
      src: 'resources_icon_getting_started.png',
    },
    {
      to: 'resources/solution-guides',
      text: 'dashboard.resources.solutionGuides',
      src: 'resources_icon_solution_v2.png'
    },
    {
      to: 'resources/downloadable-images',
      text: 'dashboard.resources.downloadableImages',
      src: 'DownloadableImages.png',
    },
    {
      to: 'resources/faq',
      text: 'dashboard.resources.faq',
      src: 'resources_icon_faq.png',
    },
    {
      to: 'resources/contact-us',
      name: 'ResourcesContactUs',
      text: 'dashboard.resources.contactUs',
      src: 'resources_icon_contact_us.png'
    },
  ];

  if (siteContent && !siteContent.isPartner) {
    list.splice(2, 0, {
      to: 'resources/videos',
      name: 'ResourcesWebinars',
      text: 'dashboard.resources.webinars',
      src: 'foo_dog_video.png',
      filename: 'resources.videos',
      page: 'resources'
    });
    list.splice(3, 0, { to: 'resources/standards', text: 'dashboard.resources.standards', src: 'resources_icon_standards_v2.png' });
    list.splice(4, 0, { to: 'resources/for-parents', text: 'dashboard.resources.forParents', src: 'resources_icon_parents.png' });
  }

  return (
    <div className="resources-container page page-gray">
      <Helmet>
        <title>Resources | codeSpark Academy Teacher Dashboard</title>
      </Helmet>
      <ul>
        {
          list.map(item => (
            <li key={item.to}>
              <Card
                text={item.text}
                to={item.to}
                src={`${s3Url}/${item.src}`}
                name={item.name}
                filename={item.filename}
                page={item.page}
                vocabulary={vocabulary}
              />
            </li>
          ))
        }
      </ul>
    </div>
  );
}
