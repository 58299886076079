const Missions = {
    'Tutorial_1_1': { type: 'main', topic:'Sequencing', icon: 'Police', name: 'Level 1-1' },
    'Tutorial_1_2': { type: 'main', topic:'Sequencing', icon: 'Police', name: 'Level 1-2' },
    'Tutorial_1_3': { type: 'main', topic:'Sequencing', icon: 'Police', name: 'Level 1-3' },
    'Tutorial_1_4': { type: 'main', topic:'Sequencing', icon: 'Police', name: 'Level 1-4' },
    'Tutorial_1_5': { type: 'main', topic:'Sequencing', icon: 'Police', name: 'Level 1-5' },
    'Tutorial_1_6': { type: 'main', topic:'Sequencing', icon: 'Police', name: 'Level 1-6' },
    'Tutorial_1_7': { type: 'main', topic:'Sequencing', icon: 'Police', name: 'Level 1-7' },
    'Tutorial_1_8': { type: 'main', topic:'Sequencing', icon: 'Police', name: 'Level 1-8' },

    'Chapter_1_9': { type: 'main', topic:'Sequencing', icon: 'Police', name: 'Level 1-9' },
    'Chapter_1_10': { type: 'main', topic:'Sequencing', icon: 'Police', name: 'Level 1-10' },
    'Chapter_1_11': { type: 'main', topic:'Sequencing', icon: 'Police', name: 'Level 1-11' },
    'Chapter_1_12': { type: 'main', topic:'Sequencing', icon: 'Police', name: 'Level 1-12' },
    'Chapter_1_13': { type: 'main', topic:'Sequencing', icon: 'Police', name: 'Level 1-13' },
    'Chapter_1_14': { type: 'main', topic:'Sequencing', icon: 'Police', name: 'Level 1-14' },
    'Chapter_1_15': { type: 'main', topic:'Sequencing', icon: 'Police', name: 'Level 1-15' },
    'Chapter_1_16': { type: 'main', topic:'Sequencing', icon: 'Police', name: 'Level 1-16' },
    'Chapter_1_17': { type: 'main', topic:'Sequencing', icon: 'Police', name: 'Level 1-17' },

    'Chapter_2_1': { type: 'main', topic:'Loops', icon: 'Construction', name: 'Level 2-1' },
    'Chapter_2_2': { type: 'main', topic:'Loops', icon: 'Construction', name: 'Level 2-2' },
    'Chapter_2_3': { type: 'main', topic:'Loops', icon: 'Construction', name: 'Level 2-3' },
    'Chapter_2_4': { type: 'main', topic:'Loops', icon: 'Construction', name: 'Level 2-4' },
    'Chapter_2_5': { type: 'main', topic:'Loops', icon: 'Construction', name: 'Level 2-5' },
    'Chapter_2_6': { type: 'main', topic:'Loops', icon: 'Construction', name: 'Level 2-6' },
    'Chapter_2_7': { type: 'main', topic:'Loops', icon: 'Construction', name: 'Level 2-7' },
    'Chapter_2_8': { type: 'main', topic:'Loops', icon: 'Construction', name: 'Level 2-8' },
    'Chapter_2_9': { type: 'main', topic:'Loops', icon: 'Construction', name: 'Level 2-9' },
    'Chapter_2_10': { type: 'main', topic:'Loops', icon: 'Construction', name: 'Level 2-10' },
    'Chapter_2_11': { type: 'main', topic:'Loops', icon: 'Construction', name: 'Level 2-11' },
    'Chapter_2_12': { type: 'main', topic:'Loops', icon: 'Construction', name: 'Level 2-12' },
    'Chapter_2_13': { type: 'main', topic:'Loops', icon: 'Construction', name: 'Level 2-13' },
    'Chapter_2_14': { type: 'main', topic:'Loops', icon: 'Construction', name: 'Level 2-14' },
    'Chapter_2_15': { type: 'main', topic:'Loops', icon: 'Construction', name: 'Level 2-15' },
    'Chapter_2_16': { type: 'main', topic:'Loops', icon: 'Construction', name: 'Level 2-16' },

    'Chapter_3_1': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-1' },
    'Chapter_3_2': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-2' },
    'Chapter_3_3': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-3' },
    'Chapter_3_4': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-4' },
    'Chapter_3_5': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-5' },
    'Chapter_3_6': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-6' },
    'Chapter_3_7': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-7' },
    'Chapter_3_8': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-8' },
    // pg 2
    'Chapter_3_9': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-9' },
    'Chapter_3_10': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-10' },
    'Chapter_3_11': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-11' },
    'Chapter_3_12': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-12' },
    'Chapter_3_13': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-13' },
    'Chapter_3_14': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-14' },
    'Chapter_3_15': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-15' },
    'Chapter_3_16': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-16' },
    'Chapter_3_17': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-17' },

     // pg 3
    /*
    'Chapter_3_18': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-18' },
    'Chapter_3_19': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-19' },
    'Chapter_3_20': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-20' },
    'Chapter_3_21': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-21' },
    'Chapter_3_22': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-22' },
    'Chapter_3_23': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-23' },
    'Chapter_3_24': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-24' },
     // pg 3

    'Chapter_3_25': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-25' },
    'Chapter_3_26': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-26' },
    'Chapter_3_27': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-27' },
    'Chapter_3_28': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-28' },
    'Chapter_3_29': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-29' },
    'Chapter_3_30': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-30' },
    'Chapter_3_31': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-31' },
    'Chapter_3_32': { type: 'main', topic:'Advanced Sequencing', icon: 'Ninja', name: 'Level 3-32' },
    */
    'Chapter_4_0': { type: 'main', topic:'Events', icon: 'Astronaut', name: 'Level 4-1' },
    'Chapter_4_01': { type: 'main', topic:'Events', icon: 'Astronaut', name: 'Level 4-2' },
    'Chapter_4_1': { type: 'main', topic:'Events', icon: 'Astronaut', name: 'Level 4-3' },
    'Chapter_4_2': { type: 'main', topic:'Events', icon: 'Astronaut', name: 'Level 4-4' },
    'Chapter_4_2_1': { type: 'main', topic:'Events', icon: 'Astronaut', name: 'Level 4-5' },
    'Chapter_4_3': { type: 'main', topic:'Events', icon: 'Astronaut', name: 'Level 4-6' },
    'Chapter_4_4': { type: 'main', topic:'Events', icon: 'Astronaut', name: 'Level 4-7' },
    'Chapter_4_5': { type: 'main', topic:'Events', icon: 'Astronaut', name: 'Level 4-8' },
    'Chapter_4_6': { type: 'main', topic:'Events', icon: 'Astronaut', name: 'Level 4-9' },
    'Chapter_4_8': { type: 'main', topic:'Events', icon: 'Astronaut', name: 'Level 4-10' },

    'Chapter_5_1': { type: 'main', topic:'Conditionals', icon: 'Chef', name: 'Level 5-1' },
    'Chapter_5_2': { type: 'main', topic:'Conditionals', icon: 'Chef', name: 'Level 5-2' },
    'Chapter_5_3': { type: 'main', topic:'Conditionals', icon: 'Chef', name: 'Level 5-3' },
    'Chapter_5_4': { type: 'main', topic:'Conditionals', icon: 'Chef', name: 'Level 5-4' },
    'Chapter_5_5': { type: 'main', topic:'Conditionals', icon: 'Chef', name: 'Level 5-5' },
    'Chapter_5_6': { type: 'main', topic:'Conditionals', icon: 'Chef', name: 'Level 5-6' },
    'Chapter_5_7': { type: 'main', topic:'Conditionals', icon: 'Chef', name: 'Level 5-7' },
    'Chapter_5_8': { type: 'main', topic:'Conditionals', icon: 'Chef', name: 'Level 5-8' },
    'Chapter_5_9': { type: 'main', topic:'Conditionals', icon: 'Chef', name: 'Level 5-9' },
    'Chapter_5_10': { type: 'main', topic:'Conditionals', icon: 'Chef', name: 'Level 5-10' },
    // Sweet Sorter
    'SweetSorter1': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 1' },
    'SweetSorter2': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 2' },
    'SweetSorter3': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 3' },
    'SweetSorter4': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 4' },
    'SweetSorter5': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 5' },

    'SweetSorter6': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 6' },
    'SweetSorter7': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 7' },
    'SweetSorter8': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 8' },
    'SweetSorter9': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 9' },
    'SweetSorter10': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 10' },
    'SweetSorter11': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 11' },
    'SweetSorter12': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 12' },
    'SweetSorter13': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 13' },
    'SweetSorter14': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 14' },
    'SweetSorter15': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 15' },
    'SweetSorter16': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 16' },
    'SweetSorter17': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 17' },
    'SweetSorter18': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 18' },
    'SweetSorter19': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 19' },
    'SweetSorter20': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 20' },
    'SweetSorter21': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 21' },
    'SweetSorter22': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 22' },
    'SweetSorter23': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 23' },
    'SweetSorter24': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 24' },
    'SweetSorter25': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 25' },
    'SweetSorter26': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 26' },
    'SweetSorter27': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 27' },
    'SweetSorter28': { type: 'main', topic:'Booleans', icon: 'Queen', name: 'Sweet Sorter 28' },
    // Pet Stacker
    'PetStacker1': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 1' },
    'PetStacker2': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 2' },
    'PetStacker3': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 3' },
    'PetStacker4': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 4' },
    'PetStacker5': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 5' },
    'PetStacker6': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 6' },
    'PetStacker7': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 7' },
    'PetStacker8': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 8' },
    'PetStacker9': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 9' },
    'PetStacker10': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 10' },
    'PetStacker11': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 11' },
    'PetStacker12': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 12' },
    'PetStacker13': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 13' },
    'PetStacker14': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 14' },
    'PetStacker15': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 15' },
    'PetStacker16': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 16' },
    'PetStacker17': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 17' },
    'PetStacker18': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 18' },
    'PetStacker19': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 19' },
    'PetStacker20': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 20' },
    'PetStacker21': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 21' },
    'PetStacker22': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 22' },
    'PetStacker23': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 23' },
    'PetStacker24': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 24' },
    'PetStacker25': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 25' },
    'PetStacker26': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 26' },
    'PetStacker27': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 27' },
    'PetStacker28': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 28' },
    'PetStacker29': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 29' },
    'PetStacker30': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 30' },
    'PetStacker31': { type: 'main', topic:'StacksQueues', icon: 'Queen', name: 'Wild West Pets 31' },

    'Temple1': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 1' },
    'Temple2': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 2' },
    'Temple3': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 3' },
    'Temple4': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 4' },
    'Temple5': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 5' },
    'Temple6': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 6' },
    'Temple7': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 7' },
    'Temple8': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 8' },

    'Temple9': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 9' },
    'Temple10': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 10' },
    'Temple11': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 11' },
    'Temple12': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 12' },
    'Temple13': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 13' },
    'Temple14': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 14' },
    'Temple15': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 15' },
    'Temple16': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 16' },

    'Temple17': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 17' },
    'Temple18': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 18' },
    'Temple19': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 19' },
    'Temple20': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 20' },
    'Temple21': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 21' },
    'Temple22': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 22' },
    'Temple23': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 23' },
    'Temple24': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 24' },

    'Temple25': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 25' },
    'Temple26': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 26' },
    'Temple27': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 27' },
    'Temple28': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 28' },
    'Temple29': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 29' },
    'Temple30': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 30' },
    'Temple31': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 31' },
    'Temple32': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 32' },

    'Temple33': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 33' },
    'Temple34': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 34' },
    'Temple35': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 35' },
    'Temple36': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 36' },
    'Temple37': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 37' },
    'Temple38': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 38' },
    'Temple39': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 39' },

    'Temple40': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 40' },
    'Temple41': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 41' },
    'Temple42': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 42' },
    'Temple43': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 43' },
    'Temple44': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 44' },
    'Temple45': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 45' },
    'Temple46': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 46' },
    'Temple47': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 47' },
    'Temple48': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 48' },

    'Temple49': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 49' },
    'Temple50': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 50' },
    'Temple51': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 51' },
    'Temple52': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 52' },
    'Temple53': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 53' },
    'Temple54': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 54' },
    'Temple55': { type: 'main', topic: 'Inequalities', icon: 'Queen', name: 'Crocodile Catch 55' },


    // 'TutorialIntro' : { type: 'extraTutorial', topic:'Tutorials', icon: '', name: 'TutorialIntro' },
    'TutorialDraw' : { type: 'extraTutorial', topic:'Tutorials', icon: '', name: 'TutorialDraw' },
    'TutorialErase' : { type: 'extraTutorial', topic:'Tutorials', icon: '', name: 'TutorialErase' },
    'TutorialMove' : { type: 'extraTutorial', topic:'Tutorials', icon: '', name: 'TutorialMove' },
    // 'TutorialShop' : { type: 'extraTutorial', topic:'Tutorials', icon: '', name: 'TutorialShop' },
    'TutorialBoughtItem' : { type: 'extraTutorial', topic:'Tutorials', icon: '', name: 'TutorialBoughtItem' },
    'TutorialScale' : { type: 'extraTutorial', topic:'Tutorials', icon: '', name: 'TutorialScale' },
    'TutorialCodeWalk' : { type: 'extraTutorial', topic:'Tutorials', icon: '', name: 'TutorialCodeWalk' },
    'TutorialCodeJump' : { type: 'extraTutorial', topic:'Tutorials', icon: '', name: 'TutorialCodeJump' },
    'TutorialCodeJumpSpring' : { type: 'extraTutorial', topic:'Tutorials', icon: '', name: 'TutorialCodeJumpSpring' },

    'StoryIdeaEat' : { type: 'extraTutorial', topic:'StoryIdeaTutorials', icon: '', name: 'StoryIdeaEat' },
    'StoryIdeaAct' : { type: 'extraTutorial', topic:'StoryIdeaTutorials', icon: '', name: 'StoryIdeaAct' },
    'StoryIdeaAndThen' : { type: 'extraTutorial', topic:'StoryIdeaTutorials', icon: '', name: 'StoryIdeaAndThen' },
    'StoryIdeaPhoto' : { type: 'extraTutorial', topic:'StoryIdeaTutorials', icon: '', name: 'StoryIdeaPhoto' },
    'StoryIdeaJoke' : { type: 'extraTutorial', topic:'StoryIdeaTutorials', icon: '', name: 'StoryIdeaJoke' },
    'StoryIdeaWalk' : { type: 'extraTutorial', topic:'StoryIdeaTutorials', icon: '', name: 'StoryIdeaWalk' },
    'StoryIdeaNewScene' : { type: 'extraTutorial', topic:'StoryIdeaTutorials', icon: '', name: 'StoryIdeaNewScene' },
    'StoryIdeaWhenStarted' : { type: 'extraTutorial', topic:'StoryIdeaTutorials', icon: '', name: 'StoryIdeaWhenStarted' },
    'StoryIdeaJump' : { type: 'extraTutorial', topic:'StoryIdeaTutorials', icon: '', name: 'StoryIdeaJump' },
    'StoryIdeaLoopInStory' : { type: 'extraTutorial', topic:'StoryIdeaTutorials', icon: '', name: 'StoryIdeaLoopInStory' },
    'StoryIdeaWhenItHears' : { type: 'extraTutorial', topic:'StoryIdeaTutorials', icon: '', name: 'StoryIdeaWhenItHears' },
    'StoryIdeaPickUp' : { type: 'extraTutorial', topic:'StoryIdeaTutorials', icon: '', name: 'StoryIdeaPickUp' },
    'StoryIdeaChangeToScene' : { type: 'extraTutorial', topic:'StoryIdeaTutorials', icon: '', name: 'StoryIdeaChangeToScene' },

    'AckGiantMonster' : { type: 'main', topic:'GameKits', icon: '', name: 'AckGiantMonster' },
    'BuriedSecretCastle' : { type: 'main', topic:'GameKits', icon: '', name: 'BuriedSecretCastle' },
    'BounceHouseParty' : { type: 'main', topic:'GameKits', icon: '', name: 'BounceHouseParty' },
    'TallCandyTower' : { type: 'main', topic:'GameKits', icon: '', name: 'TallCandyTower' },
    'EscapeMagicHero' : { type: 'main', topic:'GameKits', icon: '', name: 'EscapeMagicHero' },
    'FooDancingMania' : { type: 'main', topic:'GameKits', icon: '', name: 'FooDancingMania' },
    'FooGatorGame' : { type: 'main', topic:'GameKits', icon: '', name: 'FooGatorGame' },
    'IcyCoolVolcano' : { type: 'main', topic:'GameKits', icon: '', name: 'IcyCoolVolcano' },
    'JumpEnemyPark' : { type: 'main', topic:'GameKits', icon: '', name: 'JumpEnemyPark' },
    'JumpTireCity' : { type: 'main', topic:'GameKits', icon: '', name: 'JumpTireCity' },
    'SecretElephantTemple' : { type: 'main', topic:'GameKits', icon: '', name: 'SecretElephantTemple' },
    'SuperFooWorld' : { type: 'main', topic:'GameKits', icon: '', name: 'SuperFooWorld' },
    'TimedGameKit' : { type: 'main', topic:'GameKits', icon: '', name: 'TimedGameKit' },
    'HiddenTempleKit' : { type: 'main', topic:'GameKits', icon: '', name: 'HiddenTempleKit' }
};

export default Missions;