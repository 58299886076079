const Parse = require('parse');
const { calculateStudentsProgress } = require('./students');

Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, process.env.REACT_APP_PARSE_JS_KEY);
Parse.serverURL = process.env.REACT_APP_PARSE_URL;

export const getActiveClassrooms = (teacherId) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetClassrooms', teacherId).then((parseClassrooms) => {
      const classrooms = parseClassrooms.map((classroom) => {
        const students = classroom.get('students') || [];
        return {
          name: classroom.get('name'),
          id: classroom.id,
          created: classroom.get('createdAt'),
          numStudents: classroom.get('totalStudents'),
          grades: classroom.get('grades'),
        };
      });
      sessionStorage.setItem('classroomsList', JSON.stringify(classrooms));
      resolve(classrooms);
    }, (error) => {
      reject(error);
    });
  });
};

export const getArchivedClassrooms = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetArchivedClassrooms', params).then((parseClassrooms) => {
      const classrooms = parseClassrooms.map((classroom) => {
        const students = classroom.get('students') || [];
        return {
          name: classroom.get('name'),
          id: classroom.id,
          created: classroom.get('createdAt'),
          numStudents: students.length,
          grades: classroom.get('grades'),
        };
      });

      resolve(classrooms);
    }, (error) => {
      reject(error);
    });
  });
};

export const getClassroomDetails = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetClassroomDetails', params).then((results) => {
      resolve(results);
    }, (error) => {
      // checks if an object is not returned then we will reroute on client
      if (error.code === 141) {
        resolve(error);
      } else {
        reject(error);
      }
    });
  });
};

export const GetClassroomDetailsWithPermissions = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetClassroomDetailsWithPermissions', params).then((results) => {
      resolve(results);
    }, (error) => {
      reject(error);
    });
  });
};

export const getClassroomProjects = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetClassroomProjects', params).then((results) => {
      resolve(results);
    }, (error) => {
      reject(error);
    });
  });
};

export const getClassroomProjectCount = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetClassroomProjectCount', params).then((results) => {
      resolve(results);
    }, (error) => {
      reject(error);
    });
  });
};

export const getClassroomSummary = (company, parseClassroom) => {
  const missionsData = require(`./data/${company}/missions`);
  const missions = missionsData.default;
  const students = parseClassroom.get('students') || [];
  const summary = {};

  summary.id = parseClassroom.id;

  summary.totalStars = students.reduce((memo, student) => {
    if (student.has('childProfile')) {
      const childProfile = student.get('childProfile');
      const missionsCompleted = childProfile.get('missionsCompleted');

      for (var key in missionsCompleted) {
        memo += missionsCompleted[key];
      }
    }

    return memo;
  }, 0);

  // keys in mission-constants.js * 3 stars per level
  const percent = summary.totalStars / (Object.keys(missions).length * 3 * students.length);
  // there are 8 different icons for percentage
  summary.progress = 'progress_' + Math.floor(percent * 8) + '_lg';
  summary.percent = Math.floor(percent * 100);
  summary.avgChapterStars = summary.totalStars / students.length;

  summary.puzzleStarsPercent = parseClassroom.get('puzzleStarsPercent');
  summary.studioLevelsPublished = parseClassroom.get('studioLevelsPublished');
  summary.numStudents = students.length;
  return summary;
};

export const getStudentsProgress = (company, params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetClassroomDetails', params).then((classroom) => {
      let passwordEnabled;
      if (classroom.get('options').hasOwnProperty('StudentPasswords') === false) {
        passwordEnabled = { isRestricted: true };
      } else {
        passwordEnabled = classroom.get('options').StudentPasswords;
      }
      const students = classroom.get('students') || [];
      const options = {
        progress: calculateStudentsProgress(company, students),
        passwordEnabled: passwordEnabled
      };
      resolve(options);
    }, (error) => {
      reject(error);
    });
  });
};

export const createClassroom = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('CreateClassroom', params).then((classroom) => {
      resolve(classroom);
    }, (error) => {
      reject(error);
    });
  });
};

export const editClassroom = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('EditClassroom', params).then((classroom) => {
      resolve(classroom);
    }, (error) => {
      reject(error);
    });
  });
};

export const setClassroomArchiveStatus = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('SetClassroomArchiveStatus', params).then((result) => {
      resolve(result);
    }, (error) => {
      reject(error);
    });
  });
};

export const removeGameProject = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('ClassroomDeleteGame', params).then((result) => {
      resolve(result);
    }, (error) => {
      reject(error);
    });
  });
};

export const removeStoryProject = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('ClassroomDeleteStory', params).then((result) => {
      resolve(result);
    }, (error) => {
      reject(error);
    });
  });
};

export const removeTeacherFromClassroom = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('RemoveTeacherFromClassroom', params).then((result) => {
      resolve(result);
    }, (error) => {
      reject(error);
    });
  });
};

export const getClassroomArchiveStatus = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetClassroomArchiveStatus', params).then((result) => {
      resolve(result.isArchived);
    }, (error) => {
      reject(error);
    });
  });
};

export const addStudentsToClassroom = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('AddStudentsToClassroom', params).then((success) => {
      resolve(success);
    }, (error) => {
      reject(error);
    });
  });
};

export const addTeacherToClassroom = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('AddTeacherToClassroom', params).then((success) => {
      resolve(success);
    }, (error) => {
      reject(error);
    });
  });
};

export const getTeachersForClassroom = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetTeachersForClassroom', params).then((teachers) => {
      resolve(teachers);
    }, (error) => {
      reject(error);
    });
  });
};

export const updateClassroomOptions = (id, options) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('SetClassroomOptions', {
      classroomId: id,
      options: options
    }).then((classroom) => {
      resolve(classroom);
    }, (error) => {
      reject(error);
    });
  });
};

export const unlockFeaturesForStudents = (id) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('UnlockAllContentForStudents', {
      classroomId: id
    }).then((classroom) => {
      resolve(classroom);
    }, (error) => {
      reject(error);
    });
  });
};

export const unenrollStudents = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('UnenrollStudents', params).then((success) => {
      resolve(success);
    }, (error) => {
      reject(error);
    });
  });
};

/**
 * createClassroomCode - creates a unique code so students can log into a classroom
 *
 * @param {*} params.classroomId - the id for the classroom
 */
export const createClassroomCode = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('generateClassroomCode', params).then((success) => {
      resolve(success);
    }, (error) => {
      reject(error);
    });
  });
};
