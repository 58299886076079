import React from 'react';
import { Helmet } from 'react-helmet';

import './NotFoundWrapper.scss';

const s3Url = process.env.REACT_APP_BASE_S3_URL;

const NotFound = () => {

  return (
    <div className="not-found-page page">
      <Helmet>
        <title>Page Not Found | codeSpark Academy for Educators</title>
      </Helmet>
      <h1 className="four0four-title">404</h1>
      <h3 className="four0four-subtitle">You found a Glitch!</h3>
      <div className="image-container">
        <img className="four0four-image" src={`${s3Url}/global/404_glitch.png`} alt="" />
      </div>
      <p className="four0four-desc">This means you are looking for a page that doesn&rsquo;t exist.</p>
    </div>
  );
}

export default NotFound;