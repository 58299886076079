import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import request from 'axios';
import __camelCase from 'lodash/camelCase';
import __toLower from 'lodash/toLower';

import './TableView.scss';

import SubNav from '../../../components/global/SubNav';
import Table from '../../../components/global/Table';

const s3Url = process.env.REACT_APP_S3_CONTENT_URL;
const env = process.env.REACT_APP_NODE_ENV;

class TableView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
    };

    this.getPageData = this.getPageData.bind(this);
  }

  componentDidMount() {
    if (this.props.currLocale) {
      this.getPageData(this.props.currLocale);
    } else {
      this.getPageData('en_US');
    }
  }

  componentDidUpdate(prevProps) {
    const { currLocale, resourceData } = this.props;
    if (prevProps.currLocale !== currLocale || prevProps.resourceData.name !== resourceData.name) {
      this.getPageData(currLocale);
    }
  }

  async getPageData(currLocale) {
    const locale = currLocale;
    const { resourceData, siteContent } = this.props;
    const { filename, name, itemIndex } = resourceData;
    const company = __toLower(__camelCase(siteContent.company));
    let path = '';

    if (name === 'ResourcesFaq') {
      path = `${s3Url}/${env}/${locale}/resources.faq.${company}.json`;
    } else if (name === 'ResourcesGettingStartedGuide') {
      path = `${s3Url}/${env}/${locale}/resources.getting-started.${company}.json`;
    } else {
      path = `${s3Url}/${env}/${locale}/${filename}.json`;
    }

    await request.get(path).then(({ data }) => {
      if (itemIndex !== undefined) {
        this.setState({ data: data.items[itemIndex] });
      } else {
        this.setState({ data });
      }
    });
  }

  render() {
    const { data } = this.state;
    const { vocabulary, siteContent, resourceData } = this.props;
    const { hasDownloads, page, footnote } = resourceData;

    if (!Object.keys(data).length) return <div className="page page-gray" />;

    return (
      <div className="table-view-container page page-gray">
        <SubNav page={page} vocabulary={vocabulary} siteContent={siteContent} />
        <Table
            title={data.title}
            subtitle={data.subtitle || data.description}
            rowItems={data.items}
            vocabulary={vocabulary}
            hasDownloads={hasDownloads}
            footnote={footnote}
        />
      </div>
    );
  }
}

export default withRouter(TableView);
