import React from 'react';
import __fill from 'lodash/fill';
import { Link } from 'react-router-dom';

import './Table.scss';

import { Mixpanel } from '../../../utils/mixpanel';
import Button from '../../../components/elements/DashboardButton';
import { getCurrentUserID } from '../../../services/teacher';
class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAll: false,
      showEach: __fill(Array(props.rowItems.length), false),
    };

    this.toggleRow = this.toggleRow.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
  }

  toggleRow(rowIndex) {
    this.setState((prevState) => ({
      showEach: prevState.showEach.map((item, index) => {
        if (index === rowIndex) {
          return !item;
        }
        return item;
      }),
    }));
  }

  toggleAll() {
    this.setState((prevState) => ({
      showAll: !prevState.showAll,
      showEach: prevState.showEach.map((item) => {
        return !prevState.showAll;
      }),
    }));
  }

  openFooTubeLink () {
    window.open('https://www.youtube.com/c/codeSparkAcademyFOOtubeforKids', '_blank', 'noopener,noreferrer');
    Mixpanel.track('Dashboard (v1.3): Button Clicked', {
      parseAccountId: getCurrentUserID(),
      destination: 'FOOtube Kids',
    });
  }

  openGoogleDriveLink() {
    window.open('https://drive.google.com/drive/folders/1YiLjMJdbQDW-niwFwMFyUzoBkE2I0cnj?usp=sharing', '_blank', 'noopener,noreferrer');
    Mixpanel.track('Dashboard (v1.3): Button Clicked', {
      parseAccountId: getCurrentUserID(),
      destination: 'New Unplugged Activities',
    });
  }

  sendDownloadEvent(item, type = '') {
    if (window.location.pathname.includes('/plans/glossary')) {
      console.info(
        `User ${getCurrentUserID()} Dashboard (v1.4): Vocabulary Downloads buttons on /dashboard/lessons/plans/glossary`
      );
      Mixpanel.track('Dashboard (v1.4): Vocabulary Downloads', {
        parseAccountId: getCurrentUserID(),
        resource: item.title,
        type,
      });
    }

    if (
      window.location.pathname.includes('/summer-jump') &&
      (this.props.title === 'unplugged activities' ||
        this.props.title === 'worksheets' ||
        this.props.title.includes('ideas'))
    ) {
      console.info(
        `User ${getCurrentUserID()} Download Event: "download" buttons on /dashboard/lessons/summer-jump`
      );
      Mixpanel.track('Dashboard (v1.4): Quick Lesson Ideas Downloads', {
        parseAccountId: getCurrentUserID(),
        resource: item.title,
      });
    }

    if (window.location.pathname === '/dashboard/lessons/plans/lessons') {
      console.info(
        `User ${getCurrentUserID()} Download Event: "Full lesson" download on /dashboard/lessons/plans/lessons`
      );
      Mixpanel.track(
        'Dashboard (v1.4): Intro Computer Science Lesson Download',
        {
          parseAccountId: getCurrentUserID(),
          destinationUrl: item.title,
          resource: item.title,
          type: type ? type : '',
        }
      );
    }

    if (window.location.pathname === '/dashboard/lessons/unplugged-activities') {
      console.info(
        `User ${getCurrentUserID()} Download Event: "download" button on /dashboard/lessons/unplugged-activities`
      );
      Mixpanel.track(
        'Dashboard (v1.4): Unplugged Activities Download',
        {
          parseAccountId: getCurrentUserID(),
          resource: item.title,
        }
      );
    }

    if (window.location.pathname === '/dashboard/lessons/AGmini-lessons') {
      console.info(
        `User ${getCurrentUserID()} Download Event: "download" button on /dashboard/lessons/AGmini-lessons`
      );
      Mixpanel.track('Dashboard (v1.4): AG Mini Lesson Download', {
        parseAccountId: getCurrentUserID(),
        resource: item.title,
      });
    }

    if (window.location.pathname === '/dashboard/lessons/elaIntegrated-lessons') {
      console.info(
        `User ${getCurrentUserID()} Download Event: "download" button on /dashboard/lessons/elaIntegrated-lessons`
      );
      Mixpanel.track('Dashboard (v1.4): ELA Download', {
        parseAccountId: getCurrentUserID(),
        resource: item.title,
      });
    }
  }

  render() {
    const { showAll, showEach } = this.state;
    const {
      vocabulary,
      title,
      subtitle,
      rowItems,
      hasDownloads,
      footnote,
      downloadLink,
    } = this.props;

    let downloadPromptOne;
    let downloadPromptTwo;
    let downloadPromptThree;
    if (
      title === 'unplugged activities' ||
      title === 'worksheets' ||
      title.includes('ideas')
    ) {
      downloadPromptOne = '';
      downloadPromptTwo = '';
      downloadPromptThree = 'Download';
    } else if (title === 'glossary') {
      downloadPromptOne = 'Slide';
      downloadPromptTwo = 'PDF';
      downloadPromptThree = 'PNG';
    } else if (title.includes('adventure') || title.includes('ELA')) {
      downloadPromptOne = '';
      downloadPromptTwo = '';
      downloadPromptThree = vocabulary['dashboard.table.fullLesson'];
    } else if (title.includes('lessons') || title.includes('computer science')) {
      downloadPromptOne = '';
      downloadPromptTwo = vocabulary['dashboard.table.overview'];
      downloadPromptThree = vocabulary['dashboard.table.fullLesson'];
    } else if (title.includes('summer')) {
      downloadPromptOne = vocabulary['dashboard.table.overview'];
      downloadPromptTwo = vocabulary['dashboard.table.fullLesson'];
      downloadPromptThree = '';
    } else {
      downloadPromptOne = '';
      downloadPromptTwo = '';
      downloadPromptThree = '';
    }
    // console.log('*** PROPS ***', this.props);
    return (
      <div className="table">
        <div className="title">
          <h2>
            {title}
            <div
              className="subtitle"
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          </h2>
          <div>
            {downloadLink && (
              <a className="download" href={downloadLink} target="_blank">
                <i className="fa fa-download" />
              </a>
            )}
            <Button
              className="button--small button--wide button--lowercase"
              onClick={this.toggleAll}
            >
              {!showAll
                ? vocabulary['dashboard.table.expand']
                : vocabulary['dashboard.table.collapse']}
            </Button>
          </div>
        </div>

        {/** show FooTube video button only on Quick Lessons page */}
        {window.location.pathname.includes('/summer-jump') && (
          <div className="footube-btn">
            <Button
              className="button--small button--wide button"
              onClick={() => this.openFooTubeLink()}
            >
              {vocabulary['dashboard.resources.summerjump.fooTube']}
            </Button>
          </div>
        )}
        {/** show Google Drive button for Unplugged Activities */}
        {window.location.pathname.includes('/unplugged-activities') && (
          <div className="footube-btn">
            <Button
              className="button--small button--wide button"
              onClick={() => this.openGoogleDriveLink()}
            >
              newest activities and printables
            </Button>
          </div>
        )}
        <div>
          <div className="row-header">
            <div className="col-100">{downloadPromptOne}</div>
            <div className="col-100">{downloadPromptTwo}</div>
            <div className="col-100">{downloadPromptThree}</div>
          </div>
          <div>
            {rowItems.map((item, index) => (
              <div key={item.title} className="row-container">
                <div
                  className={`row ${showEach[index] ? 'expanded' : ''}`}
                  onClick={() => this.toggleRow(index)}
                >
                  <div className="col">
                    <span className="row-title">{item.title}</span>
                    {item.subtitle && (
                      <span className="row-subtitle">{item.subtitle}</span>
                    )}
                  </div>
                  {hasDownloads && (
                    <div className="col">
                      <div className="col-100">
                        {item.etcLink && (
                          <a
                            href={item.etcLink}
                            target="_blank"
                            onClick={() =>
                              this.sendDownloadEvent(item, 'Slide')
                            }
                          >
                            <i className="fa fa-download" />
                          </a>
                        )}
                      </div>
                      <div className="col-100">
                        {item.overviewLink && (
                          <a
                            href={item.overviewLink}
                            target="_blank"
                            onClick={() =>
                              this.sendDownloadEvent(
                                item,
                                downloadPromptTwo === 'Overview'
                                  ? 'overview'
                                  : 'PDF'
                              )
                            }
                          >
                            <i className="fa fa-download" />
                          </a>
                        )}
                      </div>
                      <div className="col-100">
                        {item.link && (
                          <a
                            href={item.link}
                            target="_blank"
                            onClick={() => this.sendDownloadEvent(item)}
                          >
                            <i className="fa fa-download" />
                          </a>
                        )}
                        {item.fullLessonLink && (
                          <a
                            href={item.fullLessonLink}
                            target="_blank"
                            onClick={() =>
                              this.sendDownloadEvent(
                                item,
                                downloadPromptThree === 'Full Lesson'
                                  ? 'lesson'
                                  : ''
                              )
                            }
                          >
                            <i className="fa fa-download" />
                          </a>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {showEach[index] && (
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                )}
              </div>
            ))}
            {title === 'getting started guide' && (
              <div className="row-container">
                <div className="row-link">
                  <div className="col">
                    <span className="row-title">
                      <Link
                        to={{
                          pathname: '/dashboard/lessons/plans/glossary',
                          state: {
                            filename: 'lessons.plans.glossary',
                            name: 'LessonsPlansGlossary',
                            page: 'lessons',
                            hasDownloads: true,
                          },
                        }}
                      >
                        glossary link
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {footnote && <div className="table-footnote">{footnote}</div>}
      </div>
    );
  }
}

export default Table;
