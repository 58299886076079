import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { getActiveClassrooms } from '../services/classroom';
import { getAllStudents } from '../services/students';
import { setClassrooms, setStudents } from '../redux/actions';

export const notify = (type, message, timeOut, onClick) => {
  NotificationManager.remove({ id: 1 });

  setTimeout(() => {
    NotificationManager.create({
      id: 1,
      type: type,
      message: message,
      title: '',
      timeOut: timeOut,
      onClick: onClick
    });
  }, 450);
};

export const refreshClassrooms = (teacherId) => {
  getActiveClassrooms(teacherId).then((classrooms) => {
    useDispatch(setClassrooms(classrooms));
  });
};

export const refreshStudents = (teacherId) => {
  getAllStudents(teacherId).then((students) => {
    useDispatch(setStudents(students));
  });
}
