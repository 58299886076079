import React from 'react';

import './Standards.scss';
import SubNav from '../../../../components/global/SubNav';

const s3PdfUrl = process.env.REACT_APP_S3_PDF_URL;

class Standards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      vocabPrefix: 'dashboard.resources.standards',
      rowsGame: [
        { category: 'Computer Science', standard: 'CSTA 1A-A-5-2', inGame: ['Chapter 1', 'Chapter 2', 'Chapter 3', 'Chapter 4', 'Chapter 5', 'Studio', 'Sweet Sorter'], url: 'https://csteachers.org/documents/en-us/7a89a973-92df-4c32-bef5-d875e0d69144/1/' },
        { category: 'Computer Science', standard: 'CSTA 1A-A-4-4', inGame: ['Chapter 1', 'Chapter 2', 'Chapter 3', 'Chapter 4', 'Chapter 5', 'Studio'], url: 'https://csteachers.org/documents/en-us/7a89a973-92df-4c32-bef5-d875e0d69144/1/' },
        { category: 'Computer Science', standard: 'CSTA 1A-A-3-5', inGame: ['Studio'], url: 'https://csteachers.org/documents/en-us/7a89a973-92df-4c32-bef5-d875e0d69144/1/' },
        { category: 'Computer Science', standard: 'CSTA 1A-A-3-6', inGame: ['Studio', 'Sweet Sorter'], url: 'https://csteachers.org/documents/en-us/7a89a973-92df-4c32-bef5-d875e0d69144/1/' },
        { category: 'Computer Science', standard: 'CSTA 1A-A-3-7', inGame: ['Chapter 1', 'Chapter 2', 'Chapter 3', 'Chapter 4', 'Chapter 5', 'Studio'], url: 'https://csteachers.org/documents/en-us/7a89a973-92df-4c32-bef5-d875e0d69144/1/' },
        { category: 'Computer Science', standard: 'CSTA 1A-C-7-9', inGame: ['Chapter 1', 'Chapter 2', 'Chapter 3', 'Chapter 4', 'Chapter 5', 'Studio', 'Sweet Sorter'], url: 'https://csteachers.org/documents/en-us/7a89a973-92df-4c32-bef5-d875e0d69144/1/' },
        { category: 'Computer Science', standard: 'CSTA 1A-N-2-16', inGame: ['Studio'], url: 'https://csteachers.org/documents/en-us/7a89a973-92df-4c32-bef5-d875e0d69144/1/' },
        { category: 'Computer Science', standard: 'CSTA 1B-A-5-4', inGame: ['Chapter 1', 'Chapter 2', 'Chapter 3', 'Chapter 4', 'Chapter 5', 'Studio'], url: 'https://csteachers.org/documents/en-us/7a89a973-92df-4c32-bef5-d875e0d69144/1/' },
        { category: 'Computer Science', standard: 'CSTA 1B-A-3-7', inGame: ['Chapter 1', 'Chapter 2', 'Chapter 3', 'Chapter 4', 'Chapter 5', 'Studio'], url: 'https://csteachers.org/documents/en-us/7a89a973-92df-4c32-bef5-d875e0d69144/1/' },
        { category: 'Math', standard: 'CCSS.Math.Practice.MP1', inGame: ['Chapter 1', 'Chapter 2', 'Chapter 3', 'Chapter 4', 'Chapter 5', 'Studio', 'Sweet Sorter'], url: 'http://www.corestandards.org/Math/Practice/' },
        { category: 'Math', standard: 'CCSS.Math.Practice.MP2', inGame: ['Chapter 1', 'Chapter 2', 'Chapter 3', 'Chapter 4', 'Chapter 5', 'Studio', 'Sweet Sorter'], url: 'http://www.corestandards.org/Math/Practice/' },
        { category: 'Math', standard: 'CCSS.Math.Practice.MP4', inGame: ['Studio'], url: 'http://www.corestandards.org/Math/Practice/' },
        { category: 'Math', standard: 'CCSS.Math.Practice.MP6', inGame: ['Chapter 1', 'Chapter 2', 'Chapter 3', 'Chapter 4', 'Chapter 5', 'Studio', 'Sweet Sorter'], url: 'http://www.corestandards.org/Math/Practice/' },
        { category: 'Math', standard: 'CCSS.Math.Practice.MP7', inGame: ['Chapter 2', 'Chapter 3', 'Chapter 4', 'Chapter 5', 'Studio'], url: 'http://www.corestandards.org/Math/Practice/' },
        { category: 'Math', standard: 'CCSS.Math.Practice.MP8', inGame: ['Chapter 2', 'Chapter 3', 'Chapter 4', 'Chapter 5', 'Studio'], url: 'http://www.corestandards.org/Math/Practice/' },
        { category: 'Math', standard: 'CCSS.Math.Content.K.MD.B.3', inGame: ['Studio', 'Sweet Sorter'], url: 'http://www.corestandards.org/Math/Content/K/MD/B/3/' },
        { category: 'Math', standard: 'CCSS.Math.Content.K.G.A.1', inGame: ['Sweet Sorter'], url: 'http://www.corestandards.org/Math/Content/K/G/A/1/' },
        { category: 'Art', standard: 'MA:Cr1.1.K', inGame: ['Studio'], url: 'http://nationalartsstandards.org/sites/default/files/Media%20Arts%20at%20a%20Glance.pdf' },
        { category: 'Art', standard: 'MA:Cr2.1.K', inGame: ['Studio'], url: 'http://nationalartsstandards.org/sites/default/files/Media%20Arts%20at%20a%20Glance.pdf' },
        { category: 'Art', standard: 'MA:Cr3.1.1.a', inGame: ['Studio'], url: 'http://nationalartsstandards.org/sites/default/files/Media%20Arts%20at%20a%20Glance.pdf' },
        { category: 'Art', standard: 'MA:Cr3.1.1.b', inGame: ['Studio'], url: 'http://nationalartsstandards.org/sites/default/files/Media%20Arts%20at%20a%20Glance.pdf' },
        { category: 'Art', standard: 'MA:Pr5.1.1.a', inGame: ['Studio'], url: 'http://nationalartsstandards.org/sites/default/files/Media%20Arts%20at%20a%20Glance.pdf' },
        { category: 'Art', standard: 'MA:Pr5.1.1.b', inGame: ['Studio'], url: 'http://nationalartsstandards.org/sites/default/files/Media%20Arts%20at%20a%20Glance.pdf' },
        { category: 'ISTE Standards', standard: 'EmpoweredLearner-a1', inGame: ['Studio'], url: 'http://www.iste.org/standards/standards/for-students-2016' },
        { category: 'ISTE Standards', standard: 'EmpoweredLearner-c1', inGame: ['Chapter 1', 'Chapter 2', 'Chapter 3', 'Chapter 4', 'Chapter 5', 'Studio', 'Sweet Sorter'], url: 'http://www.iste.org/standards/standards/for-students-2016' },
        { category: 'ISTE Standards', standard: 'InnovativeDesigner-4d', inGame: ['Studio'], url: 'http://www.iste.org/standards/standards/for-students-2016' },
        { category: 'ISTE Standards', standard: 'ComputationalThinker-5a', inGame: ['Chapter 1', 'Chapter 2', 'Chapter 3', 'Chapter 4', 'Chapter 5', 'Studio'], url: 'http://www.iste.org/standards/standards/for-students-2016' },
        { category: 'ISTE Standards', standard: 'ComputationalThinker-5c', inGame: ['Studio'], url: 'http://www.iste.org/standards/standards/for-students-2016' },
        { category: 'ISTE Standards', standard: 'ComputationalThinker-5d', inGame: ['Chapter 1', 'Chapter 2', 'Chapter 3', 'Chapter 4', 'Chapter 5', 'Studio'], url: 'http://www.iste.org/standards/standards/for-students-2016' },
        { category: 'ISTE Standards', standard: 'CreativeCommunicator-6b', inGame: ['Studio'], url: 'http://www.iste.org/standards/standards/for-students-2016' },
        { category: 'ISTE Standards', standard: 'CreativeCommunicator-6d', inGame: ['Studio'], url: 'http://www.iste.org/standards/standards/for-students-2016' },
        { category: 'ISTE Standards', standard: 'GlobalCollaborator-7a', inGame: ['Studio'], url: 'http://www.iste.org/standards/standards/for-students-2016' },
      ],
      rowsCurriculum: [
        { category: 'Math', standard: 'CCSS.Math.Practice.MP1', lessons: ['Lesson 1', 'Lesson 4', 'Lesson 10'], url: 'http://www.corestandards.org/Math/Practice/#CCSS.Math.Practice.MP1' },
        { category: 'Math', standard: 'CCSS.Math.Practice.MP2', lessons: ['Lesson 8', 'Lesson 9'], url: 'http://www.corestandards.org/Math/Practice/#CCSS.Math.Practice.MP2' },
        { category: 'Math', standard: 'CCSS.Math.Practice.MP3', lessons: ['Lesson 10'], url: 'http://www.corestandards.org/Math/Practice/#CCSS.Math.Practice.MP3' },
        { category: 'Math', standard: 'CCSS.Math.Practice.MP4', lessons: ['Lesson 5', 'Lesson 7'], url: 'http://www.corestandards.org/Math/Practice/#CCSS.Math.Practice.MP4' },
        { category: 'Math', standard: 'CCSS.Math.Practice.MP5', lessons: ['Lesson 5', 'Lesson 6', 'Lesson 7', 'Lesson 8', 'Lesson 9'], url: 'http://www.corestandards.org/Math/Practice/#CCSS.Math.Practice.MP5' },
        { category: 'Math', standard: 'CCSS.Math.Practice.MP6', lessons: ['Lesson 1', 'Lesson 3', 'Lesson 5', 'Lesson 7'], url: 'http://www.corestandards.org/Math/Practice/#CCSS.Math.Practice.MP6' },
        { category: 'Math', standard: 'CCSS.Math.Practice.MP7', lessons: ['Lesson 1', 'Lesson 2', 'Lesson 10'], url: 'http://www.corestandards.org/Math/Practice/#CCSS.Math.Practice.MP7' },
        { category: 'Math', standard: 'CCSS.Math.Practice.MP8', lessons: ['Lesson 5', 'Lesson 7', 'Lesson 8', 'Lesson 9'], url: 'http://www.corestandards.org/Math/Practice/#CCSS.Math.Practice.MP8' },
      ],
      lessonsUrl: {
        'Lesson 1': `${s3PdfUrl}/english/codeSpark-Full-CS-Curriculum-May-2016.pdf#page=11`,
        'Lesson 2': `${s3PdfUrl}/english/codeSpark-Full-CS-Curriculum-May-2016.pdf#page=23`,
        'Lesson 3': `${s3PdfUrl}/english/codeSpark-Full-CS-Curriculum-May-2016.pdf#page=36`,
        'Lesson 4': `${s3PdfUrl}/english/codeSpark-Full-CS-Curriculum-May-2016.pdf#page=40`,
        'Lesson 5': `${s3PdfUrl}/english/codeSpark-Full-CS-Curriculum-May-2016.pdf#page=51`,
        'Lesson 6': `${s3PdfUrl}/english/codeSpark-Full-CS-Curriculum-May-2016.pdf#page=63`,
        'Lesson 7': `${s3PdfUrl}/english/codeSpark-Full-CS-Curriculum-May-2016.pdf#page=73`,
        'Lesson 8': `${s3PdfUrl}/english/codeSpark-Full-CS-Curriculum-May-2016.pdf#page=80`,
        'Lesson 9': `${s3PdfUrl}/english/codeSpark-Full-CS-Curriculum-May-2016.pdf#page=87`,
        'Lesson 10': `${s3PdfUrl}/english/codeSpark-Full-CS-Curriculum-May-2016.pdf#page=91`,
      },
      headers: [
        'Chapter 1',
        'Chapter 2',
        'Chapter 3',
        'Chapter 4',
        'Chapter 5',
        'Studio',
        'Sweet Sorter',
      ],
    };
  }

  render() {
    const { vocabulary, siteContent } = this.props;
    const { vocabPrefix, rowsGame, rowsCurriculum, headers, lessonsUrl } = this.state;
    const s3Url = process.env.REACT_APP_S3_IMAGES_URL;

    return (
      <div className="resources-standards-container page page-gray">
        <SubNav page="resources" vocabulary={vocabulary} siteContent={siteContent} />
        <div className="standards-table">
          <div className="title">
            <h2>
              {vocabulary[`${vocabPrefix}.header`]}<br />
              <span className="subtitle">{vocabulary[`${vocabPrefix}.message`]}</span>
            </h2>
          </div>
          <div className="title section-title">
            <h3>
              {vocabulary[`${vocabPrefix}.ingame`]}<br />
              <span className="subtitle">{vocabulary[`${vocabPrefix}.ingameDescription`]}</span>
            </h3>
          </div>
          <div>
            <div className="row-header">
              { headers.map((header, index) => (
                <div key={`header-${index}`} className="col">{header}</div>
              ))}
            </div>
            {
              rowsGame.map((row, index) => (
                <div key={`row-game-${index}`} className="row-container">
                  <div className="row">
                    <div className="col row-title">
                      <div>{row.category}</div>
                      <div className="row-subtitle"><a href={row.url} target="_blank">{row.standard}</a></div>
                    </div>
                    {
                      headers.map((level, index) => (
                        <div key={`row-header-${index}`} className="col">
                          {row.inGame.indexOf(level) > -1 && <img className="checkmark-icon" src={`${s3Url}/checkmark.png`} alt="checkmark" /> }
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))
            }
          </div>
          <div className="title section-title">
            <h3>
              {vocabulary[`${vocabPrefix}.incurriculum`]}<br />
              <span className="subtitle">{vocabulary[`${vocabPrefix}.incurriculumDescription`]}</span>
            </h3>
          </div>
          <div>
            {
              rowsCurriculum.map((row, index) => (
                <div key={`row-curriculum-${index}`} className="row-container">
                  <div className="row">
                    <div className="col row-title">
                      <div>{row.category}</div>
                      <div className="row-subtitle"><a href={row.url} target="_blank">{row.standard}</a></div>
                    </div>
                    <div className="col full">
                      {
                        row.lessons.map((lesson, index) => (
                          <a key={`row-link-${index}`} href={lessonsUrl[lesson]} target="_blank">{lesson}</a>
                        ))
                      }
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Standards;
