import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module';

import App from './containers/App';
import store from './redux/store';
import registerServiceWorker from './registerServiceWorker';

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    dataLayer: {},
};

if (process.env.REACT_APP_NODE_ENV !== 'production') {
    tagManagerArgs['auth'] = 'cIbY-s0H-cms-MiYD9moEg';
    tagManagerArgs['preview'] = 'env-5';
}

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {track_pageview: false});
TagManager.initialize(tagManagerArgs);


const root = document.getElementById('root');
ReactDOM.render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  root
);


registerServiceWorker();
