const Parse = require('parse');
const __find = require('lodash/find');
const __size = require('lodash/size');
const __filter = require('lodash/filter');

const NUM_OF_IDEAS_TUTORIALS = 13;

Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, process.env.REACT_APP_PARSE_JS_KEY);
Parse.serverURL = process.env.REACT_APP_PARSE_URL;

export const updateStudent = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('EditStudent', {
      studentId: params.id,
      studentName: params.name,
      parentEmail: params.parentEmail
    }).then((student) => {
      resolve(student);
    }, (error) => {
      reject(error);
    });
  });
};

export const setStudentArchiveStatus = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('SetStudentArchiveStatus', params).then((students) => {
      resolve(students);
    }, (error) => {
      reject(error);
    });
  });
};

export const transferStudent = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('EditStudentEnrollment', {
      studentId: params.studentId,
      classroomDetails: [
        { id: params.fromClassroomId, isEnrolled: false },
        { id: params.toClassroomId, isEnrolled: true },
      ],
    }).then((result) => {
      resolve(result);
    }, (error) => {
      reject(error);
    });
  });
};

export const getAllStudents = (options) => {
  return new Promise((resolve, reject) => {
    let runFunction = 'GetStudentsForTeacher';

    if (options.isArchivedUnenrolled === 'archived') {
      runFunction = 'GetArchivedStudentsForTeacher';
    } else if (options.isArchivedUnenrolled === 'unenrolled') {
      runFunction = 'GetUnenrolledStudentsForTeacher';
    }

    Parse.Cloud.run(runFunction, {}).then((students) => {
      if (options.studentId) {
        const singleStudent = __find(students, ['id', options.studentId]);
        if (singleStudent) {
          resolve(singleStudent);
        }
        reject('no student');
      } else {
        resolve(students);
      }
    }, (error) => {
      reject(error);
    });
  });
};

export const resetStudentPassword = (studentId) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('ClearStudentPassword', {studentId: studentId}).then((response) => {
      resolve(response);
    }, (error) => {
      reject(error);
    });
  });
}

export const calculateStudentsProgress = (company, students) => {
  const missionsData = require(`./data/${company}/missions`);
  const missions = missionsData.default;

  var totalTopicStars = {};
  for (const name in missions) {
    const topic = missions[name].topic;
    totalTopicStars[topic] = totalTopicStars[topic] || 0;
    totalTopicStars[topic] += 3;
  }

  const studentsMap = students.map(function(student) {
    const missionProgress = {};
    const profile = {};
    const progress = {};
    const progressPercent = {};
    let totalProgress = 0;
    let totalPercent = 0;

    if (student.has('childProfile')) {
      const childProfile = student.get('childProfile');

      if (childProfile.has('puzzleProgressJson')) {
        profile.missionsCompleted = JSON.parse(childProfile.get('puzzleProgressJson'));
        for (const missionName in profile.missionsCompleted) {
          if (missions.hasOwnProperty(missionName)) {
            const { topic, name } = missions[missionName];
            progress[topic] = progress[topic] || 0;
            progress[topic] += profile.missionsCompleted[missionName].stars;

            missionProgress[topic] = missionProgress[topic] || {};
            missionProgress[topic][name] = profile.missionsCompleted[missionName];
          }
        }

        for (const key in progress) {
          const percent = progress[key]/totalTopicStars[key];
          const topicProgress = Math.floor(percent*8);
          totalProgress += topicProgress;
          totalPercent += percent;
          progressPercent[key] = percent;
          progress[key] = 'progress_' + topicProgress;
        }
      }

      if (childProfile.has('fsTutorialProgress')) {
        profile.fsTutorialProgress = childProfile.get('fsTutorialProgress');
        const key = 'Tutorials';
        missionProgress[key] = profile.fsTutorialProgress;
        // strip out the text from progress[key] so calculation can be performed
        if (progress[key]) {
          progress[key] = progress[key].replace('progress_', '');
        }

        for (const tutorial in profile.fsTutorialProgress) {
          if (tutorial.toLowerCase() !== 'none' ) {
            progress[key] = progress[key] ? parseInt(progress[key]) + 1 : 1;
          }
        }

        const NUM_OF_FS_TUTORIALS = __size(__filter(missions, {'topic': key}));
        let percent = progress[key]/NUM_OF_FS_TUTORIALS;
        if (percent) {
          percent = percent > 1 ? 1 : percent;
          const topicProgress = Math.floor(percent*8);
          progressPercent[key] = percent;
          progress[key] = `progress_${topicProgress}`;
        }
      }

      if (childProfile.has('smIdeasProgress') && childProfile.get('smIdeasProgress').length > 0) {
        profile.smIdeasProgress = childProfile.get('smIdeasProgress');
        const key = 'StoryIdeaTutorials';
        missionProgress[key] = profile.smIdeasProgress;

        for (let i = 0; i < profile.smIdeasProgress.length; i++) {
          progress[key] = i+1;
        }

        const percent = progress[key]/NUM_OF_IDEAS_TUTORIALS;
        const topicProgress = Math.floor(percent*8);
        progressPercent[key] = percent;
        progress[key] = 'progress_' + topicProgress;
      }
    }

    return {
      id: student.id,
      name: student.get('fullName'),
      parentEmail: student.get('parentEmail'),
      missionProgress: missionProgress,
      progress: progress,
      totalProgress: 'progress_' + Math.ceil(totalProgress/(Object.keys(totalTopicStars).length)),
      password: student.get('password') || undefined
    };
  });

  return studentsMap;
};
