import React from 'react';
import videojs from 'video.js';
import __includes from 'lodash/includes';

import './VideoWrapper.scss';

const s3Url = process.env.REACT_APP_BASE_S3_URL;

class Video extends React.Component {
  componentDidMount() {
    let poster = this.props.poster ? this.props.poster : `${s3Url}/flagship/default_video_poster.jpg`;

    if (__includes(this.props.title.toLowerCase(), 'pet pals')) {
      poster = `${s3Url}/flagship/pet_pals_video_poster.jpg`;
    } else if (__includes(this.props.title.toLowerCase(), 'teacher dashboard')) {
      poster = `${s3Url}/flagship/teacher-landing/teacher_video_poster.jpg`;
    }

    const options = {
      autoplay: false,
      controls: true,
      aspectRatio: '16:9',
      poster: poster,
      sources: [{
          src: this.props.videoUrl,
          type: 'video/mp4'
      }],
    };

    if (this.videoNode !== undefined) {
      this.player = videojs(this.videoNode, options);
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    return (
      <div data-vjs-player className={ `video-component-wrapper ${ this.props.extraClass }` }>
        <div className="video-border">
          <video
            ref={ node => this.videoNode = node }
            className="video-js"
            data-title={ this.props.title }
          ></video>
        </div>
      </div>
    );
  }
}

export default Video;
