import React from 'react';
import { Mixpanel } from '../../../utils/mixpanel';
import { getCurrentUserID } from '../../../services/teacher';
export default class ToggleItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showItem: false
    }
  }

  toggleRow = () => {
    this.setState({ showItem: !this.state.showItem }, () => {
      if (this.state.showItem) {
        console.info(`User ${getCurrentUserID()} event: Resources: Videos`);
        console.info(`props`, this.props);
        console.info('sending Dashboard (v1.3): Button Clicked');
        Mixpanel.track('Dashboard (v1.3): Button Clicked', { 
          parseAccountId: getCurrentUserID(),
          destination: this.props.title,
        });
      }
    });
  }

  render() {
    return (
      <div>
        <div
          className={`row ${this.state.showItem ? 'expanded' : ''}`}
          onClick={() => this.toggleRow()}
        >
          <div className="col">
            <div className="row-title">{this.props.title}</div>
            <div className="row-subtitle">{this.props.description}</div>
          </div>
        </div>
        {
          this.state.showItem &&
          <div className="content" dangerouslySetInnerHTML={{ __html: this.props.link }} />
        }
      </div>
    )
  }
}