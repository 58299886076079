import React, { Component, useEffect } from 'react';
import LazyLoad from 'react-lazyload';

import './Home.scss';
import Button from '../../components/elements/Button';
import Video from '../../components/global/Video';
import { getUserGeoIp } from '../../services/parse';

function Home(props) {
  const s3BaseUrl = process.env.REACT_APP_BASE_S3_URL;

  useEffect(() => {
    getUserGeoIp().then((data) => {
      if (data.country === 'HK' || data.country === 'MO') {
        window.location.replace('https://caprikon.education/codespark');
      }
    });
  }, []);

  return (
    <div className="home-container page">
      <div className="home-bg">
        <div className="home-intro-image">
          <LazyLoad height={500} once offset={100}>
          <div className="small-12 medium-7 cell trailer">
            <Video
              videoUrl={`${s3BaseUrl}/dashboard/videos/teacher_setup1.mp4`}
              title="Teacher Setup Video"
              poster={`${s3BaseUrl}/flagship/teacher-landing/teacher_video_poster.jpg`}>
              Your browser does not support the video tag.
            </Video>
          </div>
        </LazyLoad>
        </div>
        <div className="home-intro-text">
          <h1 dangerouslySetInnerHTML={{ __html: props.vocabulary['dashboard.home.signedOutHeader'] }}></h1>
          <p className="subtitle">{ props.vocabulary['dashboard.home.signedOutBody'] }</p>
          <Button to="/register" type="in-page" text={ props.vocabulary['dashboard.home.getStarted'] } extraClass="alt-orange" />
          <p className="sub-point" dangerouslySetInnerHTML={{ __html: props.vocabulary['dashboard.home.freeForTeachers'] }}></p>
        </div>
      </div>
      <div id="what-you-get">
        <div className="get-points">
          <img alt="" src={`${s3BaseUrl}/flagship/teacher-landing/teacher-2.png`} />
          <h2 className="header left">Sign up for our Teacher<br className="show-for-medium" />Dashboard and get:</h2>
          <ul className="list">
            <li>FREE curriculum, unplugged activities and more</li>
            <li>Easy classroom setup</li>
            <li>Manage students</li>
            <li>Control access for students</li>
            <div><img alt="" src="https://www.kidsafeseal.com/sealimage/1730524102862329251/codespark_thefoos_app_large_darktm.png" /></div>
          </ul>
        </div>
        <div className="imageContainer">
          <img alt="" src={`${s3BaseUrl}/flagship/teacher-landing/dashboard.png`} className="dashboard" />
        </div>
      </div>
      <div id="benefits-of-teaching">
        <h2 className="header left">Benefits of teaching code with codeSpark</h2>
        <ul className="list">
          <li>Aligns to standards in Math, Science, and Computer Science.</li>
          <li>Based on curriculum developed at MIT and UCLA.</li>
          <li>Completely word-free, perfect for pre-readers.</li>
          <li>New content added monthly.</li>
          <li>More than “move the robot”. Kids apply coding skills to make games.</li>
        </ul>
      </div>
      <div id="proven-effective">
        <h2 className="header left">codeSpark is proven effective</h2>
        <p className="description">After 90 minutes of coding with codeSpark Academy</p>
        <p className="statistic"><span>22%</span> showed an increase in accuracy with moderate sequencing tasks</p>
        <p className="statistic"><span>55%</span> showed an increase confidence in problem-solving abilities</p>
      </div>
      <div id="more-press">
        <p className="quote">My kids are in love with The Foos! I have been working on the site this week with Kindergartners and First Graders. Thanks for creating such an engaging way to teach coding!</p>
        <div className="more-link"><a target="_blank" href={ `${ process.env.REACT_APP_CODESPARK_BASE_URL }/press` }>More Press &gt;&gt;</a></div>
      </div>
      <div id="awards-and-recognition" className="section image-list">
        <h2 className="header">Awards and Recognition</h2>
        <ul>
          <li><img alt="" src={`${s3BaseUrl}/flagship/teacher-landing/awards/aasl.jpg`} /></li>
          <li><img alt="" src={`${s3BaseUrl}/flagship/teacher-landing/awards/brain-toy.png`} /></li>
          <li><img alt="" src={`${s3BaseUrl}/flagship/teacher-landing/awards/ctr.jpg`} /></li>
          <li><img alt="" src={`${s3BaseUrl}/flagship/teacher-landing/awards/kes.png`} /></li>
          <li><img alt="" src={`${s3BaseUrl}/flagship/teacher-landing/awards/reimagine.png`} /></li>
          <li>
            <a href="http://www.kidsafeseal.com/certifiedproducts/codespark_thefoos_app.html" target="_blank" rel="noopener noreferrer">
              <img border="0" alt="codeSpark Academy with The Foos - coding for kids is certified by the kidSAFE Seal Program." src="https://www.kidsafeseal.com/sealimage/1730524102862329251/codespark_thefoos_app_large_whitetm.png" />
            </a>
          </li>
          <li><img alt="" src={`${s3BaseUrl}/flagship/how-it-works/awards/parents_choice_gold.png`} /></li>
        </ul>
      </div>
    </div>
  );
}

export default Home;
