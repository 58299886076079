import React, { Fragment } from 'react';
import __range from 'lodash/range';

import './Pagination.scss';
const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

// code inspired by these guys https://scotch.io/tutorials/build-custom-pagination-with-react
class Pagination extends React.Component {
  constructor(props) {
    super(props);

    const { totalRecords = null, pageLimit = 30, pageNeighbours = 0} = props;

    this.pageLimit = typeof pageLimit === 'number' ? pageLimit : 30;
    this.totalRecords = typeof totalRecords === 'number' ? totalRecords : 0;
    this.pageNeighbours = typeof pageNeighbours === 'number' ? Math.max(0, Math.min(pageNeighbours, 2)) : 0;
    this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);

    this.state = { currentPage: 1 };
  }

  componentDidMount() {
    this.gotoPage(1);
  }

  gotoPage = page => {
    const { onPageChanged = f => f } = this.props;
    const currentPage = Math.max(0, Math.min(page, this.totalPages));

    const paginationData = {
      currentPage,
      totalPages: this.totalPages,
      pageLimit: this.pageLimit,
      totalRecords: this.totalRecords
    };

    this.setState({ currentPage }, () => onPageChanged(paginationData));
  }

  fetchPageNumbers = () => {
    const totalPages = this.totalPages;
    const currentPage = this.state.currentPage;
    const pageNeighbours = this.pageNeighbours;

    const totalNumbers = (this.pageNeighbours * 2) + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
      let pages = __range(startPage, endPage + 1);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
       const hasLeftSpill = startPage > 2;
       const hasRightSpill = (totalPages - endPage) > 1;
       const spillOffset = totalNumbers - (pages.length + 1);

       switch (true) {
         case (hasLeftSpill && !hasRightSpill): {
           const extraPages = __range(startPage - spillOffset, startPage - 1);
           pages = [LEFT_PAGE, ...extraPages, ...pages];
           break;
         }

         case (!hasLeftSpill && hasRightSpill): {
           const extraPages = __range(endPage + 1, endPage + spillOffset);
           pages = [...pages, ...extraPages, RIGHT_PAGE];
           break;
         }

         case (hasLeftSpill && hasRightSpill):
         default: {
           pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
           break;
         }
       }
       return [1, ...pages, totalPages];
    }
    return __range(1, totalPages);
  }

  handleClick = page => event => {
    event.preventDefault();
    this.gotoPage(page);
  }

  handleMoveLeft = event => {
    event.preventDefault();
    this.gotoPage(this.state.currentPage - (this.pageNeighbours * 2) - 1);
  }

  handleMoveRight = event => {
    event.preventDefault();
    this.gotoPage(this.state.currentPage + (this.pageNeighbours * 2) + 1);
  }

  render() {
    if (!this.totalRecords || this.totalPages === 1) return null;

    const { currentPage } = this.state;
    const pages = this.fetchPageNumbers();

    return (
      <Fragment>
        <ul className="pagination">
          {
            pages.map((page, index) => {
              if (page === LEFT_PAGE) return (
                <li key={index} className="page-item">
                  <div className="page-link" aria-label="Previous" onClick={this.handleMoveLeft}>
                    <span aria-hidden="true">&laquo;</span>
                  </div>
                </li>
              );

              if (page === RIGHT_PAGE) return (
                <li key={index} className="page-item">
                  <div className="page-link" aria-label="Next" onClick={this.handleMoveRight}>
                    <span aria-hidden="true">&raquo;</span>
                  </div>
                </li>
              );

              return (
                <li key={index} className={`page-item${currentPage === page ? 'active' : ''}`}>
                  <div className="page-link" onClick={this.handleClick(page)}>{page}</div>
                </li>
              );
            })
          }
        </ul>
      </Fragment>
    );
  }
}

export default Pagination;
