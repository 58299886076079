import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Popup from 'reactjs-popup';

import './ClassroomsDetailProjects.scss';
import '../../../../lib/css/react-confirm-alert.scss';

import {
  getClassroomProjects, removeStoryProject, removeGameProject
} from '../../../../services/classroom';
import Button from '../../../../components/elements/DashboardButton';
import Pagination from '../../../../components/global/Pagination';
import { notify } from '../../../../utils/functions';

class ClassroomsDetailProjects extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projects: [],
      currentPage: 1,
      totalPages: null,
      totalProjects: null,
      showStoryPopup: false
    };

    this.fetchClassroomProjects = this.fetchClassroomProjects.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.fetchClassroomProjects();
  }

  componentDidUpdate(prevProps) {
    const { classroomId } = this.props;

    if (prevProps.classroomId !== classroomId) {
      this.fetchClassroomProjects();
    }
  }

  fetchClassroomProjects(pageNumber) {
    const { classroomId } = this.props;
    const gameLimit = 20;
    const storyLimit = 20;
    const page = pageNumber ? pageNumber : 1;

    if (classroomId) {
      getClassroomProjects({classroomId, gameLimit, storyLimit, page}).then((projects) => {
        this.setState({ projects });
      });
    }
  }

  // custom react-confirm alert box
  confirmDelete(project) {
    confirmAlert({
      title: 'Delete This Project?',
      message: 'Are you sure you wish to delete this project? This action cannot be undone.',
      closeOnClickOutside: false,
      buttons: [
        {
          label: 'Cancel',
          onClick: () => null
        },
        {
          label: 'Yes, Delete',
          onClick: () => this.deleteProject(project)
        }
      ]
    });
  }

  deleteProject(project) {
    const { classroomId } = this.props;
    const { type, id } = project;

    if (type === 'story') {
      removeStoryProject({classroomId, storyId: id}).then(() => {
        notify('success', 'Story successfully deleted', 5000);
        this.props.updateProjectCount();
        this.fetchClassroomProjects();
      });
    } else {
      removeGameProject({classroomId, gameId: id}).then(() => {
        notify('success', 'Game successfully deleted', 5000);
        this.props.updateProjectCount();
        this.fetchClassroomProjects();
      });
    }
  }

  onPageChanged = data => {
    const { currentPage } = data;

    this.fetchClassroomProjects(currentPage);
  }

  closeModal() {
    this.setState({ showStoryPopup: false });
  }

  render() {
    const { projects } = this.state;
    const { totalProjects } = this.props;
    const imgUrl = process.env.REACT_APP_S3_IMAGES_URL;
    const gameLink = 'https://codespark.com/ul/?a=game&p=';

    return (
      <div className="classrooms-detail-projects-container">
        <h3 className="modal-title">{totalProjects} projects</h3>
        <div className="modal-content modal-content--projects">
          <div className="projects-table">
            <div className="projects-table-header-row">
              <div className="header">type</div>
              <div className="header">icon</div>
              <div className="header project-title">title</div>
              <div className="header">creator</div>
              <div className="header">share date</div>
              <div className="header"></div>
            </div>
            {
              projects.map(project => (
                <div key={project.id} className="project-row">
                  {
                    project.type === 'story' && (
                      <div className="project-details type">
                        <span>
                          <img src={`${imgUrl}/story-icon.png`} alt="story icon" /> story
                        </span>
                      </div>
                    )
                  }
                  {
                    project.type === 'game' && (
                      <div className="project-details type">
                        <span>
                          <img className="games" src={`${imgUrl}/game-controller.png`} alt="game icon" /> game
                        </span>
                      </div>
                    )
                  }

                  {
                    project.type === 'story' && (
                      <div className="project-details icon">
                        <button className="pointer-button" onClick={() => this.setState({ showStoryPopup: true })}><img src={project.thumbnail} alt="project thumbnail" /></button>
                      </div>
                    )
                  }
                  {
                    project.type === 'game' && (
                      <div className="project-details icon">
                        <a href={`${gameLink}${project.shareId}`} target="_blank"><img src={project.thumbnail} alt="project thumbnail" /></a>
                      </div>
                    )
                  }
                  {
                    project.type === 'story' && (
                      <div className="project-details project-title">
                        <button className="pointer-button" onClick={() => this.setState({ showStoryPopup: true })}>{project.name}</button>
                      </div>
                    )
                  }
                  {
                    project.type === 'game' && (
                      <div className="project-details project-title">
                        <a href={`${gameLink}${project.shareId}`} target="_blank">{project.name}</a>
                      </div>
                    )
                  }
                  <div className="project-details creator">{project.creatorName}</div>
                  <div className="project-details date">{project.sharedOn}</div>
                  <div className="project-details delete">
                    <Button className="button--xs"
                      onClick={() => this.confirmDelete(project)}>delete</Button>
                  </div>
                </div>
              ))
            }
          </div>
          {
            totalProjects && (
              <Pagination totalRecords={totalProjects} pageLimit={40} pageNeighbours={1} onPageChanged={this.onPageChanged} />
            )
          }
        </div>
        <Popup
          className="view-story-popup"
          open={this.state.showStoryPopup}
          closeOnDocumentClick={this.closeModal}
          onClose={this.closeModal}
        >
          <div className="modal">
            <h3>Coming Soon!</h3>
            <p className="popup-text">Story viewing is not available at this time.</p>
            <p className="popup-text">We hope to add this feature soon.</p>
            <div className="button-wrapper">
              <button className="dashboard-button button--small" onClick={this.closeModal}>OK</button>
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

export default ClassroomsDetailProjects;
