import React from 'react';

import './PageHeaderWrapper.scss';

function PageHeader(props) {
  return (
    <div className="small-12 cell page-header-component">
      <h1 className="page-title">{ props.pageTitle }</h1>
      <h2 className="page-subtitle">{ props.pageSubtitle }</h2>
    </div>
  );
}

export default PageHeader;
