import React, { useState } from 'react';
import { Mixpanel } from '../../../../utils/mixpanel';
import './ConnectCode.scss';
import Button from '../../../../components/elements/DashboardButton';
import { createClassroomCode } from '../../../../services/classroom';
import { getCurrentUserID } from '../../../../services/teacher';

function ConnectCode(props) {
  const [code, setCode] = useState();
  const { vocabulary } = props;

  const generateCode = () => {
    createClassroomCode({ classroomId: props.classroomId }).then(
      (data) => {
        if (data.code) {
          setCode(data.code);
        } else {
          // error
          console.error(
            props.vocabulary['dashboard.classrooms.show.codeError']
          );
        }
      },
      (err) => {
        console.error(err);
      }
    );
  };

  const openMoreInfoLink = () => {
    window.open(
      'https://drive.google.com/file/d/1o9Y56Ekk9jb8-CUafiGK_W1csoqOBfTC/view?usp=sharing',
      '_blank',
      'noopener,noreferrer'
    );
    Mixpanel.track('Dashboard (v1.4): Link Clicked', {
      parseAccountId: getCurrentUserID(),
      destinationUrl:
        'https://drive.google.com/file/d/1o9Y56Ekk9jb8-CUafiGK_W1csoqOBfTC/view?usp=sharing',
    });
  };

  return (
    <div className="classroom-detail-connect-container">
      <h3 className="modal-title">
        {vocabulary['dashboard.classrooms.show.createCode']} -{' '}
        {props.classroomName}
      </h3>
      <div className="content">
        {vocabulary['dashboard.classrooms.show.codeInstructions']}
      </div>
      {code ? (
        <div className="codeDiv">
          Code: <span className="code">{code}</span>
        </div>
      ) : (
        ''
      )}
      <div className="content">
        {code ? vocabulary['dashboard.classrooms.show.codeResponse'] : ''}
      </div>
      <div className="noteContainer">
        <div className="note">
          {code ? vocabulary['dashboard.classrooms.show.codeNote'] : ''}
        </div>
        {code && (
          <>
            <a onClick={openMoreInfoLink} className="infoLink">
              More information
            </a>
            <ul>
              <li>
                <p>
                  By sharing the classroom code with a student, a teacher
                  consents to the student's use of the codeSpark under the
                  teacher's supervision, accepts the below data collection and
                  usage practices and agrees to moderate the content posted in
                  the classroom.
                </p>
              </li>
              <li>
                <p>
                  Users of codeSpark Academy may receive push notifications
                  alerting them to in-app activity, new features, and
                  promotions. Users may disable push notifications by changing
                  the device's notification settings for codeSpark Academy.
                </p>
              </li>
              <li>
                <p>
                  The story creation tool enables users to record voice, upload
                  photos, and type speech bubbles. Users can share their stories
                  with other students within the same classroom. Teachers may
                  moderate any projects deemed unacceptable. Teachers may turn
                  off story sharing in Teacher Dashboard.
                </p>
              </li>
              <li>
                <p>
                  Parents may withdraw consent by contacting the child's school
                  or teacher that registered for codeSpark Academy.
                </p>
              </li>
            </ul>
            <p>
              <a href="https://codespark.com/terms" target="_blank">
                Terms of Service
              </a>{' '}
              <span>|</span>{' '}
              <a href="https://codespark.com/privacy" target="_blank">
                Privacy Policy
              </a>
            </p>
          </>
        )}
      </div>
      <div className="form-buttons">
        <Button className="button--small button--wide" onClick={generateCode}>
          {vocabulary['dashboard.classrooms.show.generateCode']}
        </Button>
      </div>
    </div>
  );
}

export default ConnectCode;
