import React from 'react';
import { Link } from 'react-router-dom';
import { Mixpanel } from '../../../utils/mixpanel';
import { getCurrentUserID } from '../../../services/teacher';
import './Card.scss';

const s3Url = process.env.REACT_APP_S3_IMAGES_URL;

const orderGrades = (grades) => {
  const orderedList = [];

  const order = {
    K: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    '6+': 6,
  };

  grades.forEach((grade) => (orderedList[order[grade]] = grade));

  return orderedList;
};

const sendMixpanelEvent = (item) => {
  console.info('sendMixpanelEvent', item);
  Mixpanel.track('Dashboard (v1.3): Button Clicked', {
    parseAccountId: getCurrentUserID(),
    destination: item.to,
  });
};

const Card = ({
  vocabulary,
  text,
  gradeLevels,
  to,
  src,
  type,
  level,
  linkParams,
  filename,
  name,
  page,
  hasDownloads,
  footnote,
  itemIndex,
}) => (
  <Link
    to={{
      pathname: `${to}`,
      state: {
        filename,
        name,
        page,
        hasDownloads,
        footnote,
        itemIndex,
      },
    }}
    params={linkParams}
    className="card-container"
    onClick={() => sendMixpanelEvent({ to })}
  >
    {type === 'classroom' ? (
      <div>
        <h2 className="title title--classroom">{text}</h2>
        <ul className="grade-level">
          {orderGrades(gradeLevels).map((grade) => (
            <li key={grade} className={`grade-level-${grade[0].toLowerCase()}`}>
              {grade}
            </li>
          ))}
        </ul>
        <img
          className="classroom-img"
          src={`${s3Url}/classroom_grade_${
            gradeLevels.length === 1 ? gradeLevels[0][0].toLowerCase() : 0
          }.png`}
          alt=""
        />
      </div>
    ) : type === 'chapter' ? (
      <h2 className="title">
        {vocabulary['dashboard.resources.solutionGuides.chapter']}
        <br />
        <span className="chapter-level">{level}</span>
      </h2>
    ) : type === 'add' ? (
      <div>
        <div>
          <i className="card-icon fa fa-plus-circle" />
        </div>
        <h2 className="title">{vocabulary[text] || text}</h2>
      </div>
    ) : (
      <div>
        {src && (
          <img className="card-img" src={src} alt={vocabulary[text] || text} />
        )}
        <h2 className="title">{vocabulary[text] || text}</h2>
      </div>
    )}
  </Link>
);

export default Card;
