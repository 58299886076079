import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import __sortBy from 'lodash/sortBy';
import __filter from 'lodash/filter';
import __orderBy from 'lodash/orderBy';

import { getActiveClassrooms, getArchivedClassrooms } from '../../services/classroom';
import { syncClassrooms } from '../../services/teacher';
import { notify } from '../../utils/functions';
import { setClassrooms } from '../../redux/actions';

import './Classrooms.scss';

import Card from '../../components/global/Card';
import DropdownSelect from '../../components/global/DropdownSelect';

class Classrooms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      classrooms: [],
      sortOptions: [
        { value: 'created', text: 'Created At' },
        { value: 'name', text: 'Name' },
        { value: 'numStudents', text: props.vocabulary["dashboard.student.textUpperPlural"] },
      ],
      teacherInfo: JSON.parse(sessionStorage.getItem('teacherData')),
      filter: ''
    };

    this.sortByFilter = this.sortByFilter.bind(this);
    this.filterClassrooms = this.filterClassrooms.bind(this);
  }

  componentDidMount() {
    const teacherInfo = this.state.teacherInfo;
    if (!this.props.siteContent.isPartner) {
      const sortOptions = this.state.sortOptions;
      sortOptions.push({ value: 'grades[0]', text: 'Grade Level' });
      this.setState({ sortOptions: sortOptions });
    }
    if (teacherInfo.isOauthTeacher) {
      // we might have multiple oauth logins in the future
      // make sure they're a Clever teacher
      const isCleverUser = teacherInfo?.user?.authData?.clever?.type === 'teacher';
      if (isCleverUser) {
        syncClassrooms().then((response) => {
          console.log('clever', response);
        }).catch((error) => {
          notify('error', `Error: ${error}`, 5000);
        });
      }
    }

    this.fetchActiveClassrooms(teacherInfo.id);
  }

  componentDidUpdate(prevProps) {
    const { classrooms } = this.state;
    if (prevProps.classrooms === null) {
      this.setState({ classrooms: classrooms })
    }
  }

  fetchActiveClassrooms(teacherId) {
    getActiveClassrooms({ teacherId }).then((classrooms) => {
      this.setState({
        classrooms,
        filter: 'active',
      });
      // convert all classroom names to lowercase so that we can sort correctly
      const formattedClassrooms = this.convertNamesToLC(classrooms);
      // start by sorting based on latest created
      this.sortByFilter({ value: 'created', text: 'Created At' });
    });
  }

  // convert all classroom names to lowercase so that we can sort correctly
  convertNamesToLC(classrooms) {
    const modifiedClassrooms = classrooms.map((classroom) => {
      const filter = classroom;
      filter.name = classroom.name.toLowerCase();
      return filter;
    });
    return modifiedClassrooms;
  }

  filterClassrooms(status) {
    if (status === 'archived') {
      getArchivedClassrooms({ teacherId: this.state.teacherInfo.id }).then((classrooms) => {
        this.setState({
          classrooms,
          filter: status
        });
        const formattedClassrooms = this.convertNamesToLC(classrooms);
        this.sortByFilter({ value: 'created', text: 'Created At' });
      });
    } else {
      this.fetchActiveClassrooms();
    }
  }

  sortByFilter(selected) {
    if (selected.text === 'Grade Level'){
      const sortedClassrooms = this.sortByGrade();
      this.setState({ classrooms: sortedClassrooms });
    } else if (selected.text === 'Created At') {
      this.setState({ classrooms: __orderBy(this.state.classrooms, [selected.value], ["desc"]) });
    } else {
      this.setState({ classrooms: __sortBy(this.state.classrooms, [selected.value]) });
    }
  }

  sortByGrade() {
    // filter K classes out first then sort based on grade and then name
    const classroomsWk = __filter(this.state.classrooms, (c) => c.grades[0] === 'K');
    const classroomsWOk = __filter(this.state.classrooms, (c) => c.grades[0] !== 'K');

    const sortedKClassrooms = __sortBy(classroomsWk, ['name']);
    const sortedNonKClassrooms = __sortBy(classroomsWOk, [function (room) {
      return room.grades[0];
    }, function (room) {
      return room.name;
    }]);

    return sortedKClassrooms.concat(sortedNonKClassrooms);
  }

  render() {
    let addCard;
    const { vocabulary } = this.props;
    const { filter, classrooms, teacherInfo } = this.state;

    if (filter !== 'archived') {
      if (teacherInfo.isOauthTeacher) {
        // todo: have this check for specific type of oauth user
        addCard = (<li>
          <Card
            text="Please use Clever to add classrooms"
            vocabulary={vocabulary}
            to=""
          />
        </li>);
      } else {
        addCard = (<li>
          <Card
            type="add"
            to="/dashboard/classrooms/create"
            text={`add ${vocabulary["dashboard.classroom.text"]}`}
            vocabulary={vocabulary}
          />
        </li>);
      }
    }

    return (
      <div className="classrooms-container page">
        <Helmet>
          <title>Classrooms List | codeSpark Academy Teacher Dashboard</title>
        </Helmet>
        <div className="classrooms-subnav">
          <div className="sort">
            sort by
            <DropdownSelect
              type="select"
              options={this.state.sortOptions}
              onSelect={this.sortByFilter}
            />
          </div>
          <ul>
            <li className={filter !== 'archived' ? 'active' : ''}>
              <button onClick={() => this.filterClassrooms('all')}>Active {vocabulary["dashboard.classroom.textUpperPlural"]}</button>
            </li>
            <li className={filter === 'archived' ? 'active' : ''}>
              <button onClick={() => this.filterClassrooms('archived')} >Archived {vocabulary["dashboard.classroom.textUpperPlural"]}</button>
            </li>
          </ul>
        </div>
        <ul className="cards">
          {
            classrooms !== null &&
            classrooms.map(room => (
              <li key={room.id}>
                <Card
                  type="classroom"
                  to={`classroom/${room.id}`}
                  linkParams={{ id: room.id }}
                  text={room.name}
                  teacher=""
                  gradeLevels={room.grades}
                  vocabulary={vocabulary}
                />
              </li>
            ))
          }
          {addCard}
        </ul>
      </div>
    );
  }
}

export default Classrooms;
