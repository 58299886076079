import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isLoggedIn } from '../../services/teacher';

import Self from './Self';
import Partner from './Partner';

class Login extends Component {
  componentDidMount() {
    if (isLoggedIn()) {
      this.props.history.push('/dashboard');
    }
  }

  render() {
    const { siteContent } = this.props;

    if (siteContent.isPartner) {
      return <Partner {...this.props} />
    }
    return <Self {...this.props} />;
  }
}

export default withRouter(Login);
