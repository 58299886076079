import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  isComplete: true,
  isValidEmail: true,
  teacherId: '',
  teacherProfile: {},
  classrooms: [],
  students: [],
};

const appReducer = createReducer(initialState, {
  SET_IS_COMPLETED_PROFILE: (state, action) => {
    state.isComplete = action.payload;
  },
  SET_IS_VALID_EMAIL: (state, action) => {
    state.isValidEmail = action.payload;
  },
  SET_TEACHER_ID: (state, action) => {
    state.teacherId = action.payload;
  },
  SET_TEACHER_PROFILE: (state, action) => {
    state.teacherProfile = action.payload;
  },
  SET_CLASSROOMS: (state, action) => {
    state.classrooms = action.payload;
  },
  SET_STUDENTS: (state, action) => {
    state.students = action.payload;
  }
});

export default appReducer;