import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Popup from 'reactjs-popup';

import './Login.scss';
import { getLoginLink } from '../../services/clever';
import PageHeader from '../../components/elements/PageHeader';
import FormContainer from '../../components/elements/FormContainer';
import LoginForm from '../../components/forms/Login';

class Self extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPopup: false,
      // isDistrictLogin: window.isDistrictLogin
    }
  }

  loginWithClever() {
    // localStorage.setItem('isExistingCleverLogin', true);
    window.open(getLoginLink(), '_self');
  }

  // componentDidMount() {
  //   if (this.state.isDistrictLogin === true) {
  //     this.setState({ showPopup: true });
  //   }
  // }

  closeModal = () => {
    this.setState({ showPopup: false });
  }

  render() {
    const { siteContent } = this.props;
    const s3Url = process.env.REACT_APP_S3_IMAGES_URL;

    return (
      <div className="login-container page">
        <Helmet>
          <title>Login | codeSpark Academy for Educators</title>
        </Helmet>
        <div className="login-section-wrapper">
            <div className="login-section">
              <PageHeader
                  pageTitle={ this.props.vocabulary['dashboard.login.welcomeBack'] }
                  pageSubtitle={ this.props.vocabulary['dashboard.login.welcomeMessage'] }
              />
              <FormContainer>
                <LoginForm vocabulary={this.props.vocabulary} isPartner={siteContent.isPartner} partner={siteContent.company} />
              </FormContainer>
            </div>
            <div className="login-section clever">
              <button className="btn-clever" onClick={ () => this.loginWithClever() } />
            </div>
        </div>
        <div className="crop">
          <img className="bg-image" alt="" src={`${s3Url}/school.svg`} />
        </div>
        <Popup
          className="update-profile-popup"
          open={this.state.showPopup}
          closeOnDocumentClick
          onclose={this.closeModal}
        >
          <div className="modal">
            <p className="popup-text">You are using a Clever integration at the district level, which is not supported by codeSpark at this time. </p>
            <p className="popup-text">Please register as a non-Clever teacher.</p>
            <div className="button-wrapper">
              <button className="dashboard-button" onClick={this.closeModal}>OK</button>
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

export default Self;
