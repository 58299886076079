import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { loginOrSignUp } from '../../services/clever';
import { getTeacher } from '../../services/teacher';

import { Mixpanel } from '../../utils/mixpanel';
const queryString = require('query-string');

class Clever extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: ""
    };
  }
  componentDidMount() {
    const parsedQuery = queryString.parse(this.props.location.search);

    if (parsedQuery.code) {
      this.startOauth(parsedQuery.code);
    } else {
      // nobody should go to this page directly
      this.props.history.push('/');
    }
  }

  startOauth(code) {
    loginOrSignUp(code).then((results) => {
      // grab teacher id in case we need it
      getTeacher(results.sessionData.userId).then((teacher) => {
        sessionStorage.setItem('teacherData', JSON.stringify(teacher));
      });
      if (results.isNewUser) {
        // new users get shorter registration form
        // redirect to the 'more info' page
        Mixpanel.track('Dashboard (v1.3): Clever Signup');
        this.props.history.push({
          pathname: '/more-info',
          search: `?code=${code}`
        });
      } else {
        // redirect to the dashboard home if user isn't new
        Mixpanel.track('Dashboard (v1.3): Clever Login');
        this.props.history.push('/dashboard');
      }
    }).catch((error) => {
      let errorMsg = "There was an error logging in. Error: ";
      if (error.message) {
        this.setState({error: errorMsg + error.message});
      } else {
        // students who try to log in get redirected to webgl and don't see error
        if (error.errorMessage === 'register.oauth.studentNotAllowed') {
          if (process.env.REACT_APP_NODE_ENV === 'production') {
            window.open(`https://codespark.com/play/?clever_token=${error.authData.access_token}`, "_self");
          } else if (process.env.REACT_APP_NODE_ENV === 'beta') {
            window.open(`https://beta.codespark.com/play/?clever_token=${error.authData.access_token}`, "_self");
          } else {
            window.open(`https://dev.codespark.com/play/?clever_token=${error.authData.access_token}`, "_self");
          }
        // redirect back to log in if they are using district access
        } else if (error === 'register.oauth.districtNotAllowed') {
          // set global variable because components are unrelated
          window.isDistrictLogin = true;
          return this.props.history.push('/login');
        } else {
          this.setState({error: errorMsg + JSON.stringify(error)});
        }
      }
      console.error(JSON.stringify(error));
    });
  }

  render() {
    return (
      <div className="clever-container page orange">
        <h1 className="clever-loading">Sharpening pencils, wiping down the board, logging you in</h1>
        {this.state.error ? this.state.error : ""}
      </div>
    );
  }
}

export default withRouter(Clever);
